import { ApiRoutes } from '../constants';
import Request from '.';

export const getDueFeesData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.GETDUEFEESDATA, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const downloadFeesDueReportData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.DOWNLOADFEESDUEREPORTDATA, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
