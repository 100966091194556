import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { revertAll } from './index';

const initialState = {
  categoryDataList: [],
};

export const categorySlice = createSlice({
  name: 'categorylist',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    getCategory: (state, action) => {
      state.categoryDataList = action.payload;
    },
    insertCategory: (state, action) => {
      state.categoryDataList = [...state.categoryDataList, action.payload];
    },
    updateCategory: (state, action) => {
      const newData = state.categoryDataList.map((item) => {
        if (item.i_e_c_id === action.payload.i_e_c_id) {
          item = action.payload;
        }
        return item;
      });
      state.categoryDataList = newData;
    },
    deleteCategory: (state, action) => {
      const filterData = state.categoryDataList.filter((item) => item.i_e_c_id !== action.payload);
      state.categoryDataList = filterData;
    },
  },
});

export const { getCategory, insertCategory, updateCategory, deleteCategory } =
  categorySlice.actions;

export default categorySlice.reducer;
