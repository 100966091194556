import PropTypes from 'prop-types';
// @mui
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

export default function AdminDashboardLayout({ children }) {
  return (
    <>
      {/* <Header /> */}

      <Container component="main">
        <Stack
          sx={{
            py: 1,
            m: 'auto',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          {children}
        </Stack>
      </Container>
    </>
  );
}

AdminDashboardLayout.propTypes = {
  children: PropTypes.node,
};
