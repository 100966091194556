import Request from '.';
import { ApiRoutes } from '../constants';

export const getUserWiseAllBranch = async (data) => {
    try {
      const res = await Request.post(ApiRoutes.GETUSERWISEALLBRANCH, data);
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };