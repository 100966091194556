import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { revertAll } from './index';

const initialState = {
  departmentList: [],
};

export const departmentSlice = createSlice({
  name: 'department',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    getDepartmentList: (state, action) => {
      state.departmentList = action.payload;
    },
    insertDepartment: (state, action) => {
      state.departmentList = [...state.departmentList, action.payload].sort(
        (a, b) => b.dept_id - a.dept_id
      );
    },
    updateDepartment: (state, action) => {
      const newData = state.departmentList.map((item) => {
        if (item.dept_id === action.payload.dept_id) {
          item = action.payload;
        }
        return item;
      });
      state.departmentList = newData;
    },
    deleteDepartment: (state, action) => {
      const filterData = state.departmentList.filter((item) => item.dept_id !== action.payload);
      state.departmentList = filterData;
    },
  },
});

export const {
  reset,
  getDepartmentList,
  insertDepartment,
  updateDepartment,
  deleteDepartment,
} = departmentSlice.actions;

export default departmentSlice.reducer;
