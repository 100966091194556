import Request from '.';
import { ApiRoutes } from '../constants';

export const getFeesCollectionReportsData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.getfeescollectionreportsdata, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const downloadFeesCollectionReports = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.DOWNLOADFEESCOLLECTIONREPORTSDATA, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const generatedFeesReportsData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.GETGENERATEDFEESCOLLECTIONREPORTDATA, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateFeesReportStatusData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.UPDATEFEESREPORTSTATUSDATA, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getBranchWiseBatchData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.GETBRANCHWISEBATCH, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getStudentAttendanceReportData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.GETSTUDENTATTENDANCEREPORT, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const downloadStudentAttendanceReportData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.DOWNLAODSTUDENTATTENDANCE, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const dailyIncomeReportData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.DAILYINCOMEREPORT, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const dailyExpenseReportData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.DAILYEXPENSEREPORT, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getExperienceLetterData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.GETEXPERIENCELETTER, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const insertExperienceLetterData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.INSERTEXPERIENCELETTER, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateExperienceLetterData = async (data) => {
  const result = await new Promise((resolve, reject) => {
    Request.post(ApiRoutes.UPDATEEXPERIENCELETTER, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

export const deleteExperienceLetterData = async (data) => {
  const result = await new Promise((resolve, reject) => {
    Request.delete(`${ApiRoutes.DELETEEXPERIENCELETTER}/${data}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

export const downloadExperienceLetter = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.DOWNLOADEXPERIENCELETTER, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAppointmentLetterData = async () => {
  const result = await new Promise((resolve, reject) => {
    Request.post(ApiRoutes.GETAPPOINTMENTLETTER)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

export const insertAppointmentLetterData = async (data) => {
  const result = await new Promise((resolve, reject) => {
    Request.post(ApiRoutes.INSERTAPPOINTMENTLETTER, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

export const updateAppointmentLetterData = async (data) => {
  const result = await new Promise((resolve, reject) => {
    Request.post(ApiRoutes.UPDATEAPPOINTMENTLETTER, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

export const deleteAppointmentLetterData = async (data) => {
  const result = await new Promise((resolve, reject) => {
    Request.delete(`${ApiRoutes.DELETEAPPOINTMENTLETTER}/${data}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

export const downloadAppointmentLetter = async (data) => {
  const result = await new Promise((resolve, reject) => {
    Request.post(ApiRoutes.DOWNLOADAPPOINTMENTLETTER, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};
