import PropTypes from 'prop-types';
// @mui
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';

// ----------------------------------------------------------------------

export default function CompactLayout({ children }) {
  return (
    <>
      {/* <Header /> */}

      <Container component="main">
        <Stack
          sx={{
            py: 1,
            m: 'auto',
            maxWidth: 500,
            minHeight: '100vh',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          {children}
        </Stack>

        <Typography variant="caption" component="div" sx={{ textAlign: 'center', pb: 1 }}>
          COPYRIGHT © {new Date().getFullYear()}{' '}
          <a href="https://www.truelinesolution.com/" target="_blank" rel="noopener noreferrer">
            Trueline Solution
          </a>
          <span className="d-none d-sm-inline-block">, All rights Reserved</span>
        </Typography>
      </Container>
    </>
  );
}

CompactLayout.propTypes = {
  children: PropTypes.node,
};
