import { DbName } from 'src/@core/constants/db_name';
import { deleteDB } from 'idb';
import { dbPromise } from './connectionDB';

export async function dbInsertAllData(dbName, dbVersion, storeName, data) {
  const db = await dbPromise(dbName, dbVersion, storeName);
  const tx = db.transaction(storeName, 'readwrite');
  const store = tx.objectStore(storeName);
  // eslint-disable-next-line object-shorthand
  await store.put({ id: 1, data: data });
  await tx.complete;
}

export async function dbGetAllData(dbName, dbVersion, storeName, key) {
  const db = await dbPromise(dbName, dbVersion, storeName, key);
  const tx = db.transaction(storeName, 'readonly');
  const store = tx.objectStore(storeName);
  const data = await store.getAll();
  await tx.complete;
  if (data.length > 0) return data[0]?.data;
  return [];
}

export async function dbInsert(dbName, dbVersion, storeName, key, data, order) {
  const db = await dbPromise(dbName, dbVersion, storeName, key);
  const tx = db.transaction(storeName, 'readwrite');
  const store = tx.objectStore(storeName);
  const dbData = await store.getAll();
  if (dbData.length > 0) {
    if (order === undefined) dbData[0].data.unshift(data);
    else if (order === 'asc') dbData[0].data.push(data);
    else if (order === 'des') dbData[0].data.unshift(data);
    else dbData[0].data.unshift(data);
    await store.put({ id: 1, data: dbData[0].data });
    await tx.complete;
  }
}

export async function dbUpdateData(dbName, dbVersion, storeName, key, ObjKey, data) {
  const db = await dbPromise(dbName, dbVersion, storeName, key);
  const tx = db.transaction(storeName, 'readwrite');
  const store = tx.objectStore(storeName);
  const dbData = await store.getAll();
  if (dbData.length > 0) {
    // eslint-disable-next-line no-return-assign
    const updatedData = dbData[0]?.data.map((obj) =>
      obj[ObjKey] === data[ObjKey] ? (obj = data) : obj
    );
    const newDbData = { id: 1, data: updatedData };
    await store.put(newDbData);
    await tx.complete;
  }
}

export async function dbDeleteData(dbName, dbVersion, storeName, key, ObjKey, id) {
  const db = await dbPromise(dbName, dbVersion, storeName, key);
  const tx = db.transaction(storeName, 'readwrite');
  const store = tx.objectStore(storeName);
  const dbData = await store.getAll();
  if (dbData.length > 0) {
    const updatedData = dbData[0]?.data.filter((obj) => obj[ObjKey] !== id);
    await store.put({ id: 1, data: updatedData });
    await tx.complete;
  }
}

export const clearIndexedDB = () => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in DbName) {
    // eslint-disable-next-line no-prototype-builtins
    if (DbName.hasOwnProperty(key)) {
      // eslint-disable-next-line no-await-in-loop
      deleteDB(DbName[key]);
    }
  }
};
