/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { saveAs } from 'file-saver';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBranch } from 'src/@core/api/branches';
import { getBatchName } from 'src/@core/api/common_api';
import { GetDepartment } from 'src/@core/api/department';
import {
  downloadFeesDueReportData,
  getDueFeesData,
} from 'src/@core/api/reports_api';
import {
  commaFunction,
  feesDueReportFilters,
  withoutJoinRenderData,
} from 'src/@core/constants/variables';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { dbBranchAllData, dbGetAllBranchData } from 'src/localDB/branchDB';
import {
  dbDepartmentAllData,
  dbGetAllDepartmentData,
} from 'src/localDB/departmentDB';
import { getBranchList } from 'src/redux/reducer/branch';
import { getDepartmentList } from 'src/redux/reducer/department';
import { handleToast } from 'src/utils/toast';
import { isUserLoggedIn } from 'src/utils/utils';

export default function FeesDueReport() {
  const dispatch = useDispatch();
  const userInfo = isUserLoggedIn();
  const downloadReportLoading = useBoolean();
  const [batchData, setBatchData] = useState([]);
  const [getLoader, setGetLoader] = useState(false);
  const [studentData, setStudentData] = useState([]);
  const [searchCall, setSearchCall] = useState(false);
  const [searchCalls, setSearchCalls] = useState(false);
  const [studentDataCount, setStudentDataCount] = useState(0);
  const [searchClearCall, setSearchClearCall] = useState(false);
  const [filterData, setFilterData] = useState(feesDueReportFilters);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  // REDUX DATA
  const { branchList } = useSelector((store) => store.branch);
  const { departmentList } = useSelector((store) => store.department);

  const getDueFeesDataFunction = async (data) => {
    setGetLoader(true);
    await getDueFeesData({
      ...data,
      branch_id: data?.branch_id === 0 ? userInfo?.branch_id : data?.branch_id,
      role_id: userInfo.role_id,
      user_id: userInfo.user_id,
      pagination: paginationModel,
      coadmin_type: userInfo.coadmin_type,
    })
      .then((response) => {
        setGetLoader(false);
        setSearchCall(false);
        setStudentData(response?.data?.data);
        setStudentDataCount(response?.data?.count);
      })
      .catch((error) => {
        setGetLoader(false);
        handleToast(0, error?.response?.data?.description);
      });
  };

  const fetchBranchFromDB = async () => {
    const BranchData = await dbGetAllBranchData();
    if (BranchData?.length === 0) getBranchDataFunction();
    else if (BranchData?.length > 0) dispatch(getBranchList(BranchData));
  };

  const getBranchDataFunction = async () => {
    await getBranch()
      .then((response) => {
        dispatch(getBranchList(response.data));
        dbBranchAllData(response.data);
      })
      .catch((error) => handleToast(0, error?.response?.data?.description));
  };

  const fetchDepartmentFromDB = async () => {
    const DepartmentData = await dbGetAllDepartmentData();
    if (DepartmentData?.length === 0) getDepartmentFunction();
    else if (DepartmentData?.length >= 0)
      dispatch(getDepartmentList(DepartmentData));
  };

  const getDepartmentFunction = async () => {
    await GetDepartment({
      branch_id: userInfo.branch_id,
      user_id: userInfo.user_id,
      role_id: userInfo.role_id,
    })
      .then((response) => {
        dispatch(getDepartmentList(response?.data));
        dbDepartmentAllData(response?.data);
      })
      .catch((error) => handleToast(0, error?.response?.data?.description));
  };

  const handleBatchData = async () => {
    await getBatchName()
      .then((response) => setBatchData(response?.data))
      .catch((error) => handleToast(0, error?.response?.data?.description));
  };

  useEffect(() => {
    handleBatchData();
    if (branchList.length === 0) fetchBranchFromDB();
    if (departmentList?.length === 0) fetchDepartmentFromDB();
  }, []);

  const downloadFeesDueReportDataFunction = async (data) => {
    downloadReportLoading.onTrue();
    const formData = { ...data };
    formData.user_id = userInfo?.user_id;
    formData.role_id = userInfo.role_id;
    formData.branch_id =
      filterData?.branch_id === 0
        ? userInfo?.branch_id || 0
        : searchCalls === true
        ? filterData.branch_id
        : 0;
    formData.branch_name =
      filterData?.branch_id === 0
        ? withoutJoinRenderData(
            branchList,
            'branch_id',
            userInfo.branch_id,
            'branch_name'
          )[0] || 0
        : searchCalls === true
        ? withoutJoinRenderData(
            branchList,
            'branch_id',
            filterData?.branch_id,
            'branch_name'
          )[0]
        : 'All Branch';
    formData.subbranch_id = searchCalls === true ? filterData.subbranch_id : 0;
    formData.dept_id = searchCalls === true ? filterData.dept_id : 0;
    formData.batch_id = searchCalls === true ? filterData.batch_id : 0;
    formData.coadmin_type = userInfo.coadmin_type;

    await downloadFeesDueReportData(formData)
      .then((response) => {
        downloadReportLoading.onFalse();
        handleToast(response?.status, response?.description);
        if (response?.status === 1) {
          saveAs(`${response?.data?.data}`, `${response?.data?.name}`);
        }
      })
      .catch((error) => {
        downloadReportLoading.onFalse();
        handleToast(error?.response?.data?.description);
      });
  };

  useEffect(() => {
    getDueFeesDataFunction(filterData);
  }, [paginationModel]);

  const customProgress = () => (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      height='100%'
    >
      <CircularProgress />
    </Box>
  );

  const columns = [
    {
      field: 'id',
      headerName: 'No',
      width: 80,
      sortingOrder: ['desc', 'asc'],
    },
    {
      field: 'full_name',
      headerName: 'Student Name',
      sortingOrder: ['desc', 'asc'],
      width: 220,
    },
    {
      field: 'enrollment_no',
      headerName: 'Enrollment No',
      sortable: false,
      width: 160,
      renderCell: ({ row }) => (
        <Typography title={`${row?.enrollment_prefix}${row?.enrollment_no}`}>
          {row?.enrollment_prefix}
          {row?.enrollment_no}
        </Typography>
      ),
    },
    {
      field: 'paid_fees',
      headerName: 'Paid Fees',
      width: 180,
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ row }) => (
        <Typography
          title={`₹ ${commaFunction(parseFloat(row.paid_fees).toFixed(2))}`}
        >
          {`₹ ${commaFunction(parseFloat(row.paid_fees).toFixed(2))}`}
        </Typography>
      ),
    },
    {
      field: 'remaining_fees',
      headerName: 'Remaining Fees',
      width: 180,
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ row }) => (
        <Typography
          title={`₹ ${commaFunction(
            parseFloat(row.remaining_fees).toFixed(2)
          )}`}
        >
          {`₹ ${commaFunction(parseFloat(row.remaining_fees).toFixed(2))}`}
        </Typography>
      ),
    },
    {
      field: 'father_mob',
      headerName: 'Father Mobile No.',
      width: 170,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'mother_mob',
      headerName: 'Mother Mobile No.',
      width: 170,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'batch_name',
      headerName: 'Batch',
      width: 160,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'standard_name',
      headerName: 'Standard',
      width: 160,
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  return (
    <>
      <CustomBreadcrumbs
        heading='Fees Due Report'
        sx={{
          p: 1,
          mb: { xs: 1, sm: 0 },
        }}
      />
      <Card>
        <Grid
          alignItems='center'
          direction='row'
          spacing={2}
          display='flex'
          sx={{
            pt: 2,
            px: 2,
          }}
          container
        >
          {userInfo.role_id === 1 && (
            <Grid item xs='12' md='3' lg='2.3'>
              <FormControl
                variant='outlined'
                size='small'
                sx={{ width: '100%' }}
              >
                <Autocomplete
                  options={[
                    { branch_id: 0, branch_name: 'All Branch' },
                    ...(branchList || []).filter(
                      (option) => option.parent_branch_id === 0
                    ),
                  ]}
                  disableClearable
                  getOptionLabel={(option) => option.branch_name || null}
                  value={
                    filterData?.branch_id === 0
                      ? { branch_id: 0, branch_name: 'All Branch' }
                      : branchList?.find(
                          (option) =>
                            option?.branch_id === filterData?.branch_id
                        ) || null
                  }
                  onChange={(event, selectedOption) => {
                    setSearchCall(true);
                    setFilterData({
                      ...filterData,
                      branch_id: selectedOption?.branch_id,
                      subbranch_id: 0,
                      dept_id: 0,
                      batch_id: 0,
                    });
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.branch_id}>
                      {option.branch_name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label='Select Branch' />
                  )}
                  size='small'
                  sx={{
                    width: '100%',
                  }}
                />
              </FormControl>
            </Grid>
          )}

          {userInfo.role_id <= 2 && (
            <Grid item xs='12' md='3' lg={userInfo.role_id === 1 ? '2.3' : '3'}>
              <FormControl
                variant='outlined'
                size='small'
                sx={{ width: '100%' }}
              >
                <Autocomplete
                  disableClearable
                  options={[
                    { branch_id: 0, branch_name: 'All Sub Branch' },
                    ...(branchList || []).filter(
                      (item) =>
                        (item.parent_branch_id === filterData?.branch_id &&
                          item.types === 1) ||
                        (userInfo.role_id === 2 &&
                          item.types === 1 &&
                          userInfo.branch_id === item.parent_branch_id) ||
                        ((userInfo.role_id === 3 ||
                          userInfo.role_id === 4 ||
                          userInfo.role_id === 5) &&
                          item.types === 1 &&
                          userInfo.branch_id === item.branch_id)
                    ),
                  ]}
                  getOptionLabel={(option) => option.branch_name || null}
                  value={
                    filterData.subbranch_id === 0
                      ? { branch_id: 0, branch_name: 'All Sub Branch' }
                      : branchList?.find(
                          (option) =>
                            option?.branch_id === filterData.subbranch_id
                        ) || null
                  }
                  onChange={(event, selectedOption) => {
                    setSearchCall(true);
                    setFilterData({
                      ...filterData,
                      subbranch_id: selectedOption?.branch_id,
                      dept_id: 0,
                      batch_id: 0,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label='Select Sub Branch' />
                  )}
                  size='small'
                  sx={{
                    width: '100%',
                  }}
                />
              </FormControl>
            </Grid>
          )}

          <Grid item xs='12' md='3' lg={userInfo.role_id === 1 ? '2.3' : '3'}>
            <FormControl variant='outlined' size='small' sx={{ width: '100%' }}>
              <Autocomplete
                size='small'
                disableClearable
                defaultValue={{ dept_id: 0, dept_name: 'All Department' }}
                renderInput={(params) => (
                  <TextField {...params} label='Select Department' />
                )}
                onChange={(e, data) => {
                  setSearchCall(true);
                  setFilterData({
                    ...filterData,
                    dept_id: data?.dept_id,
                    batch_id: 0,
                  });
                }}
                options={[
                  { dept_id: 0, dept_name: 'All Department' },
                  ...(departmentList || []).filter(
                    (item) =>
                      (userInfo.role_id <= 2 &&
                        item.branch_id === filterData?.subbranch_id) ||
                      (userInfo.role_id > 2 &&
                        userInfo.branch_id === item.branch_id)
                  ),
                ]}
                getOptionLabel={(option) => option.dept_name}
                value={
                  [
                    { dept_id: 0, dept_name: 'All Department' },
                    ...departmentList,
                  ]?.find((option) => option.dept_id === filterData?.dept_id) ||
                  null
                }
                renderOption={(props, option) => (
                  <li {...props} key={option.dept_id}>
                    {option.dept_name}
                  </li>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs='12' md='3' lg={userInfo.role_id === 1 ? '2.3' : '3'}>
            <FormControl variant='outlined' size='small' sx={{ width: '100%' }}>
              <Autocomplete
                size='small'
                disableClearable
                defaultValue={{ batch_id: 0, batch_name: 'All Batch' }}
                renderInput={(params) => (
                  <TextField {...params} label='Select Batch' />
                )}
                onChange={(e, data) => {
                  setSearchCall(true);
                  setFilterData({
                    ...filterData,
                    batch_id: data?.batch_id,
                  });
                }}
                options={[
                  { batch_id: 0, batch_name: 'All Batch' },
                  ...batchData.filter(
                    (item) => item.department_id === filterData.dept_id
                  ),
                ]}
                getOptionLabel={(option) => option.batch_name}
                value={
                  [
                    { batch_id: 0, batch_name: 'All Batch' },
                    ...batchData,
                  ]?.find(
                    (option) => option.batch_id === filterData?.batch_id
                  ) || null
                }
                renderOption={(props, option) => (
                  <li {...props} key={option.batch_id}>
                    {option.batch_name}
                  </li>
                )}
              />
            </FormControl>
          </Grid>

          <Grid
            item
            xs='4'
            sm='9'
            md={userInfo.role_id === 1 ? '9' : '1'}
            lg={userInfo.role_id === 1 ? '1' : '1'}
            sx={{ display: 'flex', justifyContent: 'end' }}
          >
            <Button
              color='primary'
              variant='outlined'
              sx={{ p: 0 }}
              title='Search'
              onClick={() => {
                if (searchCall === true && getLoader === false) {
                  setSearchCalls(true);
                  getDueFeesDataFunction(filterData);
                  setSearchClearCall('search');
                }
              }}
            >
              <Iconify
                icon='bitcoin-icons:search-outline'
                sx={{ width: 40, height: 35 }}
              />
            </Button>
          </Grid>
          <Grid
            item
            xs='8'
            sm='3'
            md={userInfo.role_id === 1 ? '3' : '2'}
            lg={userInfo.role_id === 1 ? '1.8' : '2'}
            sx={{ display: 'flex', justifyContent: 'space-around' }}
          >
            <Button
              color='error'
              variant='outlined'
              sx={{ p: 0 }}
              title='Clear'
              onClick={() => {
                setSearchCalls(false);
                setSearchCall(false);
                setFilterData(feesDueReportFilters);
                setSearchClearCall('clear');

                if (searchClearCall === 'search')
                  getDueFeesDataFunction(feesDueReportFilters);
              }}
            >
              <Iconify
                icon='basil:cross-outline'
                sx={{ width: 40, height: 35 }}
              />
            </Button>
            <Button
              color='success'
              variant='outlined'
              sx={{ p: 0 }}
              disabled={downloadReportLoading.value}
              title='Download Report'
              onClick={() => {
                if (downloadReportLoading.value === false)
                  downloadFeesDueReportDataFunction();
              }}
            >
              <Iconify icon='ph:export' sx={{ width: 40, height: 28 }} />
            </Button>
          </Grid>
        </Grid>

        <Box
          sx={{
            p: 2,
            mb: { xs: 3, md: 1 },
            height: 'calc(100vh - 260px)',
          }}
        >
          <DataGrid
            columns={columns}
            rows={studentData?.map((item, index) => ({
              id: index + 1 + paginationModel.page * paginationModel.pageSize,
              ...item,
            }))}
            disableRowSelectionOnClick
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            getRowId={(row) => row?.student_id}
            pagination
            pageSizeOptions={[10, 25, 50, 75, 100]}
            loading={getLoader}
            paginationMode='server'
            onPaginationModelChange={setPaginationModel}
            slots={{ loadingOverlay: customProgress }}
            rowCount={studentDataCount}
            disableColumnSelector
            disableColumnMenu
            disableColumnFilter
            localeText={{ noRowsLabel: 'There are No Records to Display' }}
            sx={{
              [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                outline: 'none !important',
              },
              [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
                outline: 'none !important',
              },
              '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                outline: 'none !important',
              },
              '.MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          />
        </Box>
      </Card>
    </>
  );
}
