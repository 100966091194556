import { createContext, useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const ProfileContext = createContext();

export const useProfileContext = () => useContext(ProfileContext);

export const ProfileProvider = ({ children }) => {
  const [shouldUpdateProfile, setShouldUpdateProfile] = useState(false);

  const triggerUpdateProfile = () => {
    setShouldUpdateProfile(true);
  };

  const resetUpdateProfile = () => {
    setShouldUpdateProfile(false);
  };

  const contextValue = useMemo(
    () => ({
      shouldUpdateProfile,
      triggerUpdateProfile,
      resetUpdateProfile,
    }),
    [shouldUpdateProfile]
  );
  return <ProfileContext.Provider value={contextValue}>{children}</ProfileContext.Provider>;
};

ProfileProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
