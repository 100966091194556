export const DbName = {
  ROLEDB: 'roleDB',
  BATCHDB: 'batchDB',
  BOARDDB: 'boardDB',
  COLORDB: 'colorDB',
  BRANCHDB: 'branchDB',
  SCHOOLDB: 'schoolDB',
  SUBJECTDB: 'subjectDB',
  CHAPTERDB: 'chapterDB',
  STANDARDDB: 'standardDB',
  TIMESLOTDB: 'timeslotDB',
  DEPARTMENTDB: 'departmentDB',
  STUDENTSTATUSDB: 'studentStatusDB',
  PAYMENTMETHODDB: 'paymentMethodDB',
  TEACHERPOSITIONDB: 'teacherPositionDB',
  TEACHERHIRINGSTATUSDB: 'taecherHiringStatusDB',
  EXPENSETYPEDB: 'expenseTypeDB',
  CATEGORYLISTDB: 'categoryListDB',
  FEESDB: 'feesDB',
  ATTENDANCESTATUSDB: 'attendanceStatusDB',
  FEESTYPEDB: 'feesTypeDB',
  MEETINGTYPEDB: 'meetingTypeDB',
};

export const StoreName = {
  ROLE: 'role',
  BATCH: 'batch',
  BOARD: 'board',
  COLOR: 'color',
  BRANCH: 'branch',
  SCHOOL: 'school',
  CHAPTER: 'chapter',
  SUBJECT: 'subject',
  STANDARD: 'standard',
  TIMESLOT: 'timeslot',
  DEPARTMENT: 'department',
  PAYMENTMETHOD: 'paymentMethod',
  STUDENTSTATUS: 'studentStatus',
  TEACHERPOSITION: 'teacherPosition',
  TEACHERHIRINGSTATUS: 'taecherHiringStatus',
  EXPENSETYPE: 'expenseType',
  CATEGORYLIST: 'categoryList',
  FEES: 'fees',
  ATTENDANCESTATUS: 'attendanceStatus',
  FEESTYPE: 'feesType',
  MEETINGTYPE: 'meetingType',
};

export const DbVersion = {
  FIRST: 1,
};
