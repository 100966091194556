import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { revertAll } from './index';

const initialState = {
  timeslotList: [],
};

export const timeslotSlice = createSlice({
  name: 'timeslot',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    getTimeslotList: (state, action) => {
      state.timeslotList = action.payload;
    },
    insertTimeslot: (state, action) => {
      state.timeslotList = [...state.timeslotList, action.payload];
    },
    updateTimeslot: (state, action) => {
      const newData = state.timeslotList.map((item) => {
        if (item.t_id === action.payload.t_id) item = action.payload;
        return item;
      });
      state.timeslotList = newData;
    },
    deleteTimeslot: (state, action) => {
      const filterData = state.timeslotList.filter((item) => item.t_id !== action.payload);
      state.timeslotList = filterData;
    },
  },
});

export const {
  getTimeslotList,
  insertTimeslot,
  updateTimeslot,
  deleteTimeslot,
} = timeslotSlice.actions;

export default timeslotSlice.reducer;
