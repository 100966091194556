import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { revertAll } from './index';

const initialState = {
  colorList: [],
};

export const colorSlice = createSlice({
  name: 'color',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    getColorlist: (state, action) => {
      state.colorList = action.payload;
    },
    insertColor: (state, action) => {
      state.colorList = [...state.colorList, action.payload];
    },
    updateColor: (state, action) => {
      const newData = state.colorList.map((item) => {
        if (item.color_id === action.payload.color_id) {
          item = action.payload;
        }
        return item;
      });
      state.colorList = newData;
    },
    deleteColors: (state, action) => {
      const filterData = state.colorList.filter((item) => item.color_id !== action.payload);
      state.colorList = filterData;
    },
  },
});

export const { getColorlist, insertColor, updateColor, deleteColors } = colorSlice.actions;
export default colorSlice.reducer;
