import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducer';

const store = configureStore({
  reducer: rootReducer,
  devTools: !!(
    process.env.REACT_APP_REDUX_DEV_TOOLS === 'true' ||
    process.env.REACT_APP_REDUX_DEV_TOOLS === true
  ),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});
export { store };
