/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { m } from 'framer-motion';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';

// hooks
import Iconify from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import { isUserLoggedIn } from 'src/utils/utils';
import { ApiRoutes } from 'src/@core/constants';
import { useEffect, useState } from 'react';
import {
  Autocomplete,
  Divider,
  FormControl,
  Grid,
  List,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Scrollbar from 'src/components/scrollbar';
import { getNotificationData, readNotificationData } from 'src/@core/api/common_api';
import { handleToast } from 'src/utils/toast';
import Label from 'src/components/label';
import { useDispatch } from 'react-redux';
import { getUserWiseAllBranch } from 'src/@core/api/admindashboard';
import { LoadingButton } from '@mui/lab';
import { paths } from 'src/routes/paths';
import { clearIndexedDB } from 'src/localDB/crudDB';
import { revertAll } from 'src/redux/reducer';
import { useNotificationContext } from './notificationContex';

export default function NotificationsPopover() {
  const dispatch = useDispatch();
  const popover = usePopover();
  const userData = isUserLoggedIn();
  const [notification, setNotification] = useState({ msg: [], id: 0 });
  const [wsSocket, setWsSocket] = useState(null);
  const { notificationData, handleIsArrivedNotification } = useNotificationContext();
  const [userWiseBranch, setuserWiseBranch] = useState([]);
  const UserWiseBranchData = JSON.parse(localStorage.getItem('UserWiseBranchData'));
  const [selectedValue, setSelectedValue] = useState(UserWiseBranchData?.branch_id);
  const defaultValue = UserWiseBranchData?.branch_id || null;
  const defaultaddress =
    UserWiseBranchData?.addressData?.find((addresses) => addresses.address_id) || null;

  const handleChange = (branchId) => {
    window.location.reload();
    const filteredData = userWiseBranch?.filter((item) => item.branch_id === branchId);
    localStorage.setItem('UserWiseBranchData', JSON.stringify(filteredData[0]));
    clearIndexedDB();
    dispatch(revertAll());
  };

  const handleChangeAddress = (addressId) => {
    const selectedBranch = userWiseBranch?.find((branch) =>
      branch?.addressData?.some((address) => address?.address_id === addressId)
    );
    if (selectedBranch) {
      localStorage.setItem(
        'UserWiseBranchData',
        JSON.stringify({ ...selectedBranch, selectedAddress_id: addressId })
      );
      clearIndexedDB();
      dispatch(revertAll());
      window.location.reload();
    }
  };

  const handleReadNotification = async (data) => {
    await readNotificationData(data)
      .then((response) => {
        getNotificationFunction();
        handleToast(response.status, response.description);
      })
      .catch((error) => {
        handleToast(0, error?.response?.data?.description);
      });
  };
  // when cron run then automatic get notification
  if (wsSocket !== null && wsSocket.readyState === 1) {
    wsSocket.onmessage = (msg) => {
      getNotificationFunction();
      const parsedMessage = JSON.parse(msg.data);
      if ('Notification' in window && parsedMessage?.msg?.key) {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            const notificationSend = new Notification(`${parsedMessage.msg.data.type}`, {
              body: parsedMessage.msg.data.msg,
            });

            notificationSend.onclick = () => {
              window.location.href = `${window.location.origin}${paths.meeting}`;
            };
          }
        });
      }
    };
  }
  if ('Notification' in window) {
    Notification.requestPermission();
  }

  const getNotificationFunction = async () => {
    await getNotificationData({ user_id: userData.user_id })
      .then((response) => {
        setNotification({ msg: response?.data, id: userData.user_id });
      })
      .catch((error) => {
        handleToast(0, error?.response?.data?.description);
      });
  };

  const handleGetUserWiseAllBranch = async () => {
    await getUserWiseAllBranch({ branch_id: userData.branch_id, user_id: userData?.user_id })
      .then((response) => {
        setuserWiseBranch(response?.data);
      })
      .catch((error) => handleToast(0, error?.response?.data?.description));
  };

  const WebSocketConnection = () => {
    const socket = new WebSocket(`${ApiRoutes.WEBSOCKET_URL}/${userData.user_id}`);
    setWsSocket(socket);
    socket.onopen = () => {};
    socket.addEventListener('close', () => {
      WebSocketConnection();
    });
  };

  if (wsSocket === null) {
    WebSocketConnection();
  }

  useEffect(() => {
    if (notificationData) {
      wsSocket.send(notificationData);
      handleIsArrivedNotification();
    }
    getNotificationFunction();
  }, [notificationData]);

  useEffect(() => {
    handleGetUserWiseAllBranch();
  }, []);

  return (
    <>
      {userData.role_id === 2 && (
        <Grid item xs={12} md={6} sx={{ width: '200px', justifyContent: 'flex-start' }}>
          <Stack>
            <FormControl variant="outlined" size="small" sx={{ width: '100%' }}>
              <Autocomplete
                options={userWiseBranch}
                disableClearable
                getOptionLabel={(option) =>
                  `${option.branch_name} ${option.branch_type === 1 ? 'Nursery' : 'Classes'}` ||
                  null
                }
                value={
                  userWiseBranch?.find((option) => option.branch_id === selectedValue) ||
                  defaultValue
                }
                onChange={(event, newValue) => {
                  setSelectedValue(newValue.branch_id);
                  handleChange(newValue.branch_id);
                }}
                renderOption={(props, option) => (
                  <li {...props} key={option.branch_id}>
                    {`${option.branch_name} ${option.branch_type === 1 ? 'Nursery' : 'Classes'}`}
                  </li>
                )}
                renderInput={(params) => <TextField {...params} label="Select Branch" />}
                size="small"
                sx={{
                  width: '100%',
                }}
              />
            </FormControl>
          </Stack>
        </Grid>
      )}
      {userData.role_id === 6 && (
        <Grid item xs={12} md={6} sx={{ width: '200px', justifyContent: 'flex-start' }}>
          <Stack>
            <FormControl variant="outlined" size="small" sx={{ width: '100%' }}>
              <Autocomplete
                options={userWiseBranch?.flatMap((branch) => branch.addressData)}
                disableClearable
                getOptionLabel={(option) => `${option.address}` || null}
                value={
                  userWiseBranch
                    ?.flatMap((branch) => branch.addressData)
                    .find(
                      (option) => option.address_id === UserWiseBranchData.selectedAddress_id
                    ) || defaultaddress
                }
                onChange={(event, newValue) => {
                  handleChangeAddress(newValue.address_id);
                }}
                renderOption={(props, option) => (
                  <li {...props} key={option.address_id}>
                    {`${option.address}`}
                  </li>
                )}
                renderInput={(params) => <TextField {...params} label="Select Branch" />}
                size="small"
                sx={{
                  width: '100%',
                }}
              />
            </FormControl>
          </Stack>
        </Grid>
      )}

      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={popover.value ? 'primary' : 'default'}
        onClick={popover.onOpen}
      >
        {/* <Badge badgeContent="0" color="error"> */}
        <Badge
          badgeContent={notification?.msg.length > 0 ? notification?.msg.length : '0'}
          color="error"
        >
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>
      </IconButton>
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{ width: 320, padding: 1, maxHeight: '300px', overFlowY: 'auto' }}
      >
        {/* sx={{ alignItems: 'center', p: 1 }} */}
        <Grid direction="row" container>
          <Grid item xs={10}>
            <Typography variant="body1">Notification</Typography>
          </Grid>
          <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end', pr: 1 }}>
            <Label color="primary" sx={{ borderRadius: '50%' }}>
              <Typography variant="body1">{notification?.msg.length}</Typography>
            </Label>
            {/* </IconButton> */}
          </Grid>
        </Grid>
        <Divider />
        <Scrollbar sx={{ maxHeight: '200px' }}>
          {notification?.msg.length > 0 ? (
            <List disablePadding sx={{ maxHeight: '200px' }}>
              {notification?.msg?.map((data, index) => (
                <>
                  <ListItemText
                    key={index}
                    sx={{ padding: 1 }}
                    disableTypography
                    secondary={
                      <>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{
                            typography: 'caption',
                            color: 'text.disabled',
                            cursor: 'pointer',
                          }}
                        >
                          <span title={data.body}>
                            <Typography
                              sx={{
                                whiteSpace: 'nowrap',
                                width: '250px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              variant="subtitle2"
                              color="primary"
                            >
                              {data.body}
                            </Typography>
                          </span>
                        </Stack>
                        <Grid container sx={{ alignItems: 'center' }}>
                          <Grid item xs={9}>
                            <Typography variant="caption" sx={{ alignItems: 'end' }}>
                              {data.n_date_time}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            sx={{
                              display: 'flex',
                              justifyContent: 'end',
                              pr: 1,
                            }}
                          >
                            <Label
                              key={index}
                              title="Read Notification"
                              variant="soft"
                              color="primary"
                              sx={{ cursor: 'pointer' }}
                              onClick={
                                () =>
                                  handleReadNotification({
                                    notification_id: data.notification_id,
                                    type: 1,
                                  }) // type:- 1- read only one , 2 - read all
                              }
                            >
                              <Iconify icon="uim:check" />
                            </Label>
                          </Grid>
                        </Grid>
                      </>
                    }
                  />
                  <Divider />
                </>
              ))}
            </List>
          ) : (
            <Typography
              sx={{
                p: 2,
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              No Notification
            </Typography>
          )}
        </Scrollbar>

        {notification?.msg.length > 0 && (
          <LoadingButton
            sx={{ mt: 1 }}
            onClick={
              () => handleReadNotification({ user_id: userData.user_id, type: 2 }) // type:- 1- read only one , 2 - read all
            }
            fullWidth
            size="small"
            variant="contained"
            color="primary"
          >
            Read All Notification
          </LoadingButton>
        )}
      </CustomPopover>
    </>
  );
}
