import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { revertAll } from './index';

const initialState = { feesType_list: [] };
export const feesTypeSlice = createSlice({
  name: 'feestype',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    getFeesTypeData: (state, action) => {
      state.feesType_list = action.payload;
    },
    insertFeesTypeData: (state, action) => {
      state.feesType_list = [...state.feesType_list, action.payload];
    },
    updateFeesTypeData: (state, action) => {
      const newData = state.feesType_list.map((item) => {
        if (item.f_type_id === action.payload.f_type_id) {
          item = action.payload;
        }
        return item;
      });
      state.feesType_list = newData;
    },
    deleteFeesTypeData: (state, action) => {
      const filterData = state.feesType_list.filter((item) => item.f_type_id !== action.payload);
      state.feesType_list = filterData;
    },
  },
});

export const { getFeesTypeData, insertFeesTypeData, updateFeesTypeData, deleteFeesTypeData } =
  feesTypeSlice.actions;

export default feesTypeSlice.reducer;
