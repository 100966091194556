import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { revertAll } from './index';

const initialState = { expenseType_list: [] };
export const expenseTypeSlice = createSlice({
  name: 'expensetype',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    getExpenseTypeData: (state, action) => {
      state.expenseType_list = action.payload;
    },
    insertExpenseTypeData: (state, action) => {
      state.expenseType_list = [...state.expenseType_list, action.payload];
    },
    updateExpenseTypeData: (state, action) => {
      const newData = state.expenseType_list.map((item) => {
        if (item.e_type_id === action.payload.e_type_id) {
          item = action.payload;
        }
        return item;
      });
      state.expenseType_list = newData;
    },
    deleteExpenseTypeData: (state, action) => {
      const filterData = state.expenseType_list.filter((item) => item.e_type_id !== action.payload);
      state.expenseType_list = filterData;
    },
  },
});

export const {
  getExpenseTypeData,
  insertExpenseTypeData,
  updateExpenseTypeData,
  deleteExpenseTypeData,
} = expenseTypeSlice.actions;

export default expenseTypeSlice.reducer;
