import Request from ".";
import { ApiRoutes } from "../constants";

// DEPARTMENT INSERT
export const InsertDepartment = async (data) => {
    try {
      const res = await Request.post(ApiRoutes.INSERTDEPARTMENT, data);
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  
  // DEPARTMENT UPDATE
  export const UpdateDepartment = async (data) => {
    try {
      const res = await Request.post(ApiRoutes.UPDATEDEPARTMENT, data);
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  
  // DEPARTMENT GET
  export const GetDepartment = async (data) => {
    try {
      const res = await Request.post(ApiRoutes.GETDEPARTMENT, data);
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  
  // DEPARTMENT DELETE
  export const DeleteDepartment = async (data) => {
    try {
      const res = await Request.post(ApiRoutes.DELETEDEPARTMENT, data);
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };