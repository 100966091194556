import { DbName, DbVersion, StoreName } from 'src/@core/constants/db_name';
import { dbDeleteData, dbGetAllData, dbInsert, dbInsertAllData, dbUpdateData } from './crudDB';

export async function dbSubjectAllData(data) {
  await dbInsertAllData(DbName.SUBJECTDB, DbVersion.FIRST, StoreName.SUBJECT, data);
}

export async function dbGetAllSubjectData() {
  return dbGetAllData(DbName.SUBJECTDB, DbVersion.FIRST, StoreName.SUBJECT, 'id');
}

export async function dbUpdateSubjectData(data) {
  await dbUpdateData(
    DbName.SUBJECTDB,
    DbVersion.FIRST,
    StoreName.SUBJECT,
    'id',
    'subject_id',
    data
  );
}

export async function dbDeleteSubjectData(id) {
  await dbDeleteData(DbName.SUBJECTDB, DbVersion.FIRST, StoreName.SUBJECT, 'id', 'subject_id', id);
}

export async function dbSubjectDataInsert(data) {
  await dbInsert(DbName.SUBJECTDB, DbVersion.FIRST, StoreName.SUBJECT, 'id', data, 'asc');
}
