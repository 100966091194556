/* eslint-disable no-lonely-if */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import SvgColor from 'src/components/svg-color';
import {
  attendanceRoute,
  attributesRoute,
  dashboardRoute,
  generalSettingsRoute,
  reportRoute,
  studentRoute,
  transactionRoute,
  vanRoute,
} from 'src/@core/constants/variables';
import { isUserLoggedIn } from 'src/utils/utils';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  dashboard: icon('ic_dashboard'),
  admindashboards: icon('ic_dashboard'),
  batch: icon('ic_appbox'),
  role: icon('ic_role'),
  standard: icon('ic_standard'),
  color: icon('ic_color'),
  subject: icon('ic_subject'),
  user: icon('ic_user'),
  timeslot: icon('ic_time'),
  branch: icon('ic_banking'),
  board: icon('ic_branch'),
  school: icon('ic_job'),
  department: icon('ic_blog'),
  chapter: icon('ic_chapter'),
  fees: icon('ic_fees'),
  paymentmethod: icon('ic_payment'),
  attributes: icon('ic_list'),
  student: icon('ic_student'),
  websetting: icon('ic_websetting'),
  teacherhiring: icon('ic_teacher'),
  studymaterial: icon('ic_studymaterial'),
  teacherattendance: icon('ic_teacherattendance'),
  generalsetting: icon('ic_setting'),
  dailyteacherattendance: icon('ic_teacherattendance'),
  attendance: icon('ic_teacherattendance'),
  facultytimetable: icon('ic_facultyTimeTbale'),
  meeting: icon('ic_meeting'),
  leave: icon('ic_leaveuser'),
  gallery: icon('ic_gallery'),
  transaction: icon('ic_transaction'),
  teacherattendancestatus: icon('ic_teacherattendance'),
  feestype: icon('ic_fees'),
  invoice: icon('ic_invoice'),
  teachertraining: icon('ic_training'),
  test: icon('ic_test'),
  eow: icon('ic_eow'),
  activity: icon('ic_activity'),
  myattendance: icon('ic_myattendance'),
  newscorner: icon('ic_newscorner'),
  van: icon('ic_van'),
  salary: icon('ic_salary'),
  studentattendance: icon('ic_teacherattendance'),
  reports: icon('ic_reports'),
  dailywork: icon('ic_dailywork'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const user = isUserLoggedIn();
  const { t } = useLocales();
  const userPermission = JSON.parse(localStorage.getItem('PermissionData'));
  const userWiseBranchData = JSON.parse(localStorage.getItem('UserWiseBranchData'));

  // JSON.parse(localStorage.getItem('UserWiseBranchData'))

  //  IF ROUTES DISPLAY ONLY NURSERY THEN DATA PUSH IN NURSERY ROUTES AND NURSERY ARRAY
  //  IF ROUTES DISPLAY ONLY CLASSES THEN DATA PUSH IN CLASSES ROUTES AND CLASSES ARRAY
  //  IF ROUTES DISPLAY ONLY CLASSES, NURSERY  THEN DATA PUSH IN CLASSES ROUTES AND CLASSES ARRAY AND ALSO PUSH IN  NURSERY ROUTES AND NURSERY ARRAY
  //  NOTE : IF YOU DON'T WANT TO DISPLAY YOUR ROUTES TO SUPERADMIN THEN NOT WRITE IN COMMON ROUTES OTHER WISE COMPLUSERY ADD IN COMMON ROUTES
  const commonRoutes = [
    'Batch',
    'Chapter',
    'Department',
    'Fees',
    'Teacher Hiring',
    'User',
    'Study Material',
    'Gallery',
    'Invoice',
    'Meeting',
    'Activity',
    'Teacher Training',
    'Test',
    'EOW',
    'News Corner',
    'Salary',
    'Student Attendance',
    'Leave',
    'Daily Work',
    'FacultyTimeTable',
  ];

  const ClassesRoutes = [
    'Test',
    'EOW',
    'Leave',
    'Batch',
    'Chapter',
    'Department',
    'Fees',
    'Teacher Hiring',
    'User',
    'Study Material',
    'Gallery',
    'Invoice',
    'Meeting',
    'Teacher Training',
    'News Corner',
    'Salary',
    'Student Attendance',
    'Daily Work',
    'FacultyTimeTable',
  ];
  const NurseryRoutes = [
    'Leave',
    'Batch',
    'Chapter',
    'Department',
    'Fees',
    'Teacher Hiring',
    'User',
    'Study Material',
    'Gallery',
    'Invoice',
    'Meeting',
    'Activity',
    'Teacher Training',
    'News Corner',
    'Salary',
    'Student Attendance',
    'Daily Work',
    'FacultyTimeTable',
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const routesArray = [];

  const ClassesArray = [];
  const NurseryArray = [];
  const studentChildernRoutes = [];
  const attributesChildernRoutes = [];
  const generalSettingsRoutes = [];
  const attendanceRoutes = [];
  const transactionRoutes = [];
  const vanRoutes = [];
  const dashboardRoutes = [];
  const reportRoutes = [];

  routesArray.push({
    title: t('Dashboard'),
    path: paths.dashboard.root,
    icon: ICONS.dashboard,
  });

  ClassesArray.push({
    title: t('Dashboard'),
    path: paths.dashboard.root,
    icon: ICONS.dashboard,
  });

  NurseryArray.push({
    title: t('Dashboard'),
    path: paths.dashboard.root,
    icon: ICONS.dashboard,
  });

  const createRouteObject = (name, subPath, addIcon) => {
    const routeObject = {
      title: t(name.toLowerCase()),
      path: subPath === '' ? getPath(name) : `${subPath}${getPath(name)}`,
    };
    if (addIcon) routeObject.icon = ICONS[name.toLowerCase().replace(' ', '')];

    return routeObject;
  };

  const getPath = (name) => paths[name.toLowerCase().replaceAll(' ', '')];

  userPermission?.forEach((permission) => {
    // NURSERY ROUTES
    if (permission.view === 1 && NurseryRoutes.includes(permission?.f_name)) {
      NurseryArray.push(createRouteObject(permission?.f_name, '', true));
    }

    // CLASSSES ROUTES
    if (permission.view === 1 && ClassesRoutes.includes(permission?.f_name)) {
      ClassesArray.push(createRouteObject(permission?.f_name, '', true));
    }

    // COMMON ROUTES : CLASSES AND NURSERY
    if (permission.view === 1 && commonRoutes.includes(permission?.f_name)) {
      routesArray.push(createRouteObject(permission?.f_name, '', true));
    }

    // CHILDREN ROUTES
    if (permission.view === 1 && studentRoute.includes(permission.f_name)) {
      studentChildernRoutes.push(createRouteObject(permission.f_name, paths.student, false));
    }
    if (permission.view === 1 && attributesRoute.includes(permission.f_name)) {
      attributesChildernRoutes.push(createRouteObject(permission.f_name, paths.attributes, false));
    }
    const updatedGeneralSettingsRoute = generalSettingsRoute.filter(
      (item) =>
        !(
          (userWiseBranchData !== null
            ? userWiseBranchData?.branch_type === 1
            : user?.branch_type === 1) &&
          (item === 'School' || item === 'Board')
        )
    );
    if (permission.view === 1 && updatedGeneralSettingsRoute.includes(permission.f_name)) {
      generalSettingsRoutes.push(createRouteObject(permission.f_name, paths.generalsetting, false));
    }
    if (permission.view === 1 && attendanceRoute.includes(permission.f_name)) {
      if (user.role_id !== 1) {
        attendanceRoutes.push(createRouteObject(permission.f_name, paths.attendance, false));
      } else if (permission.f_name === 'Teacher Attendance') {
        attendanceRoutes.push(createRouteObject(permission.f_name, paths.attendance, false));
      } else if (permission.f_name === 'Teacher Attendance Status') {
        attendanceRoutes.push(createRouteObject(permission.f_name, paths.attendance, false));
      } else if (permission.f_name === 'Teacher Attendance Report') {
        attendanceRoutes.push(createRouteObject(permission.f_name, paths.attendance, false));
      } else if (permission.f_name === 'My Attendance') {
        attendanceRoutes.push(createRouteObject(permission.f_name, paths.attendance, false));
      }
    }
    if (permission.view === 1 && transactionRoute.includes(permission.f_name)) {
      transactionRoutes.push(createRouteObject(permission.f_name, paths.transaction, false));
    }
    if (permission.view === 1 && vanRoute.includes(permission.f_name)) {
      vanRoutes.push(createRouteObject(permission.f_name, paths.van, false));
    }
    if (permission.view === 1 && dashboardRoute.includes(permission.f_name)) {
      dashboardRoutes.push(createRouteObject(permission.f_name, paths.admindashboards, false));
    }
    if (permission.view === 1 && reportRoute.includes(permission.f_name)) {
      reportRoutes.push(createRouteObject(permission.f_name, paths.reports, false));
      if (permission.f_name === 'Fees Collection Report') {
        reportRoutes.push(createRouteObject('Generated Fees Reports', paths.reports, false));
      }
    }
  });

  // COMMON ROUTES
  if (studentChildernRoutes.length > 0) {
    routesArray.push({
      ...createRouteObject('student', '', true),
      children: studentChildernRoutes,
    });
    ClassesArray.push({
      ...createRouteObject('student', '', true),
      children: studentChildernRoutes,
    });
    NurseryArray.push({
      ...createRouteObject('student', '', true),
      children: studentChildernRoutes,
    });
  }

  if (attributesChildernRoutes.length > 0) {
    routesArray.push({
      ...createRouteObject('attributes', '', true),
      children: attributesChildernRoutes,
    });
    ClassesArray.push({
      ...createRouteObject('attributes', '', true),
      children: attributesChildernRoutes,
    });
    NurseryArray.push({
      ...createRouteObject('attributes', '', true),
      children: attributesChildernRoutes,
    });
  }

  if (generalSettingsRoutes.length > 0) {
    routesArray.push({
      ...createRouteObject('general setting', '', true),
      children: generalSettingsRoutes,
    });
    ClassesArray.push({
      ...createRouteObject('general setting', '', true),
      children: generalSettingsRoutes,
    });
    NurseryArray.push({
      ...createRouteObject('general setting', '', true),
      children: generalSettingsRoutes,
    });
  }

  if (attendanceRoutes.length > 0) {
    routesArray.push({
      ...createRouteObject('attendance', '', true),
      children: attendanceRoutes,
    });
    ClassesArray.push({
      ...createRouteObject('attendance', '', true),
      children: attendanceRoutes,
    });
    NurseryArray.push({
      ...createRouteObject('attendance', '', true),
      children: attendanceRoutes,
    });
  }

  if (transactionRoutes.length > 0) {
    routesArray.push({
      ...createRouteObject('transaction', '', true),
      children: transactionRoutes,
    });
    ClassesArray.push({
      ...createRouteObject('transaction', '', true),
      children: transactionRoutes,
    });
    NurseryArray.push({
      ...createRouteObject('transaction', '', true),
      children: transactionRoutes,
    });
  }

  if (vanRoutes.length > 0) {
    routesArray.push({
      ...createRouteObject('van', '', true),
      children: vanRoutes,
    });
    NurseryArray.push({
      ...createRouteObject('van', '', true),
      children: vanRoutes,
    });
    ClassesArray.push({
      ...createRouteObject('van', '', true),
      children: vanRoutes,
    });
  }

  if (dashboardRoutes.length > 0) {
    routesArray.push({
      ...createRouteObject('admin dashboards', '', true),
      children: dashboardRoutes,
    });
    NurseryArray.push({
      ...createRouteObject('admin dashboards', '', true),
      children: dashboardRoutes,
    });
    ClassesArray.push({
      ...createRouteObject('admin dashboards', '', true),
      children: dashboardRoutes,
    });
  }

  if (reportRoutes.length > 0) {
    routesArray.push({
      ...createRouteObject('reports', '', true),
      children: reportRoutes,
    });
    NurseryArray.push({
      ...createRouteObject('reports', '', true),
      children: reportRoutes,
    });
    ClassesArray.push({
      ...createRouteObject('reports', '', true),
      children: reportRoutes,
    });
  }

  const data = useMemo(() => {
    let items;

    if (user?.role_id === 1) {
      items = routesArray;
    }
    // } else if (user?.role_id === 2 && userWiseBranchData === null) {
    //   items = adminroutesArray;
    // }
    else if (user?.role_id === 2 && userWiseBranchData !== null) {
      if (userWiseBranchData.branch_type === 2) {
        items = ClassesArray;
      } else {
        items = NurseryArray;
      }
    } else if (
      (user?.role_id === 3 || user?.role_id === 4 || user?.role_id === 5) &&
      user?.branch_type === 1
    ) {
      items = NurseryArray;
    } else if (
      (user?.role_id === 3 || user?.role_id === 4 || user?.role_id === 5) &&
      user?.branch_type === 2
    ) {
      items = ClassesArray;
    } else {
      items = routesArray;
    }

    return [
      {
        items,
      },
    ];
  }, [
    routesArray,
    // adminroutesArray,
    user?.role_id,
    userWiseBranchData,
    ClassesArray,
    NurseryArray,
  ]);

  return data;
}
