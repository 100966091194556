import Request from '.';
import { ApiRoutes } from '../constants';

export const getTeachersPositionList = async () => {
  const result = await new Promise((resolve, reject) => {
    Request.get(ApiRoutes.GETTEACHERPOSITIONLIST)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

export const createTeacherHiring = async (data) => {
  const result = await new Promise((resolve, reject) => {
    Request.post(ApiRoutes.CREATETEACHERHIRING, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

export const updateTeacherHiring = async (data) => {
  const result = await new Promise((resolve, reject) => {
    Request.post(ApiRoutes.UPDATETEACHERHIRING, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

export const updateTeacherHiringStatus = async (data) => {
  const result = await new Promise((resolve, reject) => {
    Request.post(ApiRoutes.UPDATETEACHERHIRINGSTATUS, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

export const deleteTeacherHiringDetails = async (data) => {
  const result = await new Promise((resolve, reject) => {
    Request.delete(ApiRoutes.DELETETEACHERHIRINGDETAILS, { data })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

export const getTeacherHiringList = async (data) => {
  const result = await new Promise((resolve, reject) => {
    Request.post(ApiRoutes.GETTEAHERHIRINGLIST, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

export const createHiringForm = async (data) => {
  const result = await new Promise((resolve, reject) => {
    Request.post(ApiRoutes.INSERTHIRINGFORM, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};