/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from 'react';
import { Box, Card, Chip, Grid, MenuItem, Stack, Typography } from '@mui/material';

import FormProvider from 'src/components/hook-form/form-provider';
import { RHFAutocomplete, RHFSelect, RHFTextField, RHFUpload } from 'src/components/hook-form';

import { handleToast } from 'src/utils/toast';
import { getPublicData } from 'src/@core/api/student_inquiry';
import { standardTypes } from 'src/@core/constants/variables';
import { createHiringForm, getTeachersPositionList } from 'src/@core/api/teacherhiring';

import { useDispatch, useSelector } from 'react-redux';
import { getSubjectList } from 'src/@core/api/common_api';
import { getSubjectData } from 'src/redux/reducer/subject';
import { dbGetAllSubjectData, dbSubjectAllData } from 'src/localDB/subjectDB';
import logoImage from '../../assets/logo/logo.png';

export default function TeacherHiringPublicForm() {
  const dispatch = useDispatch();
  const { subjectList } = useSelector((state) => state.subject);
  const [positionList, setPositionList] = useState([]);
  const [standardList, setStandardList] = useState([]);
  const PublicDataSchema = Yup.object().shape({
    name: Yup.string().trim().required('Name is Required'),
    email: Yup.string().required('Email is Required').email('Email must be a valid email address'),
    phone: Yup.string()
      .test('maxDigits', 'Mobile Number must have exactly 10 digits', (number) =>
        number ? number.length === 10 : true
      )
      .required('Mobile Number is Required'),
    position: Yup.string().required('Position is Required'),
    experience: Yup.string().trim().required('Experience is Required'),
    standard_type: Yup.string().required('Standard Type is Required'),
    standard_id: Yup.array().min(1, 'Standard Type is Required'),
    subject_id: Yup.array().min(1, 'Subject Name is Required'),
    qualifications: Yup.string().trim().required('Qualifications is Required'),
    current_working: Yup.string().trim().required('Working is Required'),
    current_salary: Yup.string().trim().required('Current Salary is Required'),
    expected_salary: Yup.string().trim().required('Expected Salary is Required'),
    remarks: Yup.string().trim(),
    resume: Yup.mixed().nullable().required('Resume is Required'),
  });

  const defaultValues = {
    name: '',
    email: '',
    phone: '',
    position: '',
    experience: '',
    standard_type: '',
    standard_id: [],
    subject_id: [],
    qualifications: '',
    current_working: '',
    current_salary: '',
    expected_salary: '',
    resume: null,
    remarks: '',
  };

  const methods = useForm({
    resolver: yupResolver(PublicDataSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { isSubmitting },
  } = methods;
  const handleDropSingleFile = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (newFile) {
        setValue('resume', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );
  const handleInsertHiringData = async (data) => {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('email', data.email);
    formData.append('phone', data.phone);
    formData.append('position', data.position);
    formData.append('experience', data.experience);
    formData.append('standard_id', data.standard_id);
    formData.append('subject_id', data.subject_id);
    formData.append('standard_type', data.standard_type);
    formData.append('qualifications', data.qualifications);
    formData.append('resume', data.resume);
    formData.append('current_working', data.current_working);
    formData.append('current_salary', data.current_salary);
    formData.append('expected_salary', data.expected_salary);
    formData.append('remarks', data.remarks);
    formData.append('status_id', 7);
    await createHiringForm(formData)
      .then((response) => {
        handleToast(response.status, response.description);
        if (response.status) reset();
      })
      .catch((error) => handleToast(0, error?.response?.data?.description));
  };

  const getTeacherHiringPositionList = () => {
    getTeachersPositionList()
      .then((response) => {
        if (response.status) setPositionList(response.data);
      })
      .catch((error) => handleToast(0, error?.response?.data?.description));
  };

  const getPublicDataFunction = async () => {
    await getPublicData()
      .then((response) => {
        if (response.status) setStandardList(response?.data.standard);
      })
      .catch((error) => handleToast(0, error?.response?.data?.description));
  };

  const handleInput = (event) => {
    let { value } = event.target;
    value = value.replace(/e/gi, '');
    event.target.value = value;
  };
  const handleGetSubjectList = async () => {
    await getSubjectList()
      .then((response) => {
        if (response.status) {
          dispatch(getSubjectData(response?.data));
          dbSubjectAllData(response?.data);
        }
      })
      .catch((error) => handleToast(0, error?.response?.data?.description));
  };

  const fetchSubjectFromDB = async () => {
    const SubjectData = await dbGetAllSubjectData();
    if (SubjectData.length === 0) handleGetSubjectList();
    else if (SubjectData.length > 0) dispatch(getSubjectData(SubjectData));
  };

  useEffect(() => {
    getTeacherHiringPositionList();
    getPublicDataFunction();
    if (subjectList.length === 0) fetchSubjectFromDB();
  }, []);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(handleInsertHiringData)}>
      <Grid container>
        <Grid xs={12} md={12} sx={{ m: 0 }}>
          <Card sx={{ p: 3 }}>
            <img src={logoImage} alt="Logo" style={{ height: '65px', marginBottom: '30px' }} />
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="name" label="Name" autoFocus size="small" />
              <RHFTextField name="email" label="Email Address" size="small" />
              <RHFTextField
                name="phone"
                label="Mobile Number"
                size="small"
                inputProps={{
                  type: 'number',
                  onInput: (event) => {
                    event.target.value = event.target.value.replace(/[0-9]^/g, '');
                  },
                }}
              />
              <RHFTextField name="qualifications" label="Qualification" size="small" />
              <RHFTextField
                name="experience"
                label="Experience (In Years)"
                size="small"
                margin="dense"
                inputProps={{
                  type: 'number',
                  pattern: '[0-9]*',
                  onInput: handleInput,
                }}
              />

              <Controller
                name="position"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <RHFAutocomplete
                    name="position"
                    label="Position"
                    size="small"
                    disableClearable
                    value={positionList.find((option) => option.value === value) || null}
                    options={positionList}
                    onChange={(e, positions) => {
                      onChange(positions?.value);
                    }}
                    getOptionLabel={(option) => (value === null ? '' : option.label)}
                    isOptionEqualToValue={(option, values) => option.value === values}
                    renderOption={(props, users) => (
                      <li {...props} key={users.value}>
                        {users.label}
                      </li>
                    )}
                  />
                )}
              />

              <RHFSelect
                style={{ textAlign: 'left' }}
                name="standard_type"
                margin="dense"
                label="Standard Type"
                size="small"
              >
                {standardTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </RHFSelect>

              <Controller
                name="standard_id"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <RHFAutocomplete
                    name="standard_id"
                    label="Select Standard"
                    size="small"
                    multiple
                    disableCloseOnSelect
                    options={standardList.filter(
                      (item) => item.standard_type === watch('standard_type')
                    )}
                    onChange={(e, branches) => {
                      onChange(branches);
                    }}
                    getOptionLabel={(option) => option.standard_name}
                    isOptionEqualToValue={(option, values) =>
                      option.standard_id === values.standard_id
                    }
                    renderOption={(props, users) => (
                      <li {...props} key={users.standard_id}>
                        {users.standard_name}
                      </li>
                    )}
                    renderTags={(selected, getTagProps) =>
                      selected?.map((users, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          key={users.standard_id}
                          label={users.standard_name}
                          size="small"
                          variant="soft"
                          color="primary"
                        />
                      ))
                    }
                  />
                )}
              />

              <RHFAutocomplete
                multiple
                name="subject_id"
                label="Subject Name"
                size="small"
                disableCloseOnSelect
                options={subjectList?.filter((item) => watch('standard_type') === item.type)}
                getOptionLabel={(option) => option.subject_name}
                isOptionEqualToValue={(option, value) => option.subject_id === value.subject_id}
                renderOption={(props, subject) => (
                  <li {...props} key={subject.subject_id}>
                    {subject.subject_name}
                  </li>
                )}
                renderTags={(selected, getTagProps) =>
                  selected.map((subject, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={subject.subject_id}
                      label={subject.subject_name}
                      size="small"
                      variant="soft"
                      color="primary"
                    />
                  ))
                }
              />

              <RHFTextField
                name="current_working"
                margin="dense"
                label="Present working"
                size="small"
              />
              <RHFTextField
                name="current_salary"
                label="Present salary"
                size="small"
                margin="dense"
                inputProps={{
                  type: 'number',
                  pattern: '[0-9]*',
                  onInput: handleInput,
                }}
              />
              <RHFTextField
                name="expected_salary"
                label="Expected salary"
                size="small"
                margin="dense"
                inputProps={{
                  type: 'number',
                  pattern: '[0-9]*',
                  onInput: handleInput,
                }}
              />
              <RHFTextField
                name="remarks"
                label="Remarks"
                fullWidth
                multiline
                rows={2}
                sx={{
                  gridColumn: {
                    xs: 'span 1',
                    sm: 'span 1',
                  },
                }}
              />
              <Box textAlign="justify">
                <Typography variant="caption">Upload Resume</Typography>
                <RHFUpload
                  name="resume"
                  maxSize={3145728}
                  onDrop={handleDropSingleFile}
                  onDelete={() => setValue('resume', null, { shouldValidate: true })}
                />
              </Box>
            </Box>
            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                loading={isSubmitting}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
