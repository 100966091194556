import { Navigate, useRoutes } from 'react-router-dom';
import { isUserLoggedIn } from 'src/utils/utils';
import { mainRoutes } from './main';
import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import { paths } from '../paths';
import { publicFormRoutes } from './publicform';
import { teacherAttendancePublicFormRoutes } from './teacherattendancepublicform';
import { adminDashboardRoutes } from './admindashboard';

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={paths.auth.login} replace />,
    },
    ...authRoutes,
    ...dashboardRoutes,
    ...mainRoutes,
    ...publicFormRoutes,
    ...teacherAttendancePublicFormRoutes,
    ...adminDashboardRoutes,
    {
      path: '*',
      element: <Navigate to={!isUserLoggedIn() ? paths.auth.login : paths.page404} replace />,
    },
  ]);
}
