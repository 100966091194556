import PropTypes from 'prop-types';
import { createContext, useContext, useState, useMemo } from 'react';

const NotificationContext = createContext();

export const useNotificationContext = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [notificationData, setNotificationData] = useState(null);

  const handleSendNotification = (data) => {
    setNotificationData(data);
  };

  const handleIsArrivedNotification = () => {
    setNotificationData(null);
  };

  const contextValue = useMemo(
    () => ({
      handleSendNotification,
      handleIsArrivedNotification,
      notificationData,
    }),
    [notificationData]
  );

  return (
    <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
