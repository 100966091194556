// import { LoadingButton } from '@mui/lab';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  getAttendancePassword,
  getPublicDailyTeacherAttendance,
  insertPublicTeacherAttendance,
  updatePublicTeacherAttendance,
} from 'src/@core/api/teacherattendance';
import { passwordFieldEye } from 'src/@core/constants/variables';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import FormProvider from 'src/components/hook-form/form-provider';
import CheckInOutConfirmDialog from 'src/components/sweet-alert/checkInOutConfirmDialog';
import { useBoolean } from 'src/hooks/use-boolean';
import { handleToast } from 'src/utils/toast';

export default function TeacherAttendancePublicForm() {
  const dialog = useBoolean();
  const [attendanceData, setAttendanceData] = useState([]);
  const [checkInModalOpenClose, setCheckInModalOpenClose] = useState(false);
  const [checkOutModalOpenClose, setCheckOutModalOpenClose] = useState(false);
  const [RowData, setRowData] = useState('');
  const [teacherAttendanceId, setTeacherAttendanceId] = useState('');
  const [attendancePassword, setAttendancePassword] = useState('');
  const { branch_id } = useParams();
  const TodayDate = moment().format('DD-MM-YYYY');
  const AttendancePwd = JSON.parse(localStorage.getItem('AttendancePwd'));
  const isPassword = useBoolean();

  const handleCheckInFunction = async () => {
    const selectedScheduleTime = selectedTime[RowData.user_id] || findNearestTimeSlot(RowData, 1);
    await insertPublicTeacherAttendance({
      user_id: RowData.user_id,
      branch_id: RowData.branch_id,
      date: TodayDate,
      check_in: moment().format('HH:mm'),
      u_s_id: selectedScheduleTime,
    })
      .then((response) => {
        if (response.status) {
          setCheckInModalOpenClose(!checkInModalOpenClose);
          setRowData('');
          handleGetDailyTeacherAttendance();
        }
        handleToast(response.status, response.description);
      })
      .catch((error) => handleToast(0, error?.response?.data?.description));
  };

  const handleCheckOutFunction = async () => {
    const selectedScheduleTime = selectedTime[RowData.user_id] || findNearestTimeSlot(RowData, 1);
    await updatePublicTeacherAttendance({
      check_out: moment().format('HH:mm'),
      u_s_id: selectedScheduleTime,
      t_a_id: teacherAttendanceId,
    })
      .then((response) => {
        if (response.status) {
          setCheckOutModalOpenClose(!checkOutModalOpenClose);
          handleGetDailyTeacherAttendance();
        }
        handleToast(response.status, response.description);
      })
      .catch((error) => handleToast(0, error?.response?.data?.description));
  };

  const handleCheckInconfirmClose = () => setCheckInModalOpenClose(false);
  const handleCheckOutconfirmClose = () => setCheckOutModalOpenClose(false);

  const handleGetDailyTeacherAttendance = async () => {
    await getPublicDailyTeacherAttendance(branch_id, TodayDate)
      .then((response) => {
        setAttendanceData(response?.data);
      })
      .catch((error) => handleToast(0, error?.response?.data?.description));
  };

  const handleMatchAttendancePassword = async () => {
    await getAttendancePassword({ attendance_pwd: attendancePassword, id: branch_id })
      .then((response) => {
        if (response.status === 1) {
          localStorage.setItem('AttendancePwd', JSON.stringify(response?.data[0]));
          dialog.onFalse();
          handleGetDailyTeacherAttendance();
        } else {
          handleToast(0, response?.description);
        }
      })
      .catch((error) => handleToast(0, error?.response?.data?.description));
  };

  const findNearestTimeSlot = (row, type) => {
    const currentTime = moment();
    let minDifference = Infinity;
    let nearestTimeSlot = null;

    row?.scheduledTimeData?.forEach((option) => {
      const startTimeMoment = moment(option.s_time, 'hh:mm A');
      const endTimeMoment = moment(option.e_time, 'hh:mm A');
      const diffStart = Math.abs(currentTime.diff(startTimeMoment));
      const diffEnd = Math.abs(currentTime.diff(endTimeMoment));
      if (currentTime.isBetween(startTimeMoment, endTimeMoment) || diffStart < diffEnd) {
        if (diffStart < minDifference) {
          nearestTimeSlot = option;
          minDifference = diffStart;
        }
      }
      if (diffEnd < minDifference) {
        nearestTimeSlot = option;
        minDifference = diffEnd;
      }
    });
    return type === 1 ? nearestTimeSlot?.u_s_id : row?.target?.value;
  };

  const [selectedTime, setSelectedTime] = useState([]);
  const handleTimeChange = (row, newSelectedTime) => {
    setSelectedTime((prevSelectedTimes) => ({
      ...prevSelectedTimes,
      [row.user_id]: newSelectedTime,
    }));
  };

  useEffect(() => {
    if (AttendancePwd !== null) {
      handleGetDailyTeacherAttendance();
    } else {
      dialog.onTrue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      field: 'id',
      headerName: 'No',
      width: 100,
      sortable: false,
    },
    {
      field: 'full_name',
      headerName: 'User Name',
      width: 170,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <span
          title={`${row.first_name} ${row.last_name}`}
          style={{ border: 'none', outline: 'none' }}
        >
          {row.first_name} {row.last_name}
        </span>
      ),
    },
    {
      field: 'scheduled_time',
      headerName: 'Select Schedule Time',
      sortable: false,
      filterable: false,
      width: 200,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <FormControl variant="outlined" size="small" sx={{ width: '100%' }}>
          <Select
            name={`scheduled_time_${row.user_id}`}
            variant="outlined"
            value={selectedTime[row?.user_id] || findNearestTimeSlot(row, 1)}
            onChange={(e) => {
              const newSelectedTime = findNearestTimeSlot(e, 2);
              handleTimeChange(row, newSelectedTime);
            }}
            size="small"
            sx={{
              width: '100%',
            }}
          >
            {row?.scheduledTimeData?.map((option) => (
              <MenuItem key={option.u_s_id} value={option.u_s_id}>
                {`${option.s_time} ${option.e_time}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
    },

    {
      field: 'in_out',
      headerName: 'In/Out',
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      sortingOrder: ['desc', 'asc'],
      renderCell: ({ row }) => {
        const returnElement = [];
        if (row?.attendance_data?.length > 0) {
          // eslint-disable-next-line no-unsafe-optional-chaining
          const lastAttendance = row?.attendance_data[row?.attendance_data?.length - 1];

          if (lastAttendance?.check_out === null) {
            returnElement.push(
              <Button
                sx={{ width: '80px' }}
                size="small"
                variant="contained"
                color="error"
                onClick={() => {
                  setCheckOutModalOpenClose(!checkOutModalOpenClose);
                  setRowData(row);
                  setTeacherAttendanceId(lastAttendance?.t_a_id);
                }}
              >
                check out
              </Button>
            );
          }
          if (
            lastAttendance?.check_in === null ||
            (lastAttendance?.check_in !== null && lastAttendance?.check_out !== null)
          ) {
            returnElement.push(
              <Button
                sx={{ width: '80px' }}
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  setCheckInModalOpenClose(!checkInModalOpenClose);
                  setRowData(row);
                }}
              >
                check in
              </Button>
            );
          }
        } else {
          returnElement.push(
            <Button
              sx={{ width: '80px' }}
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                setCheckInModalOpenClose(!checkInModalOpenClose);
                setRowData(row);
              }}
            >
              check in
            </Button>
          );
        }

        return returnElement;
      },
    },

    {
      field: 'Time',
      headerName: 'Time',
      width: 500,
      sortingOrder: ['desc', 'asc'],
      renderCell: ({ row }) => (
        <>
          {row?.attendance_data?.map((option, index) => (
            <span
              key={index}
              title={`${index !== 0 ? ' , ' : ''}${option.check_in} - ${
                option.check_out == null ? 'Working' : option.check_out
              }`}
            >
              {index !== 0 ? ' , ' : ''}
              {moment(option.check_in, 'HH:mm').format('hh:mm A')} -{' '}
              {option.check_out == null
                ? 'Working'
                : moment(option.check_out, 'HH:mm').format('hh:mm A')}
            </span>
          ))}
        </>
      ),
    },
  ];

  return (
    <>
      {AttendancePwd !== null && (
        <CustomBreadcrumbs
          heading={`${AttendancePwd?.branch_name} - Attendance ${moment().format(
            'DD-MM-YYYY hh:mm'
          )}`}
          sx={{
            p: 1,
            mb: { xs: 1, sm: 0 },
          }}
        />
      )}

      <Card>
        {AttendancePwd !== null && (
          <DataGrid
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableColumnMenu
            columns={columns}
            rowHeight={45}
            rows={attendanceData?.map((item, index) => ({ id: index + 1, ...item }))}
            getRowId={(row) => row?.user_id}
            localeText={{ noRowsLabel: 'There are No Records to Display' }}
            sx={{
              [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                outline: 'none !important',
              },
              [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
                outline: 'none !important',
              },
              '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                outline: 'none !important',
              },
            }}
          />
        )}
      </Card>

      <Dialog open={dialog.value} onClose={dialog.onFalse}>
        <FormProvider>
          <DialogTitle>Daily Attendance</DialogTitle>
        </FormProvider>
        <DialogContent>
          <Stack sx={{ p: 1 }}>
            <TextField
              size="small"
              label="Password"
              margin="dense"
              autoFocus
              type={isPassword.value ? 'text' : 'password'}
              variant="outlined"
              InputProps={{
                endAdornment: passwordFieldEye(isPassword),
              }}
              onChange={(e) => setAttendancePassword(e.target.value)}
              value={attendancePassword}
              sx={{
                width: 'auto',
                my: { xs: 1, sm: 0 },
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => handleMatchAttendancePassword()}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <CheckInOutConfirmDialog
        type="checkIn"
        title={`${RowData.first_name} ${RowData.last_name} Do You Want To Check In ?`}
        open={checkInModalOpenClose}
        onClose={handleCheckInconfirmClose}
        onConfirm={handleCheckInFunction}
      />

      <CheckInOutConfirmDialog
        type="checkOut"
        title={`${RowData.first_name} ${RowData.last_name} Do You Want To Check Out ?`}
        open={checkOutModalOpenClose}
        onClose={handleCheckOutconfirmClose}
        onConfirm={handleCheckOutFunction}
      />
    </>
  );
}
