import { lazy } from "react";
import { Outlet } from "react-router-dom";
import { GuestGuard } from "src/auth/guard";

import AuthClassicLayout from "src/layouts/auth/classic";

const JwtLoginPage = lazy(() => import("src/pages/auth/login"));
const ForgotPassword = lazy(() => import("src/pages/auth/forgotpassword"));
const ResetPassword = lazy(() => import("src/pages/auth/resetpassword"));

export const authRoutes = [
  {
    path: "/",
    element: (
      <GuestGuard>
        <AuthClassicLayout>
          <Outlet />
        </AuthClassicLayout>
      </GuestGuard>
    ),
    children: [
      { path: "login", element: <JwtLoginPage /> },
      { path: "forgotpassword", element: <ForgotPassword /> },
      { path: "reset-password/:token", element: <ResetPassword /> },
    ],
  },
];
