import { DbName, DbVersion, StoreName } from 'src/@core/constants/db_name';
import { dbDeleteData, dbGetAllData, dbInsertAllData, dbUpdateData } from './crudDB';
import { dbPromise } from './connectionDB';

export async function dbDepartmentAllData(data) {
  await dbInsertAllData(DbName.DEPARTMENTDB, DbVersion.FIRST, StoreName.DEPARTMENT, data);
}

export async function dbGetAllDepartmentData() {
  return dbGetAllData(DbName.DEPARTMENTDB, DbVersion.FIRST, StoreName.DEPARTMENT, 'id');
}

export async function dbUpdateDepartmentData(data) {
  await dbUpdateData(
    DbName.DEPARTMENTDB,
    DbVersion.FIRST,
    StoreName.DEPARTMENT,
    'id',
    'dept_id',
    data
  );
}

export async function dbDeleteDepartmentData(id) {
  await dbDeleteData(
    DbName.DEPARTMENTDB,
    DbVersion.FIRST,
    StoreName.DEPARTMENT,
    'id',
    'dept_id',
    id
  );
}

export async function dbDepartmentDataInsert(data) {
  const db = await dbPromise(DbName.DEPARTMENTDB, DbVersion.FIRST, StoreName.DEPARTMENT, 'id');
  const tx = db.transaction(StoreName.DEPARTMENT, 'readwrite');
  const store = tx.objectStore(StoreName.DEPARTMENT);
  const dbData = await store.getAll();
  const statusdata = [{ ...data }, ...dbData[0].data];
  await store.put({ id: 1, data: statusdata });
  await tx.complete;
}
