import { Suspense } from 'react';
import { Outlet } from 'react-router';
import { SplashScreen } from 'src/components/loading-screen';
import AdminDashboardLayout from 'src/layouts/compact/adminDashboardLayout';
import AdminDashboard from 'src/pages/dashboard/adminDashboard';

export const adminDashboardRoutes = [
  {
    element: (
      <AdminDashboardLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </AdminDashboardLayout>
    ),
    children: [
      {
        path: 'admindashboard',
        element: <AdminDashboard />,
      },
    ],
  },
];

