import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { revertAll } from './index';

const initialState = {
  featuresList: [],
};

export const featureSlice = createSlice({
  name: 'feature',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    getFeatureList: (state, action) => {
      state.featuresList = action.payload;
    },
  },
});

export const { getFeatureList } = featureSlice.actions;

export default featureSlice.reducer;
