import PropTypes from 'prop-types';
import { Navigate } from 'react-router';
import {
  IsNotValue,
  attendanceRoute,
  attributesRoute,
  dashboardRoute,
  generalSettingsRoute,
  reportRoute,
  studentRoute,
  transactionRoute,
  vanRoute,
} from 'src/@core/constants/variables';
import { paths } from 'src/routes/paths';
import { isUserLoggedIn } from 'src/utils/utils';

export default function RouteGuard({ children }) {
  const user = isUserLoggedIn();
  const permission = JSON.parse(localStorage.getItem('PermissionData'));
  const userWiseBranchData = JSON.parse(localStorage.getItem('UserWiseBranchData'));

  const avoidRoute = [paths.dashboard.root, paths.profile, paths.facultytimetable];

  if ([1, 2].includes(user.role_id)) avoidRoute.push(paths.websetting);

  permission.map((data) => {
    const updatedGeneralSettingsRoute = generalSettingsRoute.filter(
      (item) =>
        !(
          (userWiseBranchData !== null
            ? userWiseBranchData?.branch_type === 1
            : user?.branch_type === 1) &&
          (item === 'School' || item === 'Board')
        )
    );
    if (studentRoute.includes(data.f_name) && data.view === 1) {
      avoidRoute.push(`${paths.student}${paths[data.f_name.toLowerCase().replaceAll(' ', '')]}`);
    } else if (attributesRoute.includes(data.f_name) && data.view === 1) {
      avoidRoute.push(`${paths.attributes}${paths[data.f_name.toLowerCase().replaceAll(' ', '')]}`);
    } else if (updatedGeneralSettingsRoute.includes(data.f_name) && data.view === 1) {
      avoidRoute.push(
        `${paths.generalsetting}${paths[data.f_name.toLowerCase().replaceAll(' ', '')]}`
      );
    } else if (attendanceRoute.includes(data.f_name) && data.view === 1) {
      if (user.role_id !== 1) {
        avoidRoute.push(
          `${paths.attendance}${paths[data.f_name.toLowerCase().replaceAll(' ', '')]}`
        );
      } else if (data.f_name === 'Teacher Attendance') {
        avoidRoute.push(
          `${paths.attendance}${paths[data.f_name.toLowerCase().replaceAll(' ', '')]}`
        );
      } else if (data.f_name === 'Teacher Attendance Status') {
        avoidRoute.push(
          `${paths.attendance}${paths[data.f_name.toLowerCase().replaceAll(' ', '')]}`
        );
      } else if (data.f_name === 'Teacher Attendance Report') {
        avoidRoute.push(
          `${paths.attendance}${paths[data.f_name.toLowerCase().replaceAll(' ', '')]}`
        );
      } else if (data.f_name === 'My Attendance') {
        avoidRoute.push(
          `${paths.attendance}${paths[data.f_name.toLowerCase().replaceAll(' ', '')]}`
        );
      }
    } else if (vanRoute.includes(data.f_name) && data.view === 1) {
      if (data.f_name === 'Assign Student To Van') {
        avoidRoute.push(`${paths.van}${paths[data.f_name.toLowerCase().replaceAll(' ', '')]}`);
      } else if (data.f_name === 'Daily Van Attendance') {
        avoidRoute.push(`${paths.van}${paths[data.f_name.toLowerCase().replaceAll(' ', '')]}`);
      }
    } else if (reportRoute.includes(data.f_name) && data.view === 1) {
      if (data.f_name === 'Fees Due Report') {
        avoidRoute.push(`${paths.reports}${paths[data.f_name.toLowerCase().replaceAll(' ', '')]}`);
      } else if (data.f_name === 'Fees Collection Report') {
        avoidRoute.push(`${paths.reports}${paths[data.f_name.toLowerCase().replaceAll(' ', '')]}`);
        avoidRoute.push(`${paths.reports}/generatedfeesreports`);
      } else if (data.f_name === 'Student Attendance Report') {
        avoidRoute.push(`${paths.reports}${paths[data.f_name.toLowerCase().replaceAll(' ', '')]}`);
      } else if (data.f_name === 'Experience Letter') {
        avoidRoute.push(`${paths.reports}${paths[data.f_name.toLowerCase().replaceAll(' ', '')]}`);
      }else if (data.f_name === 'Appointment Letter') {
        avoidRoute.push(`${paths.reports}${paths[data.f_name.toLowerCase().replaceAll(' ', '')]}`);
      } else if(data.f_name === 'Daily Income Expense Report') {
        avoidRoute.push(`${paths.reports}${paths[data.f_name.toLowerCase().replaceAll(' ', '')]}`);
      }
    } else if (dashboardRoute.includes(data.f_name) && data.view === 1) {
      if (data.f_name === 'Student Dashboard') {
        avoidRoute.push(
          `${paths.admindashboards}${paths[data.f_name.toLowerCase().replaceAll(' ', '')]}`
        );
      } else if (data.f_name === 'Finance Dashboard') {
        avoidRoute.push(
          `${paths.admindashboards}${paths[data.f_name.toLowerCase().replaceAll(' ', '')]}`
        );
      }
    } else if (transactionRoute.includes(data.f_name) && data.view === 1) {
      avoidRoute.push(
        `${paths.transaction}${paths[data.f_name.toLowerCase().replaceAll(' ', '')]}`
      );
    }
    return null;
  });
  const showPage = permission?.find(
    (perdata) =>
      perdata.f_name.toLowerCase().trim().replace(/\s+/g, '') ===
        window.location.pathname.replace('/', '') &&
      perdata.view === 1 &&
      !(perdata.f_name === 'EOW' && user.branch_type === 1)
  );

  if (IsNotValue.includes(showPage) && !avoidRoute.includes(window.location.pathname)) {
    return <Navigate to="/404" />;
  }

  return <>{children}</>;
}

RouteGuard.propTypes = {
  children: PropTypes.node,
};
