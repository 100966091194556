import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { revertAll } from './index';

const initialState = { allStudentList: [] };

export const studentSlice = createSlice({
  name: 'student',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    getAllStudents: (state, action) => {
      state.allStudentList = action.payload;
    },
  },
});

export const { getAllStudents } = studentSlice.actions;

export default studentSlice.reducer;
