import { ApiRoutes } from '../constants';
import Request from '.';

export const getStudentsData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.GETSTUDENTSDATA, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateStudentStatus = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.UPDATESTUDENTSTATUS, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateStudentData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.UPDATESTUDENTDATA, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const insertStudentData = async (data) => {
  const result = await new Promise((resolve, reject) => {
    Request.post(ApiRoutes.INSERTSTUDENTDATA, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

export const deleteStudentData = async (data) => {
  const result = await new Promise((resolve, reject) => {
    Request.delete(`${ApiRoutes.DELETESTUDENTDATA}/${data}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

// GET Public Data
export const getPublicData = async () => {
  const result = await new Promise((resolve, reject) => {
    Request.get(ApiRoutes.GETPUBLICDATA)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

// Get Public Batch Data
export const getPublicFormBatchData = async (data) => {
  const result = await new Promise((resolve, reject) => {
    Request.post(ApiRoutes.GETPUBLICBATCHDATA, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

// Insert Public Data
export const insertPublicFormData = async (data) => {
  const result = await new Promise((resolve, reject) => {
    Request.post(ApiRoutes.INSERTPUBLICFORMDATA, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

export const addCancelReasonData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.ADDCANCELREASON, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const insertParentsDetail = async (data) => {
  const result = await new Promise((resolve, reject) => {
    Request.post(ApiRoutes.INSERTPARENTSDETAIL, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

export const checkUserNameExist = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.CHECKUSERNAMEEXIST, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteMultipleStudentsData = async (data) => {
  const result = await new Promise((resolve, reject) => {
    Request.post(ApiRoutes.DELETEMULTIPLESTUDENTS, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

export const getParentsData = async (data) => {
  const result = await new Promise((resolve, reject) => {
    Request.post(ApiRoutes.GETPARENTSDATA, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

export const addStudentFollowup = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.ADDSTUDENTFOLLOWUP, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getStudentFollowupData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.GETSTUDENTFOLLOWUPDATA, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getStudentHistory = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.GETSTUDENTHISTORY, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
