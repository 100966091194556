import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';

export default function CheckInOutConfirmDialog({
  type,
  open,
  onClose,
  onConfirm,
  title,
  ...other
}) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ pb: 2 }}>{`${title}`}</DialogTitle>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          No
        </Button>
        <LoadingButton variant="contained" color="error" onClick={onConfirm} autoFocus>
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

CheckInOutConfirmDialog.propTypes = {
  type: PropTypes.oneOf(['checkIn', 'checkOut']).isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};
