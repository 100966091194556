/* eslint-disable import/no-cycle */
import { createAction } from '@reduxjs/toolkit';

import user from './user';
import Fees from './fees';
import role from './role';
import board from './board';
import color from './color';
import batch from './batch';
import school from './school';
import branch from './branch';
import feature from './feature';
import subject from './subject';
import chapter from './chapter';
import standard from './standard';
import timeslot from './timeslot';
import department from './department';
import attributes from './attributes';
import paymentmethod from './paymentmethod';
import teacherhiring from './teacherhiring';
import studentinquiry from './studentinquiry';
import expensetype from './expensetype';
import categorylist from './categorylist';
import attendancestatus from './attendancestatus';
import feestype from './feesType';
import student from './student';
import teachertraining from './teachertraining';
import holiday from './holiday';

export const revertAll = createAction('REVERT_ALL');

const rootReducer = {
  role,
  user,
  Fees,
  board,
  color,
  batch,
  school,
  branch,
  feature,
  chapter,
  subject,
  standard,
  timeslot,
  department,
  attributes,
  paymentmethod,
  teacherhiring,
  studentinquiry,
  expensetype,
  categorylist,
  attendancestatus,
  feestype,
  student,
  teachertraining,
  holiday,
};

export default rootReducer;
