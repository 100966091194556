import { Suspense } from 'react';
import { Outlet } from 'react-router';
import { SplashScreen } from 'src/components/loading-screen';
import TeacherAttendancePublicFormLayout from 'src/layouts/compact/teacherAttendancePublicFormLayout';
import TeacherAttendancePublicForm from 'src/pages/Public Form/teacherAttendancePublicForm';

export const teacherAttendancePublicFormRoutes = [
  {
    element: (
      <TeacherAttendancePublicFormLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </TeacherAttendancePublicFormLayout>
    ),
    children: [
      {
        path: ':branch_id/teacherattendancepublicform',
        element: <TeacherAttendancePublicForm />,
      },
    ],
  },
];
