import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { varHover } from 'src/components/animate';
import { InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import { useSettingsContext } from 'src/components/settings';
import { useCallback } from 'react';
//
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import copy from 'copy-to-clipboard';
import { handleToast } from 'src/utils/toast';
import { HEADER, NAV } from '../config-layout';
import { Searchbar, AccountPopover, NotificationsPopover } from '../_common';

// ----------------------------------------------------------------------

export default function Header({ onOpenNav }) {
  const theme = useTheme();
  const popover = usePopover();

  const settings = useSettingsContext();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const onCopy = useCallback((text) => {
    if (text) {
      copy(text);
      handleToast(1, 'Hiring Form Link Copied...');
    }
  }, []);

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      <Searchbar />

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        <NotificationsPopover />
        <IconButton
          component={m.button}
          whileTap="tap"
          title="Teacher Hiring Form Url"
          whileHover="hover"
          variants={varHover(1.05)}
          color={popover.open ? 'primary' : 'default'}
          onClick={popover.onOpen}
          sx={{
            ...(popover.open && {
              bgcolor: (themec) => themec.palette.action.selected,
            }),
          }}
        >
          <Iconify icon="solar:users-group-rounded-bold-duotone" width={24} />
        </IconButton>
        <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 320 }}>
          <Typography variant="h6" sx={{ p: 1.5 }}>
            Hiring Form Url
          </Typography>
          <TextField
            fullWidth
            size="small"
            disabled
            sx={{
              px: 1,
              mb: 1,
            }}
            value={`${window.location.origin}/hiringform`}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Copy">
                    <IconButton onClick={() => onCopy(`${window.location.origin}/hiringform`)}>
                      <Iconify icon="eva:copy-fill" width={24} />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </CustomPopover>
        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
