export const ApiRoutes = {
  API_HOSTNAME: process.env.REACT_APP_API_HOSTNAME,
  WEBSOCKET_URL: process.env.REACT_APP_WEB_SOCKET_URL,

  // Branch
  INSERTBRANCH: 'insertbranch',
  GETBRANCH: 'getbranch',
  UPDATEBRANCH: 'updatebranch',
  DELETEBRANCHADDRESS: 'deletebranchaddress',
  DELETEBRANCH: 'deletebranch',
  GETBRANCHADDRESS: 'getbranchaddress',

  // BOARD
  INSERTBOARD: 'insertboard',
  UPDATEBOARD: 'updateboard',
  DELETEBOARD: 'deleteboard',
  GETBOARDLIST: 'getboardlist',

  // Auth
  LOGIN: 'login',
  FORGOTPASSWORD: 'forgot-password',
  RESETPASSWORD: 'changeforgotpassword',
  INSERTROLE: 'insertrole',
  UPDATEROLE: 'updaterole',
  UPDATEROLESTATUS: 'updaterolestatus',
  GETROLES: 'getroles',
  GETFEATURES: 'getfeatures',
  GETROLEPERMISSION: 'getrolepermission',
  UPDATEROLEPERMISSION: 'updaterolepermission',

  // STANDARD
  INSERTSTANDARDS: 'insertstandard',
  GETSTANDARDS: 'getstandard',
  DELETESTANDARDS: 'deletestandard',
  UPDATESTANDARDS: 'updatestandard',

  // SCHOOL
  ADDSCHOOL: 'insertschool',
  UPDATESCHOOL: 'updateschool',
  DELETESCHOOL: 'deleteschool',
  GETSCHOOLDATA: 'getschooldata',

  // DEPARTMENT
  INSERTDEPARTMENT: 'insertdepartment',
  UPDATEDEPARTMENT: 'updatedepartment',
  GETDEPARTMENT: 'getdepartment',
  DELETEDEPARTMENT: 'deletedepartment',
  GETBRANCHADDRESSWISEDEPARTMENT: 'getbranchaddresswisedepartment',

  // DAILY WORK
  INSERTDAILYWORK: 'insertdailywork',
  GETTEACHERDAILYWORK: 'getteacherdailywork',
  GETDAILYWORKASSIGNEDSTUDENTS: 'getstudentlistofassigndailywork',
  UPDATEDAILYWORK: 'updatedailywork',
  DELETEDAILYWORK: 'deletedailyworkdata',
  // GETBATCHANDTEACHERWISESUBJECTS: 'getbatchandteacherwisesubjects',
  // GETBATCHWISESTUDENTLIST: 'getbatchwisestudentlist',

  // COLOR
  INSERTCOLOR: 'insertcolor',
  GETCOLORLIST: 'getcolorlist',
  UPDATECOLOR: 'updatecolor',
  DELETECOLOR: 'deletecolor',

  // SUBJECT
  INSERTSUBJECT: 'insertsubject',
  GETSUBJECTLIST: 'getsubjectlist',
  UPDATESUBJECT: 'updatesubject',
  DELETESUBJECT: 'deletesubject',
  GETSUBJECTWISESCHOOLANDSTANDARDLIST: 'getsubjectwiseschoolandstandardlist',

  // CHAPTER
  INSERTCHAPTER: 'insertchapter',
  GETCHAPTER: 'getchapterlist',
  UPDATECHAPTER: 'updatechapter',
  DELETECHAPTER: 'deletechapter',

  // Timeslot
  GETTIMESLOT: 'gettimeslotlist',
  INSERTTIMESLOT: 'inserttimeslot',
  UPDATETIMESLOT: 'updatetimeslot',
  DELETETIMESLOT: 'deletetimeslot',

  // BATCH
  INSERTBATCH: 'insertbatch',
  GETBATCHLIST: 'getbatchlist',
  GETSUBJECTANDFACULTYLIST: 'getsubjectandfacultylist',
  DELETEBATCH: 'deletebatch',
  UPDATEBATCH: 'updatebatch',
  UPDATEBATCHSTATUS: 'updatebatchstatus',
  GETTAECHERLIST: 'getteacherlist',
  GETBATCHDATAINBOARDVIEW: 'getbatchdatainboardview',
  ISTAECHERALREADYASSIGN: 'isteacheralreadyassign',
  GETCONFIRMANDTRIALSTUDENTLIST: 'getconfirmandtrialstudentlist',

  // FEES
  INSERTFEES: 'insertfees',
  GETFEES: 'getfees',
  DELETEFEES: 'deletefees',
  UPDATEFEES: 'updatefees',

  // USER
  GETUSER: 'getuser',
  ADDUSER: 'insertuser',
  DELETEUSER: 'deleteuser',
  UPDATEUSER: 'updateuser',
  UPDATEUSERSTATUS: 'updateuserstatus',
  UPDATESTANDARDSTATUS: 'updateStandardstatus',
  DELETEUSERATTACHMENT: 'deleteuserattachment',
  UPDATEUSERPROFILE: 'updateuserprofile',
  DELETEUSERSCHEDULETIME: 'deleteuserschedule',
  USERNAMEEXISTCHECK: 'getexistsusername',
  GETATTACHMENTDEPARTMENTSCHEDULEDATA: 'getattachmentdepartmentschedule',

  // PAYMENT METHOD
  GETPAYMENTMETHOD: 'getpaymentmethodlist',
  INSERTPAYMENTMETHOD: 'insertpaymentmethod',
  UPDATEPAYMENTMETHOD: 'updatepaymentmethod',
  DELETEPAYMENTMETHOD: 'deletepaymentmethod',
  GETBRANCHWISEDEPARTMENT: 'getbranchwisedepartment',
  GETBRANCHLOCATIONWISEDEPARTMENT: 'getbranchlocationwisedepartment',
  GETUSERDATA: 'getuserdata', // user data without pagination

  // STUDENT INQUIRY
  GETSTUDENTSDATA: 'getstudentlist',
  UPDATESTUDENTSTATUS: 'updatestudentstatus',
  UPDATESTUDENTDATA: 'updatestudent',

  // ATTRIBUTES
  GETATTRIBUTESLIST: 'getattributeslist',
  INSERTSTATUSATTRIBUTES: 'insertattribute',
  UPDATESTATUSATTRIBUTES: 'updateattribute',
  DELETESTATUSATTRIBUTES: 'deleteattribute',
  GETMEETINGTYPE: 'getmeetingtypedata',
  INSERTMEETINGTYPE: 'insertmeetingtype',
  UPDATEMEETINGTYPE: 'updatetmeetingdtypeata',
  DELETEMEETINGTYPE: 'deletetmeetingtypedata',

  // WEBSETTING
  GETWEBSETTING: 'getwebsetting',
  UPDATEWEBSETTING: 'updatewebsetting',
  TESTMAIL: 'testmail',
  BRANCHWISEUPDATEWEBSETTING: 'branchwiseupdatewebsetting',
  BRANCHWISEGETWEBSETTING: 'branchwisegetwebsetting',
  UPDATEENROLLMENTWEBSETTING: 'updateenrollmentwebsetting',

  // STUDENT
  GETBATCHDATA: 'getbatchdata',
  GETBATCHNAME: 'getbatchname',
  GETALLUSERDATA: 'getalluserlist',
  INSERTSTUDENTDATA: 'insertstudent',
  DELETESTUDENTDATA: 'deletestudent',
  GETFEESDATA: 'getfeesdata',
  GETPUBLICDATA: 'getpublicdata',
  INSERTPUBLICFORMDATA: 'insertpublicformdata',
  ADDCANCELREASON: 'addcancelreason',
  INSERTPARENTSDETAIL: 'insertparentsdetail',
  CHECKUSERNAMEEXIST: 'checkusernameexist',
  GETPUBLICBATCHDATA: 'getpublicbatchdata',
  GETALLBATCHDATA: 'getallbatch',
  DELETEMULTIPLESTUDENTS: 'deletemultiplestudent',
  GETPARENTSDATA: 'getparentsdata',
  FINDSTUDENTDATA: 'findstudentdata',
  FINDPARENTSDATA: 'findparentsdata',

  // STUDENT FOLLOWUP
  ADDSTUDENTFOLLOWUP: 'addstudentfollowup',
  GETSTUDENTFOLLOWUPDATA: 'getstudentfollowupdata',
  GETSTUDENTHISTORY: 'getstudenthistory',

  // TEACHER HIRING
  UPDATETEACHERHIRINGSTATUS: 'updatestatus',
  CREATETEACHERHIRING: 'insertteacherhiring',
  UPDATETEACHERHIRING: 'updateteacherhiring',
  GETTEAHERHIRINGLIST: 'getteacherhiringlist',
  GETTEACHERPOSITIONLIST: 'getteacherposition',
  DELETETEACHERHIRINGDETAILS: 'deleteteacherhiring',
  INSERTHIRINGFORM: 'inserthiringform',

  // STUDY MATERIALS
  INSERTSTUDYMATERIAL: 'insertstudymaterial',
  DELETESTUDYMATERIAL: 'deletestudymaterial',
  BATCHESASSIGNEDSUBJECTS: 'batchesassignedsubjects',
  STUDYMATERIALFILEANDFOLDER: 'studymaterialfileandfolder',
  DYNAMICFOLDERSFILEDATA: 'dynamicfoldersfiledata',
  GETDEPTWISEBATCHDATA: 'getdeptwisebatchdata',

  // COMMON
  GETFACULTYBATCH: 'getfacultybatch',
  GETADMINSUBBRANCH: 'getadminsubbranch',

  // TEACHER ATTENDANCE
  GETTEACHERATTENDANCE: 'getteacherattendance',
  DOWNLOADTEACHERATTENDANCE: 'downloadteacherattendance',
  GETBRANCHWISETEACHER: 'getteacherlistbybranch',
  // GETBRANCHWISEBATCHTEACHERATTENDANCE: 'getbatchlistbybranch',
  // GETBATCHWISEUSERTEACHERATTENDANCE: 'getteacherlistbybatch',
  GETDAILYTEACHERATTENDANCE: 'getdailyteacherattendance',
  INSERTTEACHERATTENDANCE: 'insertteacherattendance',
  UPDATETEACHERATTENDANCE: 'updateteacherattendance',
  INSERTATTENDANCEPASSWORD: 'insertattendancepassword',
  GETATTENDANCEPASSWORD: 'getattendancepassword',

  GETPUBLICTEACHERLISTBYBRANCH: 'getpublicteacherlistbybranch',
  INSERTPUBLICTEACHERATTENDANCE: 'insertpublicteacherattendance',
  UPDATEPUBLICTEACHERATTENDANCE: 'updatepublicteacherattendance',
  GETPUBLICDAILYTEACHERATTEDANCE: 'getpublicdailyteacherattendance',

  // Leave
  GETLEAVE: 'getleave',
  GETLEAVEDATA: 'getleavedata',
  UPDATELEAVESTATUS: 'updateleavestatus',
  GETLEAVEATTACHMENT: 'getleaveattachment',
  INSERTLEAVE: 'insertleave',
  UPDATELEAVE: 'updateleave',
  DELETELEAVEATTACHMENT: 'deleteleaveattachment',
  DELETELEAVE: 'deleteleave',

  // TIME TABLE
  INSERTTIMETABLE: 'inserttimetable',
  GETTIMETABLEDATA: 'gettimetabledata',
  UPDATETIMETABLEDATA: 'updatetimetabledata',
  GETFACULTYTIMETABLEDATA: 'getfacultytimetabledata',
  GETSCHOOLWISETIMETABLEDATA: 'getschoolwisetimetabledata',

  // MEETINGS
  INSERTMEETINGDATA: 'insertmeetingdata',
  UPDATEMEETINGDATA: 'updatemeetingdata',
  UPDATEMEETINGSTATUS: 'updateMeetingStatus',
  GETMEETINGDATA: 'getmeetingsdata',
  DELETEMEETINGDATA: 'deletemeetingdata',
  INSERTMEETINGAGENDA: 'insertMeetingAgenda',
  UPDATEMEETINGATTENDANCESTATUS: 'updatemeetingattendancestatus',
  GETMEETINGAGENDADATA: 'getmeetingagendadata',
  GETMEETINGAGENDAASSIGNUSERS: 'getmeetingagendaassignusers',
  UPDATEMEETINGAGENDATASKSTATUS: 'updatemeetingagendataskstatus',
  UPDATEMEETINGAGENDA: 'updatemeetingagenda',
  DELETEMEETINGAGENDA: 'deletemeetingagenda',
  GETBATCHWISEUSERS: 'deptsbatchesfacultydata',
  GETCONFIRMSTUDENTSLIST: 'getconfirmstudents',
  GETMEETINGASSIGNUSERS: 'meetingassignusers',
  GETMEETINGATTRIBUTES: 'meetingattributedata',
  GETNOTIFICATIONDATA: 'getnotificationdata',
  ADDABSENTREASON: 'addabsentreason',

  // GALLERY
  GETGALLERYFILEANDFOLDER: 'getgalleryfileandfolder',
  INSERTGALLERYMEDIA: 'insertgallerymedia',

  // NOTIFICATION
  READNOTIFICATION: 'readnotification',

  // EXPENSE TYPE
  INSERTEXPENSETYPE: 'insertexpensetype',
  GETEXPENSETYPELIST: 'getexpensetypelist',
  DELETEEXPENSETYPE: 'deleteexpensetype',
  UPDATEEXPENSETYPE: 'updateexpensetype',

  // INCOME EXPENSE CATEGORY
  INSERTCATEGORYLIST: 'insertcategory',
  GETCATEGORYLIST: 'getcategorydata',
  DELETECATEGORY: 'deletecategory',
  UPDATECATEGORY: 'updatecategory',

  // EXPENSE
  INSERTINCOMEEXPENSE: 'insertincomeexpense',
  GETINCOMEEXPENSELIST: 'getincomeexpenselist',
  DELETEINCOMEEXPENSE: 'deleteincomeexpense',
  UPDATEINCOMEEXPENSE: 'updateincomeexpense',
  GETTRANSACTIONATTACHMENT: 'gettransactionattachment',
  DELETETRANSATIONATTACHMENT: 'deletetransationattachment',

  // TEACHER ATTENDANCE STATUS
  INSERTTEACHERATTENDANCESTATUS: 'insertteacherattendancestatus',
  GETTEACHERATTENDANCESTATUS: 'getteacherattendancestatus',
  UPDATETEACHERATTENDANCESTATUS: 'updateteacherattendancestatus',

  // FEES TYPE
  INSERTFEESTYPE: 'insertfeestype',
  GETFEESTYPELIST: 'getfeestypelist',
  DELETEFEESTYPE: 'deletefeestype',
  UPDATEFEESTYPE: 'updatefeestype',

  // INVOICE
  GETBRANCHWISEUSERSLIST: 'getbranchwiseuserslist',
  GETINVOICEDATA: 'getinvoicelist',
  GETALLSTUDENTSLIST: 'getallstudentslist',
  DELETEINVOICE: 'deleteinvoice',
  DOWNLOADFEESRECIEPT: 'downloadfeesreciept',
  GETBRANCHWISEADDRESS: 'getbranchwiseaddress',
  GETSTUDENTSLIST: 'getstudentdata',
  INSERTINVOICEDATA: 'insertinvoice',
  UPDATEINVOICEDATA: 'updateinvoice',
  GETSTUDENTFEESINVOICEDATA: 'getstudentfeeslist',
  GETINVOICEDETAIL: 'getinvoicedetail',

  // STUDENT TEST
  GETSTUDENTTESTDATA: 'getstudenttestdata',
  GETSTUDENTTESTDETAILSDATA: 'getstudenttestdetailsdata',
  GETTESTDETAILSWITHOUTPAGINATION: 'gettestdetailswithoutpagination',
  GETUSERWISEBATCHDATA: 'getuserwisebatchlist',
  GETBATCHANDUSERWISESUBJECTS: 'getbatchanduserwisesubjects',
  INSERTSTUDENTTESTDATA: 'inserttestdata',
  GETSTUDENTDETAILSDATA: 'getstudentdetailsdata',
  UPDATESTUDENTTESTDATA: 'updatetestdata',
  DELETETESTDATA: 'deletetestdata',
  UPDATEDSTAUSDATA: 'updatedstausdata',
  INSERTSTUDENTSTESTMARKS: 'addstudentstestmarksdata',
  UPDATESTUDENTSTESTMARKS: 'updatestudentstestmarksdata',
  GETBATCHWISESUBJECTDATA: 'getbatchwisesubject',

  // EOW
  GETEOWDISPLAYDATA: 'geteowdata',
  GETUSERWISEBATCHLIST: 'getbatchandchapterlist',
  GETBATCHANDTEACHERWISESUBJECTS: 'getbatchandteacherwisesubjects',
  GETCHPATERLISTSUBJECTANDSTANDARDWISE: 'getchpaterlistsubjectandstandardwise',
  EXPORTSTANDARDWISEEOWREPORT: 'exporteowdata',
  GETBATCHWISESTUDENTLIST: 'getbatchwisestudentlist',
  INSERTEOW: 'inserteow',
  UPDATEEOW: 'updateeow',
  DELETEEOW: 'deleteeow',
  GETEOWDATASTUDENTGROUPWISE: 'geteowdatastudentgroupwise',

  // HOLIDAYS
  INSERTHOLIDAYS: 'insertholidays',
  GETHOLIDAYSLIST: 'getholidayslist',
  DELETEHOLIDAYS: 'deleteholidays',
  UPDATEHOLIDAYS: 'updateholidays',

  // ATTENDANCE REPORT
  UPDATECHECKINCHECKOUT: 'updatecheckincheckout',

  // ACTIVITY
  GETSTANDARDWISEBATCHLIST: 'getstandardwisebatchlist',
  GETBATCHWISEUSERLIST: 'getbatchwiseuserlist',
  INSERTACTIVITY: 'insertactivity',
  GETACTIVITYLIST: 'getactivitylist',
  GETACTIVITYBATCHWISESTUDENTLIST: 'getactivitybatchwisestudentlist',
  UPDATEACTIVITY: 'updateactivity',
  DELETEACTIVITY: 'deleteactivity',
  INSERTACTIVITYSTUDENTATTENDENCEANDRATTING: 'insertactivitystudentattendenceandratting',
  GETSTUDENTPERFORMANCEACTIVITYWISE: 'getstudentperformanceactivitywise',
  UPDATESTUDENTATTENDENCEANDRATTING: 'updatestudentattendenceandratting',
  GETACTIVITYLISTWITHPAGINATION: 'getactivitylistwithpagination',

  // TEACHER TARINING
  INSERTTEACHERTRAINING: 'insertteachertraining',
  UPDATETEACHERTRAINING: 'updateteachertraining',
  GETTEACHERTRAINING: 'getteachertraining',
  DELETETEACHERTRAINING: 'deleteteachertraining',
  GETDEPARTMENTWISETRAININGMEDIA: 'getdepartmentwisetrainingmedia',

  // MY ATTENDANCE
  GETMYATTENDANCE: 'getmyattendance',
  INSERTNOTE: 'insertnote',

  // NEWS CORNER
  INSERTNEWSDATA: 'insertnewsdata',
  GETNEWSCORNERDATA: 'getnewscornerdata',
  UPDATENEWSCORNERDATA: 'updatenewscornerdata',
  DELETENEWSCORNERDATA: 'deletenewscornerdata',

  // VAN MANAGEMENT
  GETVANASSIGNSTUDENTS: 'getvanassignstudents',
  GETVANDRIVERS: 'getvandrivers',
  ASSIGNSTUDENTSTOVAN: 'assignstudentstovan',
  UPDATEVANASSIGNSTUDENTS: 'updatevanassignstudents',
  DRIVERASSIGNEDSTUDENTS: 'driverassignedstudents',
  INSERTVANATTENDANCE: 'insertvanattendance',
  UPDATEVANATTENDANCE: 'updatevanattendance',

  // REPORTS
  GETDUEFEESDATA: 'getduefeesdata',
  DOWNLOADFEESDUEREPORTDATA: 'downloadfeesduereportdata',

  // SALARY MODULE
  GETUSERSALARYDETAILS: 'getusersalarydetails',
  GENERATEUSERSALARY: 'generateusersalary',
  GETUSERSALARY: 'getusersalary',
  UPDATEUSERSALARY: 'updateusersalary',
  DELETEUSERSALARY: 'deleteusersalary',
  DOWNLOADUSERSALARYRECEIPT: 'downloadusersalaryreceipt',
  GETSALARYREPORTDATA: 'getsalaryreportdata',

  // GET USER WISE BATCH
  GETUSERWISEBATCH: 'getuserwisebatch',

  // GET BATCH WISE STUDENT
  GETBATCHWISESTUDENT: 'getbatchwisestudent',

  // STUDENT ATTENDANCE
  INSERTSTUDENTATTENDANCE: 'insertstudentattendance',
  UPDATESTUDENTATTENDANCE: 'updatestudentattendance',
  GETATTENDANCEISTAKEN: 'getattendanceistaken',

  // BRANCH ADMIN DASHBOARD
  GETUSERWISEALLBRANCH: 'getuserwiseallbranch',

  // STUDENT DASHBOARD
  GETSTUDENTDASHBOARD: 'studentdashboard',

  // FINANCE DASHBOARD
  INCOMEEXPENSECHART: 'incomeexpensechart',
  CATEGORYWISEINCOMEEXPENSECHART: 'categorywiseincomeexpensechart',

  // REPORTS
  getfeescollectionreportsdata: 'getfeescollectionreportsdata',
  DOWNLOADFEESCOLLECTIONREPORTSDATA: 'downloadfeescollectionreportsdata',
  GETGENERATEDFEESCOLLECTIONREPORTDATA: 'getgeneratedfeescollectionreportdata',
  UPDATEFEESREPORTSTATUSDATA: 'updatefeesreportstatusdata',
  GETBRANCHWISEBATCH: 'getbranchwisebatch',
  GETSTUDENTATTENDANCEREPORT: 'getstudentattendancereport',
  DOWNLAODSTUDENTATTENDANCE: 'downlaodstudentattendance',
  DAILYINCOMEREPORT: 'dailyincome',
  DAILYEXPENSEREPORT: 'dailyexpense',
  GETEXPERIENCELETTER: 'getexperienceletter',
  INSERTEXPERIENCELETTER: 'insertexperienceletter',
  UPDATEEXPERIENCELETTER: 'updateexperienceletter',
  DELETEEXPERIENCELETTER: 'deleteexperienceletter',
  DOWNLOADEXPERIENCELETTER: 'downloadexperienceletter',
  GETAPPOINTMENTLETTER: 'getappointmentletter',
  INSERTAPPOINTMENTLETTER: 'insertappointmentletter',
  UPDATEAPPOINTMENTLETTER: 'updateappointmentletter',
  DELETEAPPOINTMENTLETTER: 'deleteappointmentletter',
  DOWNLOADAPPOINTMENTLETTER: 'downloadappointmentletter',

  // DASHBOARD
  GETDAILYSTUDENTATTENDANCEFORDASHBOARD: 'dailystduentattendancedashboard',
  GETDAILYSTAFFATTENDANCEFORDASHBOARD: 'getdailystaffattendancefordashboard',
};
