import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IsNotValue } from 'src/@core/constants/variables';

export const handleToast = (type, message) => {
  /**
   * type
   * 1 - success
   * 0 - error
   */
  const toastConfig = {
    autoClose: 1000,
    position: toast.POSITION.TOP_RIGHT,
    toastId: 'custom-id-yes',
  };

  if (type) {
    toast.success(message, toastConfig);
  } else {
    toast.error(
      IsNotValue.includes(message) ? 'Something Went Wrong Please Try Again Later' : message,
      { ...toastConfig, toastId: 'custom-id-yes' }
    );
  }
};
