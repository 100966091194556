import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { revertAll } from './index';

const initialState = {
  teacherList: [],
  userData: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    getTeacherData: (state, action) => {
      state.teacherList = action.payload;
    },
    getUsers: (state, action) => {
      // without pagination
      state.userData = action.payload;
    },
  },
});

export const { getTeacherData, getUsers } = userSlice.actions;

export default userSlice.reducer;