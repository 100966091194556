import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { revertAll } from './index';

const initialState = {
  paymentMethodList: [],
}

export const paymentmethodSlice = createSlice({
  name: 'paymentmethod',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    getPaymentMethodList: (state, action) => {
      state.paymentMethodList = action.payload;
    },
    insertPaymentMethod: (state, action) => {
      state.paymentMethodList = [...state.paymentMethodList, action.payload];
    },
    updatePaymentMethod: (state, action) => {
      const newData = state.paymentMethodList.map((item) => {
        if (item.p_m_id === action.payload.p_m_id) {
          item = action.payload;
        }
        return item;
      });
      state.paymentMethodList = newData;
    },
    deletePaymentMethod: (state, action) => {
      const filterData = state.paymentMethodList.filter((item) => item.p_m_id !== action.payload);
      state.paymentMethodList = filterData;
    },
  },
});

export const {
  getPaymentMethodList,
  insertPaymentMethod,
  updatePaymentMethod,
  deletePaymentMethod,
} = paymentmethodSlice.actions;

export default paymentmethodSlice.reducer;
