import { createSlice } from '@reduxjs/toolkit';
import { IsNotValue } from 'src/@core/constants/variables';
// eslint-disable-next-line import/no-cycle
import { revertAll } from './index';

const initialState = {
  kanban_lead: [],
  finalSearch: false,
  all_teacher_lead: [],
  teacher_position_list: [],
};

export const teacherhiringSlice = createSlice({
  name: 'teacherhiring',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    getPositionList: (state, action) => {
      state.teacher_position_list = action.payload;
    },
    deleteTeacherHiring: (state, action) => {
      const teacherHiringData = state.all_teacher_lead;
      if (teacherHiringData) {
        delete teacherHiringData[`t_${action.payload}`];
        state.all_teacher_lead = teacherHiringData;
      }
    },
    updateTeacherHiringDetails: (state, action) => {
      const newdata = state.all_teacher_lead[`t_${action.payload.teacher_hiring_id}`];
      if (!IsNotValue.includes(newdata)) {
        state.all_teacher_lead = {
          ...state.all_teacher_lead,
          [`t_${action.payload.teacher_hiring_id}`]: action.payload,
        };
      }
    },
    getKanbanTeacherHiringLead: (state, action) => {
      state.kanban_lead = { ...state.kanban_lead, ...action.payload };
    },
    getAllTeacherHiring: (state, action) => {
      state.all_teacher_lead = { ...state.all_teacher_lead, ...action.payload };
    },
    updateKanbanSearchStatus: (state, action) => {
      state.finalSearch = action.payload;
    },
    clearKanbanTeacherHiringLead: (state, action) => {
      state.kanban_lead = action.payload;
      state.all_teacher_lead = action.payload;
    },
  },
});

export const {
  getPositionList,
  deleteTeacherHiring,
  getAllTeacherHiring,
  updateKanbanSearchStatus,
  updateTeacherHiringDetails,
  getKanbanTeacherHiringLead,
  clearKanbanTeacherHiringLead,
  getSearchKanbanTeacherHiringLead,
} = teacherhiringSlice.actions;

export default teacherhiringSlice.reducer;
