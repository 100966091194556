/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-nested-ternary */
/* eslint-disable func-names */
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { RHFSelect } from 'src/components/hook-form/rhf-select';
import { Card, Grid, ListItemButton, MenuItem, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { LocalizationProvider, MobileDatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { handleToast } from 'src/utils/toast';
import { useEffect, useState } from 'react';
import {
  getPublicData,
  getPublicFormBatchData,
  insertPublicFormData,
} from 'src/@core/api/student_inquiry';
import { useParams } from 'react-router';
import moment from 'moment';
import Iconify from 'src/components/iconify';
import { standardTypes, mediumOptions, earliestDate } from 'src/@core/constants/variables';

import { findStudentDataOnParentNumber } from 'src/@core/api/student';
import logoImage from '../../assets/logo/logo.png';

export default function PublicForm() {
  const [standardDataList, setStandardDataList] = useState([]);
  const [branchDataList, setBranchDataList] = useState([]);
  const [batchDataList, setBatchDataList] = useState([]);
  const [schoolDataList, setSchoolDataList] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState();
  const [findedStudentData, setFindedStudentData] = useState([]);
  const [isFatherNoChange, setIsFatherNoChange] = useState(false);
  const [isMotherNoChange, setIsMotherNoChange] = useState(false);
  const [selectedStudentParentId, setSelectedStudentParentId] = useState(null);
  const [isSelectFindedStudent, setIsSelectFindedStudent] = useState(false);

  const { branch_id } = useParams();

  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() - 1);

  const PublicDataSchema = Yup.object().shape(
    {
      full_name: Yup.string().trim().required('Full Name is Required'),
      dob: Yup.date()
        .typeError('Date of Birth is Required')
        .required('Date of Birth is Required')
        .min(earliestDate, `Date of Birth cannot be before ${earliestDate.toDateString()}`)
        .max(maxDate, 'Date of Birth can not be Future Date or Current Date'),
      address: Yup.string().trim().required('Address is Required'),
      standard_id: Yup.string().trim().required('Standard is Required'),
      standard_type: Yup.string().trim().required('Standard Type is Required'),
      father_mob: Yup.string().when('mother_mob', (mother_mob, schema) =>
        !mother_mob || mother_mob[0]?.trim() === ''
          ? schema
              .required('At least one of Father Mobile No. or Mother Mobile No. is required')
              .test('maxFatherDigits', 'Phone number must have exactly 10 digits', (number) =>
                number ? number.length === 10 : true
              )
          : schema.test('maxFatherDigits', 'Phone number must have exactly 10 digits', (number) =>
              number ? number.length === 10 : true
            )
      ),
      mother_mob: Yup.string().when('father_mob', (father_mob, schema) =>
        !father_mob || father_mob[0]?.trim() === ''
          ? schema
              .required('At least one of Father Mobile No. or Mother Mobile No. is required')
              .test('maxMotherDigits', 'Phone number must have exactly 10 digits', (number) =>
                number ? number.length === 10 : true
              )
          : schema.test('maxMotherDigits', 'Phone number must have exactly 10 digits', (number) =>
              number ? number.length === 10 : true
            )
      ),
      medium: Yup.string().when('standard_type', (standard_type, schema) =>
        parseInt(standard_type[0], 10) === 1
          ? schema.required('Medium is required')
          : schema.notRequired()
      ),
      school_id: Yup.string().when('standard_type', (standard_type, schema) =>
        parseInt(standard_type[0], 10) === 2
          ? schema.required('School is required')
          : schema.notRequired()
      ),
      school_start_time: Yup.string().when('standard_type', (standard_type, schema) =>
        parseInt(standard_type[0], 10) === 2
          ? schema.required('School Start Time is required')
          : schema.notRequired()
      ),
      school_end_time: Yup.string().when('standard_type', (standard_type, schema) =>
        parseInt(standard_type[0], 10) === 2
          ? schema
              .required('School End Time is required')
              .test(
                'is-after-start',
                'School End Time must be greater than School Start time',
                function (value) {
                  const { school_start_time } = this.parent;
                  return moment(value).isAfter(school_start_time);
                }
              )
          : schema.notRequired()
      ),
      last_year_percentage: Yup.number().when('school_id', (school_id, schema) =>
        school_id[0] !== '' && school_id[0] !== 0 && school_id[0] !== null
          ? schema
              .transform((originalValue, originalObject) =>
                originalValue === 0 ? undefined : originalValue
              )
              .min(0, 'Percentage cannot be negative')
              .max(100, 'Percentage cannot be greater than 100')
              .typeError('Invalid Percentage')
              .required('Last Year Percentage is required')
          : schema
              .min(0, 'Percentage cannot be negative')
              .max(100, 'Percentage cannot be greater than 100')
              .typeError('Invalid Percentage')
      ),
    },
    ['father_mob', 'mother_mob']
  );

  const defaultValues = {
    full_name: '',
    dob: null,
    reference: '',
    father_mob: '',
    mother_mob: '',
    school_id: '',
    school_name: '',
    school_start_time: null,
    school_end_time: null,
    standard_id: '',
    standard_type: '',
    batch_id: '',
    board_id: '',
    last_year_percentage: 0,
    address: '',
    medium: '',
  };

  const methods = useForm({
    resolver: yupResolver(PublicDataSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    trigger,
    setValue,
    control,
    formState: { isSubmitting },
  } = methods;

  const handleInput = (event) => {
    let { value } = event.target;
    value = value.replace(/e/gi, '');
    event.target.value = value;
  };

  const validateTime = () => {
    if (
      watch('school_start_time') !== null &&
      watch('school_end_time') !== null &&
      watch('school_start_time') >= watch('school_end_time')
    )
      setError('school_end_time', {
        type: 'manual',
        message: 'End Time must be greater than Start Time.',
      });
    else clearErrors('school_end_time');
  };

  const otherSchool = schoolDataList.find((option) => option.type === 1);

  const getPublicDataFunction = async () => {
    await getPublicData()
      .then((response) => {
        setStandardDataList(response?.data.standard);
        setBranchDataList(response?.data.branch);
        setSchoolDataList(response?.data.school);
      })
      .catch((error) => handleToast(0, error?.response?.data?.description));
  };

  const getPublicBatchData = async (getSelectedStd, getselectedSchool) => {
    let schoolboard_id = '';
    if (watch('school_id') && watch('standard_type') === 2) {
      schoolboard_id = schoolDataList.find((school) => school.school_id === getselectedSchool);
    }
    const subbranch_id = branchDataList.find(
      (branch) =>
        branch.parent_branch_id === parseInt(branch_id, 10) &&
        branch.branch_type === watch('standard_type')
    );

    await getPublicFormBatchData({
      standard_id: getSelectedStd,
      branch_id: subbranch_id?.branch_id !== undefined ? subbranch_id?.branch_id : null,
      board_id: schoolboard_id.board_id ? schoolboard_id.board_id : '',
      medium: watch('standard_type') === 1 ? getselectedSchool : '',
    })
      .then((response) => {
        setBatchDataList(response?.data);
      })
      .catch((error) => handleToast(0, error?.response?.data?.description));
  };

  useEffect(() => {
    getPublicDataFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findStudentOnParentData = async (data) => {
    await findStudentDataOnParentNumber({
      father_mob: data.father_mob,
      mother_mob: data.mother_mob,
      student_id: 0,
    })
      .then((response) => {
        if (response?.status === 1) {
          setFindedStudentData(response?.data);
        }
        setIsFatherNoChange(false);
        setIsFatherNoChange(false);
      })
      .catch((error) => {
        handleToast(0, error?.response?.data?.description);
      });
  };

  const onSubmit = async (data) => {
    if (
      data.school_start_time !== null &&
      data.school_end_time !== null &&
      data.school_start_time >= data.school_end_time
    ) {
      setError('school_end_time', {
        type: 'manual',
        message: 'End Time must be greater than Start Time.',
      });
    } else {
      clearErrors('school_end_time');
      const schoolboard_id = schoolDataList.find((school) => school.school_id === selectedSchool);

      const formData = {
        ...data,
        dob: moment(data.dob).format('DD-MM-YYYY'),
        school_start_time:
          data.school_start_time !== null && data.school_start_time !== ''
            ? moment(data.school_start_time).format('HH:mm')
            : '',
        school_end_time:
          data.school_end_time !== null && data.school_end_time !== ''
            ? moment(data.school_end_time).format('HH:mm')
            : '',
        school_name: data.school_name ? data.school_name : '',
        branch_id,
        board_id:
          data.school_id !== '' && schoolboard_id.board_id !== undefined
            ? schoolboard_id.board_id
            : '',
        medium: parseFloat(watch('standard_type')) === 1 ? data.medium : '',
      };

      if (findedStudentData.length > 0 && isSelectFindedStudent === false) {
        handleToast(0, 'Please Select Any Student Details For Same Parents');
      } else {
        formData.parents_id = selectedStudentParentId;
        await insertPublicFormData(formData)
          .then((response) => {
            if (response?.status === 1) {
              setFindedStudentData([]);
              setSelectedStudentParentId(null);
              setIsSelectFindedStudent(false);
              reset();
            }
            handleToast(response.status, response.description);
          })
          .catch((error) => handleToast(0, error?.response?.data?.description));
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid xs={12} md={12} sx={{ m: 0 }}>
          <Card sx={{ p: 3 }}>
            <img src={logoImage} alt="Logo" style={{ height: '65px', marginBottom: '30px' }} />
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="full_name" size="small" label="Full Name" />

              <Controller
                name="dob"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label="Date of Birth"
                      orientation="portrait"
                      value={value}
                      format="dd/MM/yyyy"
                      onChange={(date) => {
                        onChange(date);
                        trigger('dob');
                      }}
                      maxDate={new Date()}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                          size: 'small',
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
              />

              <RHFTextField
                name="father_mob"
                size="small"
                label="Father Mobile No."
                inputProps={{
                  type: 'number',
                  pattern: '[0-9]*',
                  onInput: handleInput,
                }}
                onChange={(e) => {
                  setValue('father_mob', e.target.value, {
                    shouldValidate: true,
                  });
                  trigger('mother_mob');
                  setIsFatherNoChange(true);
                }}
                onBlur={(e) => {
                  if (e.target.value.trim() && isFatherNoChange === true) {
                    findStudentOnParentData({
                      father_mob: e.target.value,
                      mother_mob: watch('mother_mob'),
                    });
                  }
                }}
              />

              <RHFTextField
                name="mother_mob"
                size="small"
                label="Mother Mobile No."
                inputProps={{
                  type: 'number',
                  pattern: '[0-9]*',
                  onInput: handleInput,
                }}
                onChange={(e) => {
                  setValue('mother_mob', e.target.value, {
                    shouldValidate: true,
                  });
                  trigger('father_mob');
                  setIsMotherNoChange(true);
                }}
                onBlur={(e) => {
                  if (e.target.value.trim() && isMotherNoChange === true) {
                    findStudentOnParentData({
                      father_mob: watch('father_mob'),
                      mother_mob: e.target.value,
                    });
                  }
                }}
              />

              <RHFTextField name="reference" size="small" label="Reference" />

              <Controller
                name="standard_type"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          sx: {
                            maxHeight: typeof maxHeight === 'number' ? 220 : 'unset',
                          },
                        },
                      },
                      sx: { textTransform: 'capitalize' },
                    }}
                    error={!!error}
                    helperText={error ? error?.message : ''}
                    label="Standard Type"
                    size="small"
                    style={{ textAlign: 'left' }}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      setValue('standard_id', '');
                      setValue('school_id', '');
                      setValue('medium', '');
                    }}
                  >
                    {standardTypes.map((option) => (
                      <MenuItem key={option.name} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />

              {watch('standard_type') !== '' && (
                <Controller
                  name="standard_id"
                  control={control}
                  render={({ field }) => (
                    <RHFSelect
                      {...field}
                      style={{ textAlign: 'left' }}
                      label="Select Standard"
                      size="small"
                      onChange={(e) => {
                        field.onChange(e);
                        if (watch('school_id')) {
                          getPublicBatchData(e.target.value, watch('school_id'));
                        }
                      }}
                    >
                      {standardDataList?.map(
                        (option) =>
                          option.status === 0 &&
                          watch('standard_type') === option.standard_type && (
                            <MenuItem key={option.standard_id} value={option.standard_id}>
                              {option.standard_name}
                            </MenuItem>
                          )
                      )}
                    </RHFSelect>
                  )}
                />
              )}

              {watch('standard_type') === 1 ? (
                <Controller
                  name="medium"
                  render={({ field }) => (
                    <RHFSelect
                      {...field}
                      label="Select Medium"
                      size="small"
                      style={{ textAlign: 'left' }}
                      onChange={(e) => {
                        field.onChange(e);
                        if (watch('standard_id')) {
                          getPublicBatchData(watch('standard_id'), e.target.value);
                        }
                      }}
                    >
                      {mediumOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  )}
                />
              ) : (
                ''
              )}

              {watch('school_id') && watch('school_id') === otherSchool.school_id && (
                <RHFTextField name="school_name" label="School Name" size="small" />
              )}

              {watch('standard_type') === 2 && (
                <>
                  <Controller
                    name="school_id"
                    render={({ field }) => (
                      <RHFSelect
                        {...field}
                        label="Select School"
                        size="small"
                        style={{ textAlign: 'left' }}
                        onChange={(e) => {
                          field.onChange(e);
                          setSelectedSchool(e.target.value);
                          if (watch('standard_id')) {
                            getPublicBatchData(watch('standard_id'), e.target.value);
                          }
                        }}
                      >
                        {schoolDataList
                          ?.sort((a, b) => (a.type === 1 ? 1 : b.type === 1 ? -1 : 0))
                          .map((option) => (
                            <MenuItem key={option.school_id} value={option.school_id}>
                              {option.school_name}
                            </MenuItem>
                          ))}
                      </RHFSelect>
                    )}
                  />

                  <Controller
                    name="school_start_time"
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          ampm={false}
                          label="School Start Time"
                          size="small"
                          value={value}
                          onChange={(time) => {
                            onChange(time);
                            validateTime();
                          }}
                          slotProps={{
                            textField: {
                              error: !!error,
                              helperText: error?.message,
                              fullWidth: true,
                              size: 'small',
                            },
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />

                  <Controller
                    name="school_end_time"
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          ampm={false}
                          label="School End Time"
                          size="small"
                          value={value}
                          onChange={(time) => {
                            onChange(time);
                            validateTime();
                          }}
                          slotProps={{
                            textField: {
                              error: !!error,
                              helperText: error?.message,
                              fullWidth: true,
                              size: 'small',
                            },
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />

                  <RHFTextField
                    name="last_year_percentage"
                    label="Last Year Percentage"
                    size="small"
                    InputProps={{
                      type: 'number',
                      pattern: '^\\d*\\.?\\d*$',
                      endAdornment: (
                        <Iconify icon="fluent-mdl2:calculator-percentage" color="gray" size="50" />
                      ),
                    }}
                  />
                </>
              )}

              {watch('school_id') !== 1 && (
                <RHFSelect
                  name="batch_id"
                  label="Select Preferred Batch"
                  size="small"
                  style={{ textAlign: 'left' }}
                >
                  {batchDataList.map((option) => (
                    <MenuItem key={option.batch_id} value={option.batch_id}>
                      {`${option.s_time} to ${option.e_time}`}
                    </MenuItem>
                  ))}
                </RHFSelect>
              )}
              <RHFTextField
                name="address"
                label="Address"
                fullWidth
                multiline
                size="small"
                rows={2}
                sx={{
                  gridColumn: {
                    xs: 'span 0',
                    sm: 'span 2',
                  },
                }}
              />
            </Box>
            {findedStudentData?.length > 0 && (
              <>
                <Typography sx={{ px: 1 }} variant="h5">
                  Select Any Student For Same Parents
                </Typography>

                {findedStudentData?.map((studentdata) => (
                  <Stack
                    component={ListItemButton}
                    onClick={() => {
                      setSelectedStudentParentId(studentdata?.parents_id);
                      setIsSelectFindedStudent(true);
                    }}
                    sx={{
                      mx: 1,
                      mt: 1,
                      border: '1px solid #eee',
                      borderRadius: 1,
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      backgroundColor:
                        studentdata.parents_id === selectedStudentParentId &&
                        isSelectFindedStudent === true
                          ? '#e8d3dc'
                          : 'inherit',
                      '&:hover': {
                        backgroundColor:
                          studentdata.parents_id === selectedStudentParentId &&
                          isSelectFindedStudent === true
                            ? '#e8d3dc'
                            : 'inherit',
                      },
                    }}
                  >
                    <Stack direction="row" flexWrap="wrap" alignItems="center" spacing={1}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
                        Student Name:
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{ color: 'primary.main', marginRight: '30px' }}
                      >
                        {studentdata.full_name}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '0.8rem',
                        }}
                      >
                        Enrollment No:
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{ color: 'text.secondary', marginRight: '30px' }}
                      >
                        {`${studentdata?.enrollment_prefix}${studentdata?.enrollment_no}`}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '0.8rem',
                        }}
                      >
                        Father Mobile:
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{ color: 'text.secondary', marginRight: '10px' }}
                      >
                        {studentdata?.father_mob}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '0.8rem',
                        }}
                      >
                        Mother Mobile:
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{ color: 'text.secondary', marginRight: '10px' }}
                      >
                        {studentdata?.mother_mob}
                      </Typography>
                    </Stack>
                  </Stack>
                ))}
              </>
            )}
            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                color="primary"
                type="submit"
                variant="contained"
                fullWidth
                loading={isSubmitting}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
