import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { revertAll } from './index';

const initialState = {
  studentsList: {},
  parentsList: [],
};

export const studentInquirySlice = createSlice({
  name: 'studentinquiry',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    getStudentsList: (state, action) => {
      state.studentsList = { ...state.studentsList, ...action.payload };
    },
    insertStudent: (state, action) => {
      state.studentsList[action.payload.status].data = [
        action.payload,
        ...state.studentsList[action.payload.status].data,
      ];
      state.studentsList[action.payload.status].count += 1;
    },
    updateStudentsData: (state, action) => {
      const { student_id, status, ...updatedData } = action.payload;
      if (state.studentsList[status]) {
        const newData = state.studentsList[status].data.map((item) => {
          if (item.student_id === student_id) {
            return { student_id, status, ...updatedData };
          }
          return item;
        });
        state.studentsList[status].data = newData;
      }
    },
    deleteStudentsData: (state, action) => {
      const { ...deleteData } = state.studentsList;
      const updatedDatas = Object.keys(deleteData).reduce((data, key) => {
        const filteredData = deleteData[key].data.filter(
          (item) => item.student_id !== action.payload
        );
        data[key] = {
          data: filteredData,
          count: filteredData.length,
        };
        return data;
      }, {});

      state.studentsList = updatedDatas;
    },
    addCancelReason: (state, action) => {
      state.studentsList = [action.payload, ...state.studentsList];
    },
    insertParentsData: (state, action) => {
      state.parentsList = [action.payload, ...state.parentsList];
    },
  },
});

export const {
  getStudentsList,
  insertStudent,
  updateStudentsData,
  deleteStudentsData,
  addCancelReason,
  insertParentsData,
} = studentInquirySlice.actions;

export default studentInquirySlice.reducer;
