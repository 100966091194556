import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { SplashScreen } from 'src/components/loading-screen';

import CompactLayout from 'src/layouts/compact/layout';
import PublicForm from 'src/pages/Public Form/PublicForm';
import TeacherHiringPublicForm from 'src/pages/TeacherHiring/publicForm';

export const publicFormRoutes = [
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: ':branch_id/studentinquiryform', element: <PublicForm /> },
      { path: 'hiringform', element: <TeacherHiringPublicForm /> },
    ],
  },
];
