import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { revertAll } from './index';

const initialState = {
  branchList: [],
};

export const branchSlice = createSlice({
  name: 'branch',
  initialState: {
    branchList: [],
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    getBranchList: (state, action) => {
      state.branchList = action.payload;
    },
    insertBranches: (state, action) => {
      state.branchList = [...state.branchList, action.payload];
    },
    updateBranches: (state, action) => {
      const updatedData = state.branchList.map((item) => {
        if (item.branch_id === action.payload.branch_id) {
          item = action.payload;
        }
        return item;
      });
      state.branchList = updatedData;
    },
    deleteBranches: (state, action) => {
      const deleteData = state.branchList.filter((item) => item.branch_id !== action.payload);
      state.branchList = deleteData;
    },
  },
});

export const {
  getBranchList,
  insertBranches,
  updateBranches,
  deleteBranches,
} = branchSlice.actions;

export default branchSlice.reducer;
