import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { revertAll } from './index';

const initialState = {
  teachertrainingList: [],
};

export const teacherTrainingSlice = createSlice({
  name: 'teachertraining',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    getTeacherTrainingList: (state, action) => {
      state.teachertrainingList = action.payload;
    },
    insertTeacherTrainingList: (state, action) => {
      state.teachertrainingList = [...state.teachertrainingList, action.payload].sort(
        (a, b) => b.t_t_id - a.t_t_id
      );
    },
    updateTeacherTrainingList: (state, action) => {
      const newData = state.teachertrainingList.map((item) => {
        if (item.t_t_id === action.payload.t_t_id) {
          item = action.payload;
        }
        return item;
      });
      state.teachertrainingList = newData;
    },
    deleteTeacherTrainingList: (state, action) => {
      const filterData = state.teachertrainingList.filter((item) => item.t_t_id !== action.payload);
      state.teachertrainingList = filterData;
    },
  },
});

export const {
  reset,
  getTeacherTrainingList,
  insertTeacherTrainingList,
  updateTeacherTrainingList,
  deleteTeacherTrainingList,
} = teacherTrainingSlice.actions;

export default teacherTrainingSlice.reducer;
