import Request from '.';
import { ApiRoutes } from '../constants';

export const getTeacherAttendance = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.GETTEACHERATTENDANCE, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const downloadTeacherAttendance = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.DOWNLOADTEACHERATTENDANCE, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getTeacherDataByBranch = async (branch_id) => {
  try {
    const res = await Request.get(`${ApiRoutes.GETBRANCHWISETEACHER}/${branch_id}`);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getPublicTeacherDataByBranch = async (branch_id) => {
  try {
    const res = await Request.get(`${ApiRoutes.GETPUBLICTEACHERLISTBYBRANCH}/${branch_id}`);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getDailyTeacherAttendance = async (branch_id, date) => {
  try {
    const res = await Request.get(`${ApiRoutes.GETDAILYTEACHERATTENDANCE}/${branch_id}/${date}`);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getPublicDailyTeacherAttendance = async (branch_id, date) => {
  try {
    const res = await Request.get(
      `${ApiRoutes.GETPUBLICDAILYTEACHERATTEDANCE}/${branch_id}/${date}`
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const insertTeacherAttendance = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.INSERTTEACHERATTENDANCE, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const insertPublicTeacherAttendance = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.INSERTPUBLICTEACHERATTENDANCE, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateTeacherAttendance = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.UPDATETEACHERATTENDANCE, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updatePublicTeacherAttendance = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.UPDATEPUBLICTEACHERATTENDANCE, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const insertAttendancePassword = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.INSERTATTENDANCEPASSWORD, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAttendancePassword = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.GETATTENDANCEPASSWORD, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getMyAttendance = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.GETMYATTENDANCE, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateCheckInCheckOut = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.UPDATECHECKINCHECKOUT, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const insertAttendanceNote = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.INSERTNOTE, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
