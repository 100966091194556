import Axios from 'axios';
import { clearIndexedDB } from 'src/localDB/crudDB';
import { useDispatch } from 'react-redux';
import { revertAll } from 'src/redux/reducer';
import { ApiRoutes } from '../constants';

export const axios = Axios.create({
  rejectUnauthorized: false, // (NOTE: this will disable client verification)
  baseURL: ApiRoutes.API_HOSTNAME,
  timeout: 1000000000,
  responseType: 'json',
});

const endPoint = [
  'updatewebsetting',
  'updateuserprofile',
  'updateteacherhiring',
  'insertteacherhiring',
  'branchwiseupdatewebsetting',
  'insertstudymaterial',
  'insertuser',
  'updateuser',
  'inserthiringform',
  'insertincomeexpense',
  'updateincomeexpense',
  'insertdepartment',
  'updatedepartment',
  'insertnewsdata',
  'updatenewscornerdata',
  'insertgallerymedia',
  'insertleave',
  'updateleave',
  'deleteleave',
  'insertdailywork',
  'updatedailywork',
];

axios.interceptors.request.use(
  async (config) => {
    config.headers = {
      Accept: 'application/json , */*',
      'Content-Type': endPoint.includes(config.url)
        ? 'multipart/form-data'
        : 'application/json',
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem('UserData'))?.verify_token
      }`,
    };
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response?.data,
  (error) => {
    if (error.response.status === 403) {
      window.location.replace('/');
      localStorage.clear();
      clearIndexedDB();
      useDispatch(revertAll());
    }
    return Promise.reject(error);
  }
);

export default axios;
