/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Table,
  TableContainer,
  Typography,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { dailyExpenseReportData, dailyIncomeReportData } from 'src/@core/api/reports';
import { commaFunction } from 'src/@core/constants/variables';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { handleToast } from 'src/utils/toast';

export default function DailyIncomeExpenseReport() {
  const calenderDialog = useBoolean();
  const filtersData = { start_date_time: new Date(), end_date_time: new Date() };
  const [incomeData, setIncomeData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);
  const [totalIncomeAmount, setTotalIncomeAmount] = useState('');
  const [totalExpenseAmount, setTotalExpenseAmount] = useState('');
  const [totalDifference, setTotalDifference] = useState(0);
  const [isFilterdTimeShow, setIsFilterdTimeShow] = useState(false);
  const [filterData, setFilterData] = useState(filtersData);
  const [incomeGetLoader, setIncomeGetLoader] = useState(false);
  const [expenseGetLoader, setExpenseGetLoader] = useState(false);

  const handleGetDailyIncomeReportData = async (data) => {
    setIncomeGetLoader(true);
    await dailyIncomeReportData({
      start_date_time: moment(data.start_date_time).format('DD-MM-YYYY'),
      end_date_time: moment(data.end_date_time).format('DD-MM-YYYY'),
    })
      .then((response) => {
        setIncomeGetLoader(false);
        setIncomeData(response?.data?.income);
        setTotalIncomeAmount(response?.data?.dailyIncomeTotal);
      })
      .catch((error) => {
        handleToast(0, error?.response?.data?.description);
        setIncomeGetLoader(false);
      });
  };

  const handleGetDailyExpenseReportData = async (data) => {
    setExpenseGetLoader(true);
    await dailyExpenseReportData({
      start_date_time: moment(data.start_date_time).format('DD-MM-YYYY'),
      end_date_time: moment(data.end_date_time).format('DD-MM-YYYY'),
    })
      .then((response) => {
        setExpenseGetLoader(false);
        setExpenseData(response?.data?.expense);
        setTotalExpenseAmount(response?.data?.dailyExpenseTotal);
      })
      .catch((error) => {
        handleToast(0, error?.response?.data?.description);
        setExpenseGetLoader(false);
      });
  };

  useEffect(() => {
    handleGetDailyIncomeReportData(filtersData);
    handleGetDailyExpenseReportData(filtersData);
  }, []);
  useEffect(() => {
    setTotalDifference(totalIncomeAmount - totalExpenseAmount);
  }, [totalIncomeAmount, totalExpenseAmount]);

  const maxLength = Math.max(incomeData.length, expenseData.length);

  const rows = [];
  for (let i = 0; i < maxLength; i++) {
    const incomeRow = incomeData[i] || {};
    const expenseRow = expenseData[i] || {};

    rows.push(
      <TableRow key={i}>
        <TableCell style={{ borderRight: '2px solid #e0e6ed' }}>{incomeRow?.date}</TableCell>
        <TableCell style={{ borderRight: '2px solid #e0e6ed' }}>{incomeRow?.name}</TableCell>
        <TableCell style={{ borderRight: '2px solid #e0e6ed' }}>
          {incomeRow?.amount && commaFunction(parseFloat(incomeRow?.amount).toFixed(2))}
        </TableCell>
        <TableCell style={{ borderRight: '2px solid #e0e6ed' }}>{expenseRow?.date}</TableCell>
        <TableCell style={{ borderRight: '2px solid #e0e6ed' }}>{expenseRow?.name}</TableCell>
        <TableCell style={{ borderRight: '2px solid #e0e6ed' }}>
          {expenseRow?.amount &&
            commaFunction(parseFloat(expenseRow?.amount).toFixed(2))}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      <CustomBreadcrumbs
        heading="Daily Income Expense Report"
        sx={{
          p: 1,
          mb: { xs: 1, sm: 0 },
        }}
      />
      <Card>
        <Grid container sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
          {/* {isFilterdTimeShow === true && ( */}
          <Stack
            spacing={1}
            sx={{
              mr: 2,
              mt: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <Typography variant="button">
              {`From : ${moment(filterData?.start_date_time).format('DD-MM-YYYY')} To : ${moment(
                filterData?.end_date_time
              ).format('DD-MM-YYYY')} `}
            </Typography>
            {isFilterdTimeShow === true && (
              <IconButton
                onClick={() => {
                  setFilterData({ start_date_time: new Date(), end_date_time: new Date() });
                  if (incomeGetLoader === false && expenseGetLoader === false) {
                    handleGetDailyIncomeReportData(filtersData);
                    handleGetDailyExpenseReportData(filtersData);
                  }
                  setIsFilterdTimeShow(false);
                }}
              >
                <Iconify icon="tabler:x" color="red" />
              </IconButton>
            )}
          </Stack>
          {/* )} */}
          <Grid
            item
            sx={{ px: 2, pt: 2, display: 'flex', flexDirection: 'row', justifyContent: 'end' }}
          >
            <Button
              variant="outlined"
              sx={{ p: 0, marginRight: '10px' }}
              title="Calendar"
              onClick={() => calenderDialog.onTrue()}
            >
              <Iconify icon="lets-icons:date-today-duotone" sx={{ width: 40, height: 35 }} />
            </Button>
          </Grid>
        </Grid>
        <TableContainer style={{ marginTop: '10px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ borderRight: '2px solid #e0e6ed' }}>Date</TableCell>
                <TableCell style={{ borderRight: '2px solid #e0e6ed' }}>Category Name</TableCell>
                <TableCell style={{ borderRight: '2px solid #e0e6ed' }}>Balance</TableCell>
                <TableCell style={{ borderRight: '2px solid #e0e6ed' }}>Date</TableCell>
                <TableCell style={{ borderRight: '2px solid #e0e6ed' }}>Category Name</TableCell>
                <TableCell style={{ borderRight: '2px solid #e0e6ed' }}>Balance</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {totalDifference > 0 ? (
                  <>
                    <TableCell
                      style={{ borderRight: '2px solid #e0e6ed', borderTop: '2px solid #e0e6ed' }}
                    >
                      JAMA AAGAL LAVYA
                    </TableCell>
                    <TableCell
                      style={{ borderRight: '2px solid #e0e6ed', borderTop: '2px solid #e0e6ed' }}
                    />
                    <TableCell
                      style={{ borderRight: '2px solid #e0e6ed', borderTop: '2px solid #e0e6ed' }}
                    >
                      {totalDifference && commaFunction(parseFloat(totalDifference).toFixed(2))}
                    </TableCell>
                    <TableCell
                      style={{ borderRight: '2px solid #e0e6ed', borderTop: '2px solid #e0e6ed' }}
                    />
                    <TableCell
                      style={{ borderRight: '2px solid #e0e6ed', borderTop: '2px solid #e0e6ed' }}
                    />
                    <TableCell
                      style={{ borderRight: '2px solid #e0e6ed', borderTop: '2px solid #e0e6ed' }}
                    />
                  </>
                ) : (
                  <>
                    <TableCell
                      style={{ borderRight: '2px solid #e0e6ed', borderTop: '2px solid #e0e6ed' }}
                    />
                    <TableCell
                      style={{ borderRight: '2px solid #e0e6ed', borderTop: '2px solid #e0e6ed' }}
                    />
                    <TableCell
                      style={{ borderRight: '2px solid #e0e6ed', borderTop: '2px solid #e0e6ed' }}
                    />
                    <TableCell
                      style={{ borderRight: '2px solid #e0e6ed', borderTop: '2px solid #e0e6ed' }}
                    >
                      JAMA AAGAL LAVYA
                    </TableCell>
                    <TableCell
                      style={{ borderRight: '2px solid #e0e6ed', borderTop: '2px solid #e0e6ed' }}
                    />
                    <TableCell
                      style={{ borderRight: '2px solid #e0e6ed', borderTop: '2px solid #e0e6ed' }}
                    >
                      {totalDifference && commaFunction(parseFloat(totalDifference).toFixed(2))}
                    </TableCell>
                  </>
                )}
              </TableRow>
              {rows}
              <TableRow>
                <TableCell
                  style={{ borderRight: '2px solid #e0e6ed', borderTop: '2px solid #e0e6ed' }}
                >
                  TOTAL INCOME:
                </TableCell>
                <TableCell
                  style={{ borderRight: '2px solid #e0e6ed', borderTop: '2px solid #e0e6ed' }}
                >
                  {}
                </TableCell>
                <TableCell
                  style={{ borderRight: '2px solid #e0e6ed', borderTop: '2px solid #e0e6ed' }}
                >
                  {totalIncomeAmount === null
                    ? '0.00'
                    : commaFunction(parseFloat(totalIncomeAmount).toFixed(2))}
                </TableCell>
                <TableCell
                  style={{ borderRight: '2px solid #e0e6ed', borderTop: '2px solid #e0e6ed' }}
                >
                  TOTAL EXPENSE:
                </TableCell>
                <TableCell
                  style={{ borderRight: '2px solid #e0e6ed', borderTop: '2px solid #e0e6ed' }}
                >
                  {}
                </TableCell>
                <TableCell
                  style={{ borderRight: '2px solid #e0e6ed', borderTop: '2px solid #e0e6ed' }}
                >
                  {totalExpenseAmount === null
                    ? '0.00'
                    : commaFunction(parseFloat(totalExpenseAmount).toFixed(2))}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      {/* Filter Date Range */}
      <Dialog fullWidth open={calenderDialog.value} onClose={calenderDialog.onFalse}>
        <DialogTitle sx={{ pb: 2 }}>Select Date Range</DialogTitle>

        <DialogContent>
          <Stack
            justifyContent="center"
            spacing={3}
            direction={{ xs: 'column', sm: 'row', md: 'row' }}
          >
            <DateCalendar
              label="Start date"
              value={filterData?.start_date_time}
              onChange={(date) => {
                setFilterData({
                  ...filterData,
                  start_date_time: date,
                  end_date_time: null,
                });
              }}
            />

            <DateCalendar
              label="End date"
              minDate={filterData?.start_date_time}
              value={filterData?.end_date_time}
              onChange={(date) => {
                setFilterData({
                  ...filterData,
                  end_date_time: date,
                });
              }}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={calenderDialog.onFalse}>
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              // getLoader === false
              if (filterData?.end_date_time !== null && filterData?.start_date_time !== null) {
                calenderDialog.onFalse();
                // setSearchCall(true);
                // setSearchClearCall('search');
                setIsFilterdTimeShow(true);
                handleGetDailyExpenseReportData(filterData);
                handleGetDailyIncomeReportData(filterData);
              } else {
                handleToast(0, 'Please Select Proper Date');
              }
            }}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
