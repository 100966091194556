import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { revertAll } from './index';

const initialState = {
  holidayList: [],
};

export const holidaySlice = createSlice({
  name: 'holiday',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    getHolidayList: (state, action) => {
      state.holidayList = action.payload;
    },
    insertHoliday: (state, action) => {
      state.holidayList = [...state.holidayList, action.payload];
    },
    updateHoliday: (state, action) => {
      const newData = state.holidayList.map((item) => {
        if (item.h_id === action.payload.h_id) item = action.payload;
        return item;
      });
      state.holidayList = newData;
    },
    deleteHoliday: (state, action) => {
      const filterData = state.holidayList.filter((item) => item.h_id !== action.payload);
      state.holidayList = filterData;
    },
  },
});

export const { getHolidayList, insertHoliday, updateHoliday, deleteHoliday } = holidaySlice.actions;

export default holidaySlice.reducer;
