import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { revertAll } from './index';

const initialState = {
  roleList: [],
};

export const roleSlice = createSlice({
  name: 'role',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    getRolesList: (state, action) => {
      state.roleList = action.payload;
    },
    insertRoles: (state, action) => {
      state.roleList = [...state.roleList, action.payload]?.sort((a, b) =>
        a?.r_name.localeCompare(b?.r_name)
      );
    },
    updateRole: (state, action) => {
      const newData = state.roleList.map((item) => {
        if (item.r_id === action.payload.r_id) item = action.payload;
        return item;
      });
      state.roleList = newData;
    },
  },
});

export const { getRolesList, insertRoles, updateRole, deleteRole } = roleSlice.actions;

export default roleSlice.reducer;
