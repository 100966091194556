import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { revertAll } from './index';

const initialState = {
  FeesList: [],
};

export const FeesSlice = createSlice({
  name: 'Fees',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    getfees: (state, action) => {
      state.FeesList = action.payload;
    },
    insertfees: (state, action) => {
      state.FeesList = [...state.FeesList, action.payload];
    },
    updatefees: (state, action) => {
      const newData = state.FeesList.map((item) => {
        if (item.fees_id === action.payload.fees_id) {
          item = action.payload;
        }
        return item;
      });
      state.FeesList = newData;
    },
    deletefees: (state, action) => {
      const filterData = state.FeesList.filter((item) => item.fees_id !== action.payload);
      state.FeesList = filterData;
    },
  },
});

export const { getfees, insertfees, updatefees, deletefees } = FeesSlice.actions;

export default FeesSlice.reducer;
