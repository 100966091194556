import Request from '.';
import { ApiRoutes } from '../constants';

export const UserLogin = async (data) => {
  const res = await Request.post(ApiRoutes.LOGIN, data);
  return res;
};

export const forgotPassword = async (data) => {
  const result = await new Promise((resolve, reject) => {
    Request.post(ApiRoutes.FORGOTPASSWORD, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

export const resetPassword = async (data) => {
  const result = await new Promise((resolve, reject) => {
    Request.post(ApiRoutes.RESETPASSWORD, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

// BOARD INSERT
export const insertBoard = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.INSERTBOARD, data);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// GET BOARD LIST
export const getBoardList = async () => {
  try {
    const res = await Request.get(ApiRoutes.GETBOARDLIST);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// BOARD UPDATE
export const updateBoard = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.UPDATEBOARD, data);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// BOARD DELETE
export const deleteBoard = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.DELETEBOARD, data);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// export const addStandards = async (data) => {
//   const res = await Request.post(ApiRoutes.ADDSTANDARDS, data);
//   return res;

// }

// SCHOOL'S COMMON APIS
// ADD SCHOOL
export const addSchoolData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.ADDSCHOOL, data);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// DELETE SCHOOL DATA
export const deleteSchoolData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.DELETESCHOOL, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// UPDATE SCHOOL DATA
export const updateSchoolData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.UPDATESCHOOL, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// INSERT COLOR
export const insertColorData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.INSERTCOLOR, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// GET COLOR LIST
export const getColorList = async () => {
  try {
    const res = await Request.get(ApiRoutes.GETCOLORLIST);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// UPDATE COLOR
export const updateColorData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.UPDATECOLOR, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// DELETE COLOR
export const deleteColor = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.DELETECOLOR, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// INSERT SUBJECT
export const insertSubjectData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.INSERTSUBJECT, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// GET SUBJECT LIST
export const getSubjectList = async () => {
  try {
    const res = await Request.get(ApiRoutes.GETSUBJECTLIST);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// UPDATE v
export const updateSubjectData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.UPDATESUBJECT, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// DELETE SUBJECT
export const deleteSubject = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.DELETESUBJECT, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// INSERT BATCH
export const insertBatchData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.INSERTBATCH, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// GET BATCH
export const getBatchList = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.GETBATCHLIST, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// GET BATCH WISE SUBJECT AND FACULTY LIST
export const getSubjectandFacultyList = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.GETSUBJECTANDFACULTYLIST, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// DELETE BATCH
export const deleteBatch = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.DELETEBATCH, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// UPDATE BATCH
export const updateBatch = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.UPDATEBATCH, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// UPDATE BATCH STATUS
export const updateBatchStatus = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.UPDATEBATCHSTATUS, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// GET TEACHER LIST FOR BATCH MODULE
export const getTeacherList = async (branch_id, time_slot_id) => {
  try {
    const res = await Request.get(`${ApiRoutes.GETTAECHERLIST}/${branch_id}/${time_slot_id}`);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// GET BRANCH WISE DEPARTMENT
export const getBranchWiseDepartment = async (branch_id) => {
  try {
    const res = await Request.get(`${ApiRoutes.GETBRANCHWISEDEPARTMENT}/${branch_id}`);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// GET BRANCH LOCATION WISE DEPARTMENT
export const getBranchLocationWiseDepartment = async (address_id) => {
  try {
    const res = await Request.get(`${ApiRoutes.GETBRANCHLOCATIONWISEDEPARTMENT}/${address_id}`);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// GET BATCH DATA IN BOARD VIEW
export const getBatchDataInBoardView = async (branch_id, role_id, user_id, is_teacher) => {
  try {
    const res = await Request.get(
      `${ApiRoutes.GETBATCHDATAINBOARDVIEW}/${branch_id}/${role_id}/${user_id}/${is_teacher}`
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// GET BATCH LIST FOR STUDENT MODULE
export const getBatchData = async (data) => {
  const result = await new Promise((resolve, reject) => {
    Request.post(ApiRoutes.GETBATCHDATA, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

export const getBatchName = async (data) => {
  const result = await new Promise((resolve, reject) => {
    Request.post(ApiRoutes.GETBATCHNAME, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

// GET FEES LIST FOR STUDENT MODULE
export const getFeesData = async (data) => {
  const result = await new Promise((resolve, reject) => {
    Request.post(ApiRoutes.GETFEESDATA, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

// GET FACULTY'S ASSIGNED BATCHES
export const getFacultysbatchData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.GETFACULTYBATCH, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// GET ADMIN SUBBRANCH AND BRANCH DATA
export const getAdminSubBranchData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.GETADMINSUBBRANCH, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// IS TAECHER ALREADY ASSIGN IN THIS TIMESLOT
export const isTeacherAdlreadyAssgin = async (user_id, time_slot_id) => {
  try {
    const res = await Request.get(`${ApiRoutes.ISTAECHERALREADYASSIGN}/${user_id}/${time_slot_id}`);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// GET CONFIRM AND TRIAL STUDENT LIST FOR DISAPLY DATA IN BATCH
export const getConfirmandTrialStudent = async (batch_id) => {
  try {
    const res = await Request.get(`${ApiRoutes.GETCONFIRMANDTRIALSTUDENTLIST}/${batch_id}`);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// READ NOTIFICATION
export const readNotificationData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.READNOTIFICATION, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// GET NOTIFICATION DATA USER WISE
export const getNotificationData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.GETNOTIFICATIONDATA, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAllBatchData = async (data) => {
  const result = await new Promise((resolve, reject) => {
    Request.post(ApiRoutes.GETALLBATCHDATA, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

export const getAllStudentList = async (data) => {
  const result = await new Promise((resolve, reject) => {
    Request.get(ApiRoutes.GETALLSTUDENTSLIST, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
  return result;
};

// SUBJECT WISE SCHOOL & STANDARD LIST
export const getSubjectwiseschoolandstandardlist = async (subject_id) => {
  try {
    const res = await Request.get(`${ApiRoutes.GETSUBJECTWISESCHOOLANDSTANDARDLIST}/${subject_id}`);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// GET BRANCH ADDRESS WISE DEPARTMENT
export const getBranchAddressWiseDepartment = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.GETBRANCHADDRESSWISEDEPARTMENT, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
