import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { revertAll } from './index';

const initialState = {
  schoolList: [],
};

export const schoolSlice = createSlice({
  name: 'school',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    getSchoolList: (state, action) => {
      state.schoolList = action.payload;
    },
    insertSchool: (state, action) => {
      state.schoolList = [...state.schoolList, action.payload];
    },
    updateSchool: (state, action) => {
      const newData = state.schoolList.map((item) => {
        if (item.school_id === action.payload.school_id) {
          item = action.payload;
        }
        return item;
      });
      state.schoolList = newData;
    },
    deleteSchool: (state, action) => {
      const filterData = state.schoolList.filter((item) => item.school_id !== action.payload);
      state.schoolList = filterData;
    },
  },
});

export const { getSchoolList, insertSchool, updateSchool, deleteSchool } = schoolSlice.actions;

export default schoolSlice.reducer;
