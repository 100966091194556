import { DbName, DbVersion, StoreName } from 'src/@core/constants/db_name';
import { dbDeleteData, dbGetAllData, dbInsertAllData, dbUpdateData } from './crudDB';
import { dbPromise } from './connectionDB';

export async function dbBranchAllData(data) {
  await dbInsertAllData(DbName.BRANCHDB, DbVersion.FIRST, StoreName.BRANCH, data);
}

export async function dbGetAllBranchData() {
  return dbGetAllData(DbName.BRANCHDB, DbVersion.FIRST, StoreName.BRANCH, 'id');
}

export async function dbUpdateBranchData(data) {
  await dbUpdateData(DbName.BRANCHDB, DbVersion.FIRST, StoreName.BRANCH, 'id', 'branch_id', data);
}

export async function dbDeleteBranchData(id) {
  await dbDeleteData(DbName.BRANCHDB, DbVersion.FIRST, StoreName.BRANCH, 'id', 'branch_id', id);
}

export async function dbBranchDataInsert(data) {
  const db = await dbPromise(DbName.BRANCHDB, DbVersion.FIRST, StoreName.BRANCH, 'id');
  const tx = db.transaction(StoreName.BRANCH, 'readwrite');
  const store = tx.objectStore(StoreName.BRANCH);
  const dbData = await store.getAll();
  const statusdata = [...dbData[0].data, { ...data }];
  await store.put({ id: 1, data: statusdata });
  await tx.complete;
}
