import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { revertAll } from './index';

const initialState = {
  chapterList: [],
};

export const chapterSlice = createSlice({
  name: 'chapter',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    getChapters: (state, action) => {
      state.chapterList = action.payload;
    },
    insertChapters: (state, action) => {
      state.chapterList = [...state.chapterList, action.payload];
    },
    updatChapters: (state, action) => {
      const updatedData = state.chapterList.map((item) => {
        if (item.chapter_id === action.payload.chapter_id) {
          item = action.payload;
        }
        return item;
      });
      state.chapterList = updatedData;
    },
    deleteChapters: (state, action) => {
      const deleteData = state.chapterList.filter((item) => item.chapter_id !== action.payload);
      state.chapterList = deleteData;
    },
  },
});

export const { getChapters, insertChapters, updatChapters, deleteChapters } = chapterSlice.actions;

export default chapterSlice.reducer;
