import { Box, Card, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getUserWiseAllBranch } from 'src/@core/api/admindashboard';
import { PATH_AFTER_LOGIN } from 'src/config-global';
import { useRouter } from 'src/routes/hooks';
import { handleToast } from 'src/utils/toast';
import ComapnyLogo from 'src/assets/images/logo/logo.png';
import { isUserLoggedIn } from 'src/utils/utils';

export default function AdminDashboard() {
  const router = useRouter();
  const userData = isUserLoggedIn();
  const [userWiseBranch, setuserWiseBranch] = useState([]);
  const handleGetUserWiseAllBranch = async () => {
    await getUserWiseAllBranch({ branch_id: userData.branch_id, user_id: userData?.user_id })
      .then((response) => {
        setuserWiseBranch(response?.data);
      })
      .catch((error) => handleToast(0, error?.response?.data?.description));
  };
  useEffect(() => {
    handleGetUserWiseAllBranch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCardClick = (branchId, addressId) => {
    const filteredData = userWiseBranch?.filter((item) => item.branch_id === branchId);
    localStorage.setItem(
      'UserWiseBranchData',
      JSON.stringify({ ...filteredData[0], selectedAddress_id: addressId })
    );
    router.push(PATH_AFTER_LOGIN);
  };
  const numCards = userWiseBranch?.reduce((acc, curr) => acc + (curr.addressData?.length || 0), 0);
  let gridSize = 4;
  if (numCards === 2) {
    gridSize = 6;
  } else if (numCards === 3) {
    gridSize = 4;
  }

  return (
    <>
      <Box
        component="div"
        sx={{
          width: 220,
          height: 70,
          display: 'inline-flex',
        }}
      >
        <img src={ComapnyLogo} alt="Angel Classes" />
      </Box>
      <Grid container sx={{ marginTop: '100px', justifyContent: 'center', alignItems: 'center' }}>
        {userWiseBranch?.map((data) =>
          userData?.role_id === 6 ? (
            data?.addressData?.map((addressdata) => (
              <Grid xs={12} md={gridSize} sm={12}>
                <Card
                  sx={{
                    height: '200px',
                    marginRight: '15px',
                    marginLeft: '15px',
                    marginBottom: '15px',
                    textAlign: 'center',
                    backgroundColor: '#fbebf2',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleCardClick(data.branch_id, addressdata.address_id)}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '60px',
                    }}
                  >
                    {data.branch_name} - {addressdata.address}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    ({data?.branch_type === 1 ? 'Nursery' : 'Classes'})
                  </Typography>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid xs={12} md={userWiseBranch.length <= 2 ? 6 : 4} sm={12}>
              <Card
                sx={{
                  height: '200px',
                  marginRight: '15px',
                  marginLeft: '15px',
                  marginBottom: '10px',
                  textAlign: 'center',
                  backgroundColor: '#fbebf2',
                  cursor: 'pointer',
                }}
                onClick={() => handleCardClick(data.branch_id)}
              >
                <Typography
                  variant="h5"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '60px',
                  }}
                >
                  {data.branch_name}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  ({data?.branch_type === 1 ? 'Nursery' : 'Classes'})
                </Typography>
              </Card>
            </Grid>
          )
        )}
      </Grid>
    </>
  );
}
