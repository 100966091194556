import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { revertAll } from './index';

const initialState = {
  StandardList: [],
};

export const StandardSlice = createSlice({
  name: 'standard',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    getstandard: (state, action) => {
      state.StandardList = action.payload;
    },
    insertstandard: (state, action) => {
      state.StandardList = [...state.StandardList, action.payload];
    },
    updatestandard: (state, action) => {
      const newData = state.StandardList.map((item) => {
        if (item.standard_id === action.payload.standard_id) {
          item = action.payload;
        }
        return item;
      });
      state.StandardList = newData;
    },
    deletestandard: (state, action) => {
      const filterData = state.StandardList.filter((item) => item.standard_id !== action.payload);
      state.StandardList = filterData;
    },
  },
});

export const {
  getstandard,
  insertstandard,
  updatestandard,
  deletestandard,
} = StandardSlice.actions;

export default StandardSlice.reducer;
