import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { revertAll } from './index';

const initialState = { board_list: [] };
export const boardSlice = createSlice({
  name: 'board',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    getBoardData: (state, action) => {
      state.board_list = action.payload;
    },
    insertBoardData: (state, action) => {
      state.board_list = [...state.board_list, action.payload];
    },
    updateBoardData: (state, action) => {
      const newData = state.board_list.map((item) => {
        if (item.board_id === action.payload.board_id) {
          item = action.payload;
        }
        return item;
      });
      state.board_list = newData;
    },
    deleteBoardData: (state, action) => {
      const filterData = state.board_list.filter((item) => item.board_id !== action.payload);
      state.board_list = filterData;
    },
  },
});

export const {
  getBoardData,
  insertBoardData,
  updateBoardData,
  deleteBoardData,
} = boardSlice.actions;

export default boardSlice.reducer;
