import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { revertAll } from './index';

const initialState = { subjectList: [] };

export const subjectSlice = createSlice({
  name: 'subject',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    getSubjectData: (state, action) => {
      state.subjectList = action.payload;
    },
    insertSubject: (state, action) => {
      state.subjectList = [...state.subjectList, action.payload];
    },
    updateSubject: (state, action) => {
      const newData = state.subjectList.map((item) => {
        if (item.subject_id === action.payload.subject_id) {
          item = action.payload;
        }
        return item;
      });
      state.subjectList = newData;
    },
    deleteSubjectData: (state, action) => {
      const filterData = state.subjectList.filter((item) => item.subject_id !== action.payload);
      state.subjectList = filterData;
    },
  },
});

export const {
  getSubjectData,
  insertSubject,
  updateSubject,
  deleteSubjectData,
} = subjectSlice.actions;
export default subjectSlice.reducer;
