import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { revertAll } from './index';

const initialState = {
  attendanceStatus: [],
};

export const attendanceStatusSlice = createSlice({
  name: 'attendancestatus',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    getAttendanceStatusList: (state, action) => {
      state.attendanceStatus = action.payload;
    },
    updateAttendanceStatus: (state, action) => {
      const newData = state.attendanceStatus.map((item) => {
        if (item.t_a_s_id === action.payload.t_a_s_id) {
          item = action.payload;
        }
        return item;
      });
      state.attendanceStatus = newData;
    },
  },
});

export const { getAttendanceStatusList, updateAttendanceStatus } = attendanceStatusSlice.actions;

export default attendanceStatusSlice.reducer;
