/* eslint-disable import/no-extraneous-dependencies */
import { openDB } from 'idb';

export const dbPromise = async (dbName, dbVersion, storeName, keypath) => {
  const db = openDB(dbName, dbVersion, {
    // eslint-disable-next-line no-shadow
    upgrade(db) {
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, {
          keyPath: keypath,
          autoIncrement: true,
        });
      }
    },
  });
  return db;
};
