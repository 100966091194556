import Request from '.';
import { ApiRoutes } from '../constants';

export const insertBranch = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.INSERTBRANCH, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getBranch = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.GETBRANCH, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateBranch = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.UPDATEBRANCH, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteBranchAddress = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.DELETEBRANCHADDRESS, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteBranch = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.DELETEBRANCH, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getBranchAddress = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.GETBRANCHADDRESS, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
