import Request from ".";
import { ApiRoutes } from "../constants";

// GET CONFIRM STUDENT DATA
export const getStudentsData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.GETSTUDENTSDATA, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// GET STUDENT FEES DATA
export const getStudentFeesInvoiceData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.GETSTUDENTFEESINVOICEDATA, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// FIND STUDENT DATA ON NUMBER
export const findStudentDataOnParentNumber = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.FINDSTUDENTDATA, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// FIND PARENT DATA 
export const findParentData = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.FINDPARENTSDATA, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};


