import { IconButton, InputAdornment } from '@mui/material';
import Iconify from 'src/components/iconify';

export const IsNotValue = [null, 'null', undefined, 'undefined', '', 0, '0'];

export const USER_STATUS_OPTIONS = [
  { value: 0, label: 'Active' },
  { value: 1, label: 'Inactive' },
];

export const BATCH_STATUS_OPTIONS = [
  { value: 1, label: 'Active' },
  { value: 0, label: 'Inactive' },
];

export const typeOptions = [
  { value: 0, label: 'Main Branch' },
  { value: 1, label: 'Sub Branch' },
];

export const branchTypeOptions = [
  { value: 1, label: 'Nursery' },
  { value: 2, label: 'Classes' },
];

export const coadminTypeOptions = [
  { value: 1, label: 'Nursery' },
  { value: 2, label: 'Classes' },
];

export const Email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const studentFilter = {
  board: 0,
  branch: 0,
  subbranch: 0,
  address: 0,
  department: 0,
  school: 0,
  batch: 0,
  standard: 0,
  user: 0,
  status: 0,
  s_date: null,
  e_date: null,
  search: '',
};

export const studentInformation = [
  { label: 'Name : ', field: 'full_name' },
  { label: 'DOB : ', field: 'dob' },
  { label: 'Mother Phone : ', field: 'mother_mob' },
  { label: 'Father Phone : ', field: 'father_mob' },
  { label: 'Address : ', field: 'address' },
  { label: 'Reference : ', field: 'reference' },
];

export const educationInformation = [
  { label: 'Enrollment No : ', field: 'enrollment_no' },
  { label: 'Medium : ', field: 'medium' },
  { label: 'Board : ', field: 'board_id' },
  { label: 'Standard : ', field: 'standard_id' },
  { label: 'School : ', field: 'school_id' },
  { label: 'School Time : ', field: 'school_start_time' },
  { label: 'Trial Days : ', field: 'trial_days' },
  { label: 'Trial Date : ', field: 'trial_date' },
  { label: 'Last Year Percentage : ', field: 'last_year_percentage' },
  { label: 'Confirm Date : ', field: 'confirm_date' },
];

export const branchInformation = [
  { label: 'Status : ', field: 'status_name' },
  { label: 'Standard Type : ', field: 'standard_type' },
  { label: 'Branch : ', field: 'branch_id' },
  { label: 'Location : ', field: 'address_id' },
  { label: 'Department : ', field: 'department_id' },
  { label: 'Batch : ', field: 'batch_id' },
  // { label: 'Classes Time : ', field: 's_time' },
  { label: 'Inquiry Date : ', field: 'inquiry_date' },
  { label: 'Inquiry Person : ', field: 'inquiry_taken_by' },
];

export const feesInformation = [
  { label: 'Education Fees : ', field: 'decided_fees' },
  { label: 'Admission Fees : ', field: 'form_fees' },
  { label: 'Stationary Fees : ', field: 'stationary_fees' },
  { label: 'Payment Method : ', field: 'p_m_id' },
  { label: 'Bank Name : ', field: 'bank_name' },
  { label: 'Cheque No. : ', field: 'cheque_no' },
  { label: 'Van Fees : ', field: 'van_fees' },
  { label: 'Notes : ', field: 'notes' },
  { label: 'Van Driver Name : ', field: 'van_driver_id' },
];

export function withoutJoinRenderData(mainData, data_id, row_id, row_name) {
  return mainData?.filter((item) => item[data_id] === row_id).map((data) => data[row_name]);
}

export const BatchInformation = [
  { label: 'Batch Name :', field: 'batch_name' },
  { label: 'Batch Time :', field: 'batch_time' },
  { label: 'Standard :', field: 'standard_name' },
  { label: 'No.of Student :', field: 'no_of_student' },
  { label: 'Status :', field: 'status' },
];

export const permissionType = ['view', 'create', 'update', 'delete'];

export const teacherInformation = [
  { label: 'Name :', field: 'name' },
  { label: 'Email Address :', field: 'email' },
  { label: 'Mobile Phone :', field: 'phone' },
  { label: 'Experience(In Years) :', field: 'experience' },
  { label: 'Qualifications :', field: 'qualifications' },
  { label: 'Remarks :', field: 'remarks' },
];

export const workInformation = [
  { label: 'Current Working :', field: 'current_working' },
  { label: 'Current Salary :', field: 'current_salary' },
  { label: 'Expected Salary :', field: 'expected_salary' },
  { label: 'Position :', field: 'position' },
  { label: 'Status :', field: 'status_name' },
  { label: 'Notice Period :', field: 'notice_period' },
  { label: 'Joining Date :', field: 'joining_date' },
];

export function passwordFieldEye(password) {
  return (
    <InputAdornment position="end">
      <IconButton onClick={password.onToggle} edge="end">
        <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
      </IconButton>
    </InputAdornment>
  );
}

export const teacherHiringFilter = {
  search: '',
  position: 0,
  standard: 0,
  branchlocation: '',
};

export const studyMaterialOption = [
  { value: 1, label: 'Media' },
  { value: 2, label: 'URL' },
];
export const baseURL = window.location.origin;

export const generalSettingsRoute = [
  'Board',
  'Branch',
  'Color',
  'Role',
  'School',
  'Standard',
  'Subject',
  'Timeslot',
  'Payment Method',
  'Fees Type',
  'Holidays',
];

export const transactionRoute = ['Expense Type', 'Category List', 'Income Expense'];
export const studentRoute = ['Student Inquiry', 'Student List'];

export const attendanceRoute = [
  'Teacher Attendance',
  'Daily Teacher Attendance',
  'Teacher Attendance Status',
  'Teacher Attendance Report',
  'My Attendance',
];
export const attributesRoute = ['Student Status', 'Teacher Hiring Status', 'Meeting Type'];

export const vanRoute = ['Assign Student To Van', 'Daily Van Attendance'];
export const dashboardRoute = ['Student Dashboard', 'Finance Dashboard'];
export const reportRoute = [
  'Fees Due Report',
  'Fees Collection Report',
  'Student Attendance Report',
  'Daily Income Expense Report',
  'Experience Letter',
  'Appointment Letter',
];

export const studentDialogPersonalData = [
  { name: 'Full Name : ', value: 'full_name' },
  { name: 'Father Phone : ', value: 'father_mob' },
  { name: 'Mother Phone : ', value: 'mother_mob' },
  { name: 'Date Of Birth : ', value: 'dob' },
  { name: 'References : ', value: 'reference' },
];

export const studentDialogSchoolData = [
  { name: 'School Start Time : ', value: 'school_start_time' },
  { name: 'School End Time : ', value: 'school_end_time' },
  { name: 'Fees : ', value: 'decided_fees' },
  { name: 'Last Year Percentage : ', value: 'last_year_percentage' },
  { name: 'Inquiry Taken By : ', value: 'inquiry_taken_by' },
];

export const weekDays = [
  { value: 'mon_s_id', label: 'Monday' },
  { value: 'tue_s_id', label: 'Tuesday' },
  { value: 'wed_s_id', label: 'Wednesday' },
  { value: 'thu_s_id', label: 'Thursday' },
  { value: 'fri_s_id', label: 'Friday' },
  { value: 'sat_s_id', label: 'Saturday' },
];

export const chapterDefaultFilterValue = {
  school: 0,
  board: 0,
  standard: 0,
  subject: 0,
  standardType: 0,
  medium: -1,
  searchValue: '',
};

export const dailyWorkFilters = {
  batch: 0,
  subject: 0,
  start_date: null,
  end_date: null,
  search: '',
};

export const branchTypes = [
  { name: 'Nursery ', value: 1 },
  { name: 'Classes', value: 2 },
];

export const facultyFilter = {
  branch: '',
  subbranch: '',
  user: '',
};

export const materialOptions = [
  { value: 1, label: 'Create Folder' },
  { value: 2, label: 'Upload Material' },
];

export const mediumOptions = [
  { value: 0, label: 'Gujarati' },
  { value: 1, label: 'English' },
];

export const standardTypes = [
  { name: 'Nursery', value: 1 },
  { name: 'Classes', value: 2 },
];

export const batchTypes = [
  { name: 'Nursery ', value: 1 },
  { name: 'Classes', value: 2 },
];

export const transactionTypes = [
  { name: 'Income ', value: 1 },
  { name: 'Expense', value: 2 },
];

export const incomeExpenseInformation = [
  { label: 'Name :', field: 'name' },
  { label: 'Date :', field: 'date' },
  { label: 'Category :', field: 'i_e_c_id' },
  { label: 'Expense Type :', field: 'e_type_id' },
  { label: 'Amount :', field: 'amount' },
  { label: 'Payment Method :', field: 'p_m_id' },
  { label: 'Bank Name :', field: 'bank_name' },
  { label: 'Cheque No :', field: 'cheque_number' },
  { label: 'Note :', field: 'note' },
];

export const incomeExpenseBranchInformation = [
  { label: 'Branch Type :', field: 'branch_type' },
  { label: 'Branch :', field: 'branch_id' },
];

export const UserInformation = [
  { label: 'First Name :', field: 'first_name' },
  { label: 'Last Name :', field: 'last_name' },
  { label: 'Username :', field: 'user_name' },
  { label: 'Phone No. :', field: 'phoneno' },
  { label: 'Parent Phone No. :', field: 'parent_phoneno' },
  { label: 'Email :', field: 'email' },
  { label: 'Address :', field: 'address' },
  { label: 'Status :', field: 'status' },
  { label: 'Is Van Driver :', field: 'is_vandriver' },
  { label: 'Total Salary :', field: 'total_salary' },
  { label: 'Paid Leave Hours :', field: 'paid_leave_hour' },
  { label: 'Used Paid Leave Hours  :', field: 'used_paid_leave_hour' },
];

export const UserbranchInformation = [
  { label: 'Branch Name :', field: 'branch_name' },
  { label: 'Branch Type :', field: 'branch_type' },
  { label: 'Role :', field: 'role_name' },
  { label: 'Co-admin Type :', field: 'coadmin_type' },
  { label: 'Is Teacher :', field: 'is_teacher' },
];

export const LeaveInformation = [
  { label: 'Name :', field: 'name' },
  { label: 'Applied Date :', field: 'applied_date_time' },
  { label: 'Start Date :', field: 'start_date_time' },
  { label: 'End Date :', field: 'end_date_time' },
  { label: 'Reason :', field: 'reason' },
  { label: 'Leave Type :', field: 'leave_type' },
  { label: 'Leaves :', field: 'leaves' },
  { label: 'Status :', field: 'status' },
];

export const LeaveBranchInformation = [
  { label: 'Branch :', field: 'branch_name' },
  { label: 'Branch Type :', field: 'branch_type' },
  { label: 'Department :', field: 'dept_name' },
];

export const feesDefaultFilterValue = {
  selectedbranch: 0,
  selectedstandard: 0,
  selectedboard: 0,
  selectedstandardtype: 0,
  selectedmedium: -1,
};

export const currentYear = new Date().getFullYear();
export const earliestDate = new Date(currentYear - 100, 0, 1);

export const meetingWeekDays = [
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thusday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
  { value: 7, label: 'Sunday' },
];

export const recursiveMeetingTypes = [
  { value: 1, label: 'Once' },
  { value: 2, label: 'Weekly' },
  { value: 3, label: 'Monthly' },
];

export const meetingStatus = [
  { value: 1, label: 'Pending' },
  { value: 2, label: 'Start' },
  { value: 3, label: 'Completed' },
  { value: 4, label: 'Missed' },
  { value: 5, label: 'Cancel' },
];

export const agendaTypesOptions = [
  { value: 1, label: 'Assign Task' },
  { value: 2, label: 'Suggestion' },
];

export const userTypesOptions = [
  { value: 1, label: 'Staff' },
  { value: 2, label: 'Student' },
];

export const meetingFilters = {
  search: '',
  meet_type: 0,
  recursive_meet_type: 0,
  start_date_time: null,
  end_date_time: null,
  created_by: 0,
  assign_to: 0,
};

export const meetingStatusOptions = [
  { value: 1, label: 'Pending' },
  { value: 2, label: 'Start' },
  { value: 3, label: 'Completed' },
  { value: 4, label: 'Missed' },
  { value: 5, label: 'Cancel' },
];

export const agendaStatusOptions = [
  { value: 1, label: 'Pending' },
  { value: 2, label: 'Working' },
  { value: 3, label: 'Completed' },
];

export const MeetingInformation = [
  { name: 'Meeting Type', value: 'meet_type_id' },
  { name: 'Meeting Message', value: 'meet_message' },
  { name: 'Meeting Date', value: 'meet_date' },
  { name: 'Meeting Day', value: 'meeting_day' },
  { name: 'Start Time', value: 'start_time' },
  { name: 'End Time', value: 'end_time' },
  { name: 'Location', value: 'address_id' },
  { name: 'Recursive Meeting Type', value: 'recursive_meet_type' },
  { name: 'Meeting Status', value: 'meet_status' },
  { name: 'Cancel Reason', value: 'cancel_reason' },
  { name: 'Is Reschedule', value: 'is_cancel_reschedule' },
];

export const StudentInformation = [
  { label: 'Name :', field: 'student_id' },
  { label: 'Branch :', field: 'branch_id' },
  { label: 'Standard Type :', field: 'standard_type' },
  { label: 'Board :', field: 'board_id' },
  { label: 'Medium :', field: 'medium' },
  { label: 'Standard :', field: 'standard_id' },
  { label: 'Note :', field: 'notes' },
];

export const InvoiceInformation = [
  { label: 'Invoice Number :', field: 'invoice_no' },
  { label: 'Date :', field: 'i_date' },
  { label: 'Total Amount :', field: 'decided_fees' },
  { label: 'Paid Amount :', field: 'total_paid_fees' },
  { label: 'Remaining Amount :', field: 'remaining_fees' },
  { label: 'Fees Taken By :', field: 'fees_taken_by' },
];

export const TeacherTrainingStatus = [
  { label: 'Pending', value: '0' },
  { label: 'Processing', value: '1' },
  { label: 'Completed', value: '2' },
];
export const StudentTestData = [
  { name: 'Test Name : ', value: 'test_name' },
  { name: 'Total Marks : ', value: 'total_marks' },
  { name: 'Test Date Time : ', value: 'test_date_time' },
  { name: 'Test Status : ', value: 'test_status' },
  { name: 'Batch : ', value: 'batch_id' },
  { name: 'Subject : ', value: 'subject_id' },
  { name: 'Created By : ', value: 'user_id' },
  { name: 'Description : ', value: 'test_desc' },
  { name: 'Cancel Reason : ', value: 'cancel_reason' },
];

export const StudentTestFilter = {
  branch_id: 0,
  subbranch_id: 0,
  dept_id: 0,
  student_id: 0,
  batch_id: 0,
  subject_id: 0,
  start_date_time: null,
  end_date_time: null,
  search: '',
};

export const EOWreportTypeOption = [
  { value: 1, label: 'Standard Wise EOW' },
  { value: 2, label: 'Subject Wise EOW' },
];

export const defaultEOWfilter = {
  batch_id: 0,
  parent_branch_id: 0,
  teacher_id: 0,
  report_type: 0,
  branch_id: 0,
  subject_id: 0,
};

export const ActivityInformation = [
  { label: 'Activity Name :', field: 'title' },
  { label: 'Date :', field: 'activity_date' },
  { label: 'Description :', field: 'description' },
];

export const NewsCornerData = [
  { name: 'News Title', value: 'news_title' },
  { name: 'Schedule Time', value: 'schedule_time' },
  { name: 'Branch Name', value: 'branch_id' },
  { name: 'SubBranch Name', value: 'subbranch_id' },
  { name: 'Department Name', value: 'dept_id' },
  { name: 'Batch Name', value: 'batch_id' },
  { name: 'Created By', value: 'user_id' },
  { name: 'News Content', value: 'news_content' },
];

export const NewsCornerFilter = {
  branch: 0,
  subbranch: 0,
  department: 0,
  batch: 0,
  news_date: null,
  search: '',
};

export const vanAttendance = {
  subbranch_id: 0,
  dept_id: 0,
  batch_id: 0,
  start_time: null,
  end_time: null,
  search: '',
};

export const feesDueReportFilters = {
  branch_id: 0,
  subbranch_id: 0,
  dept_id: 0,
  batch_id: 0,
};

export const SalaryFilters = {
  user_id: 0,
  month_year: null,
  search: '',
  start_month: null,
  end_month: null,
  branch: 0,
  subbranch: 0,
  location_id: 0,
};

export const FeesCollectionReportFilters = {
  start_date: null,
  end_date: null,
  branch_id: 0,
  subbranch_id: 0,
  dept_id: 0,
  batch_id: 0,
};

export const commaFunction = (data) => data?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const leavesCommonType = [
  { value: 0, label: 'Full Leave' },
  { value: 1, label: 'Morning Leave' },
  { value: 2, label: 'Afternoon Leave' },
];

export const paidUnpaidLeaveType = [
  { value: 0, label: 'Paid Leave' },
  { value: 1, label: 'UnPaid Leave' },
];

export const studentTestStatusOptions = [
  { value: 1, label: 'Pending' },
  { value: 2, label: 'Done' },
  { value: 3, label: 'Cancel' },
];

export const PRESENT_ABSENT_OPTION = [
  { value: 1, label: 'Present' },
  { value: 2, label: 'Absent' },
];
