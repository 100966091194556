const ROOTS = {
  LOGIN: '/login',
  FORGOTPASSWORD: '/forgotpassword',
  RESETPASSWORD: '/resetpassword',
  DASHBOARD: '/dashboard',
  ADMINDASHBOARDS: '/admindashboards',
  STUDENTDASHBOARD: '/studentdashboard',
  FINANCEDASHBOARD: '/financedashboard',
  BRANCH: '/branch',
  BOARD: '/board',
  SCHOOL: '/school',
  DEPARTMENT: '/department',
  TIMESLOT: '/timeslot',
  PAGENOTFOUND: '/404',
  STANDARDS: '/standard',
  COLOR: '/color',
  SUBJECT: '/subject',
  CHAPTER: '/chapter',
  BATCH: '/batch',
  USER: '/user',
  ROLE: '/role',
  STUDENT: '/student',
  PAYMENTMETHOD: '/paymentmethod',
  FEES: '/fees',
  ATTRIBUTES: '/attributes',
  WEBSETTING: '/websetting',
  TEACHERHIRING: '/teacherhiring',
  PROFILE: '/userprofile',
  STUDYMATERIAL: '/studymaterial',
  TEACHERATTENDANCE: '/teacherattendance',
  GENERALSETTING: '/generalsetting',
  STUDENTINQUIRY: '/studentinquiry',
  STUDENTLIST: '/studentlist',
  STUDENTSTATUS: '/studentstatus',
  TEACHERHIRINGSTATUS: '/teacherhiringstatus',
  ATTENDANCE: '/attendance',
  DAILYTEACHERATTENDANCE: '/dailyteacherattendance',
  FACULTYTIMETABLE: '/facultytimetable',
  MEETING: '/meeting',
  MEETINGTYPE: '/meetingtype',
  LEAVE: '/leave',
  GALLERY: '/gallery',
  TRANSACTION: '/transaction',
  EXPENSETYPE: '/expensetype',
  CATEGORYLIST: '/categorylist',
  INCOMEEXPENSE: '/incomeexpense',
  ATTENDANCESTATUS: '/teacherattendancestatus',
  FEESTYPE: '/feestype',
  INVOICE: '/invoice',
  TEST: '/test',
  EOW: '/eow',
  HOLIDAYS: '/holidays',
  TEACHERATTENDANCEREPORT: '/teacherattendancereport',
  ACTIVITY: '/activity',
  TEACHERTRAINING: '/teachertraining',
  MYATTENDANCE: '/myattendance',
  NEWSCORNER: '/newscorner',
  VAN: '/van',
  ASSIGNSTUDNETTOVAN: '/assignstudenttovan',
  DAILYVANATTENDANCE: '/dailyvanattendance',
  SALARY: '/salary',
  STUDENTATTENDANCE: '/studentattendance',
  REPORTS: '/reports',
  FEESDUEREPORT: '/feesduereport',
  FEESCOLLECTIONREPORT: '/feescollectionreport',
  GENERATEDFEESREPORTS: '/generatedfeesreports',
  STUDENTATTENDANCEREPORT: '/studentattendancereport',
  DAILYINCOMEEXPENSEREPORT: '/dailyincomeexpensereport',
  EXPERIENCELETTER: '/experienceletter',
  DAILYWORK: '/dailywork',
  APPOINTMENTLETTER: '/appointmentletter',
};

// ----------------------------------------------------------------------

export const paths = {
  page404: ROOTS.PAGENOTFOUND,

  // AUTH
  auth: {
    login: ROOTS.LOGIN,
    forgotpassword: ROOTS.FORGOTPASSWORD,
    resetpassword: ROOTS.RESETPASSWORD,
  },
  dashboard: {
    root: ROOTS.DASHBOARD,
  },
  admindashboards: ROOTS.ADMINDASHBOARDS,
  studentdashboard: ROOTS.STUDENTDASHBOARD,
  financedashboard: ROOTS.FINANCEDASHBOARD,
  branch: ROOTS.BRANCH,
  board: ROOTS.BOARD,
  school: ROOTS.SCHOOL,
  department: ROOTS.DEPARTMENT,
  color: ROOTS.COLOR,
  subject: ROOTS.SUBJECT,
  timeslot: ROOTS.TIMESLOT,
  chapter: ROOTS.CHAPTER,
  batch: ROOTS.BATCH,
  student: ROOTS.STUDENT,
  fees: ROOTS.FEES,
  standard: ROOTS.STANDARDS,
  role: ROOTS.ROLE,
  user: ROOTS.USER,
  paymentmethod: ROOTS.PAYMENTMETHOD,
  attributes: ROOTS.ATTRIBUTES,
  websetting: ROOTS.WEBSETTING,
  teacherhiring: ROOTS.TEACHERHIRING,
  profile: ROOTS.PROFILE,
  studymaterial: ROOTS.STUDYMATERIAL,
  teacherattendance: ROOTS.TEACHERATTENDANCE,
  generalsetting: ROOTS.GENERALSETTING,
  studentlist: ROOTS.STUDENTLIST,
  studentinquiry: ROOTS.STUDENTINQUIRY,
  studentstatus: ROOTS.STUDENTSTATUS,
  teacherhiringstatus: ROOTS.TEACHERHIRINGSTATUS,
  attendance: ROOTS.ATTENDANCE,
  dailyteacherattendance: ROOTS.DAILYTEACHERATTENDANCE,
  facultytimetable: ROOTS.FACULTYTIMETABLE,
  meeting: ROOTS.MEETING,
  meetingtype: ROOTS.MEETINGTYPE,
  leave: ROOTS.LEAVE,
  gallery: ROOTS.GALLERY,
  transaction: ROOTS.TRANSACTION,
  expensetype: ROOTS.EXPENSETYPE,
  categorylist: ROOTS.CATEGORYLIST,
  incomeexpense: ROOTS.INCOMEEXPENSE,
  teacherattendancestatus: ROOTS.ATTENDANCESTATUS,
  feestype: ROOTS.FEESTYPE,
  invoice: ROOTS.INVOICE,
  test: ROOTS.TEST,
  eow: ROOTS.EOW,
  holidays: ROOTS.HOLIDAYS,
  teacherattendancereport: ROOTS.TEACHERATTENDANCEREPORT,
  activity: ROOTS.ACTIVITY,
  teachertraining: ROOTS.TEACHERTRAINING,
  myattendance: ROOTS.MYATTENDANCE,
  newscorner: ROOTS.NEWSCORNER,
  van: ROOTS.VAN,
  assignstudenttovan: ROOTS.ASSIGNSTUDNETTOVAN,
  dailyvanattendance: ROOTS.DAILYVANATTENDANCE,
  salary: ROOTS.SALARY,
  studentattendance: ROOTS.STUDENTATTENDANCE,
  reports: ROOTS.REPORTS,
  feesduereport: ROOTS.FEESDUEREPORT,
  feescollectionreport: ROOTS.FEESCOLLECTIONREPORT,
  generatedfeesreports: ROOTS.GENERATEDFEESREPORTS,
  studentattendancereport: ROOTS.STUDENTATTENDANCEREPORT,
  dailyincomeexpensereport: ROOTS.DAILYINCOMEEXPENSEREPORT,
  experienceletter: ROOTS.EXPERIENCELETTER,
  dailywork: ROOTS.DAILYWORK,
  appointmentletter: ROOTS.APPOINTMENTLETTER,
};
