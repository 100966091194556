import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { revertAll } from './index';

const initialState = {
  meeting_type_list: [],
  student_status_list: [],
  teacher_status_list: [],
};

export const attributesSlice = createSlice({
  name: 'attributes',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    getStudentStatusList: (state, action) => {
      state.student_status_list = action.payload;
    },
    getTeacherStatusList: (state, action) => {
      state.teacher_status_list = action.payload;
    },
    insertStudentStatusList: (state, action) => {
      state.student_status_list = [
        ...state.student_status_list,
        action.payload,
      ];
    },
    updateStudentStatusList: (state, action) => {
      const newData = state.student_status_list.map((item) => {
        if (item.a_id === action.payload.a_id) {
          item = action.payload;
        }
        return item;
      });
      state.student_status_list = newData;
    },
    deleteStudentStatusList: (state, action) => {
      const filterData = state.student_status_list.filter(
        (item) => item.a_id !== action.payload
      );
      state.student_status_list = filterData;
    },
    insertTeacherHiringStatusList: (state, action) => {
      state.teacher_status_list = [
        ...state.teacher_status_list,
        action.payload,
      ];
    },
    updateTeacherHiringStatusList: (state, action) => {
      const newData = state.teacher_status_list.map((item) => {
        if (item.a_id === action.payload.a_id) {
          item = action.payload;
        }
        return item;
      });
      state.teacher_status_list = newData;
    },
    deleteTeacherHiringStatusList: (state, action) => {
      const filterData = state.teacher_status_list.filter(
        (item) => item.a_id !== action.payload
      );
      state.teacher_status_list = filterData;
    },

    getMeetingTypeList: (state, action) => {
      state.meeting_type_list = action.payload;
    },

    insertMeetingTypeList: (state, action) => {
      state.meeting_type_list = [...state.meeting_type_list, action.payload];
    },

    updateMeetingTypeList: (state, action) => {
      const newData = state.meeting_type_list.map((item) => {
        if (item.meet_type_id === action.payload.meet_type_id) {
          item = action.payload;
        }
        return item;
      });
      state.meeting_type_list = newData;
    },

    deleteMeetingTypeList: (state, action) => {
      const filterData = state.meeting_type_list.filter(
        (item) => item.meet_type_id !== action.payload
      );
      state.meeting_type_list = filterData;
    },
  },
});

export const {
  getStudentStatusList,
  getTeacherStatusList,
  insertStudentStatusList,
  updateStudentStatusList,
  deleteStudentStatusList,
  insertTeacherHiringStatusList,
  updateTeacherHiringStatusList,
  deleteTeacherHiringStatusList,
  getMeetingTypeList,
  insertMeetingTypeList,
  updateMeetingTypeList,
  deleteMeetingTypeList,
} = attributesSlice.actions;

export default attributesSlice.reducer;
