import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Iconify from '../iconify';
import FileThumbnail from '../file-thumbnail';

// ----------------------------------------------------------------------

export default function Upload({
  disabled,
  multiple = false,
  error,
  helperText,
  //
  file,
  onDelete,
  //
  files,
  thumbnail,
  onUpload,
  onRemove,
  onRemoveAll,
  sx,
  ...other
}) {
  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    multiple,
    disabled,
    ...other,
  });

  const hasFile = !!file && !multiple;

  const hasError = isDragReject || !!error;

  return (
    <Box sx={{ width: 1, position: 'relative', ...sx }}>
      <Box
        {...getRootProps()}
        sx={{
          m: 0.5,
          width: 64,
          height: 64,
          flexShrink: 0,
          display: 'flex',
          borderRadius: 1,
          cursor: 'pointer',
          alignItems: 'center',
          color: 'text.disabled',
          justifyContent: 'center',
          bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
          border: (theme) => `dashed 1px ${alpha(theme.palette.grey[500], 0.16)}`,
          ...(isDragActive && {
            opacity: 0.72,
          }),
          ...(disabled && {
            opacity: 0.48,
            pointerEvents: 'none',
          }),
          ...(hasError && {
            color: 'error.main',
            borderColor: 'error.main',
            bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
          }),
          '&:hover': {
            opacity: 0.72,
          },
          ...sx,
        }}
      >
        <input {...getInputProps()} />

        {hasFile ? (
          <Stack
            alignItems="center"
            display="inline-flex"
            justifyContent="center"
            sx={{
              m: 0.5,
              width: 64,
              height: 64,
              borderRadius: 1.25,
              overflow: 'hidden',
              position: 'relative',
              ...sx,
            }}
          >
            <FileThumbnail
              tooltip
              imageView
              file={file}
              sx={{ position: 'absolute' }}
              imgSx={{ position: 'absolute' }}
            />

            {onDelete && (
              <IconButton
                size="small"
                onClick={onDelete}
                sx={{
                  p: 0.5,
                  top: 4,
                  right: 4,
                  position: 'absolute',
                  color: 'common.white',
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                  '&:hover': {
                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                  },
                }}
              >
                <Iconify icon="mingcute:close-line" width={14} />
              </IconButton>
            )}
          </Stack>
        ) : (
          <Iconify icon="eva:cloud-upload-fill" width={28} />
        )}
      </Box>
      {helperText && helperText}
    </Box>
  );
}

Upload.propTypes = {
  disabled: PropTypes.object,
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  files: PropTypes.array,
  helperText: PropTypes.object,
  multiple: PropTypes.bool,
  onDelete: PropTypes.func,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onUpload: PropTypes.func,
  sx: PropTypes.object,
  thumbnail: PropTypes.bool,
};
