import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { AuthGuard, RouteGuard } from 'src/auth/guard';
import { LoadingScreen } from 'src/components/loading-screen';
import FeesDueReport from 'src/pages/Reports/feesDueReport';
import DailyIncomeExpenseReport from 'src/pages/Reports/dailyIncomeExpenseReport';

const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const StudentDashboard = lazy(() =>
  import('src/pages/dashboard/studentDashboard')
);
const FinanceDashboard = lazy(() =>
  import('src/pages/dashboard/financeDashboard')
);
const Branch = lazy(() => import('src/pages/Branch/branch'));
const School = lazy(() => import('src/pages/school/school'));
const RoleList = lazy(() => import('src/pages/Role/RoleList'));
const Board = lazy(() => import('src/pages/Board/board'));
const Standard = lazy(() => import('src/pages/standard/standard'));
const Department = lazy(() => import('src/pages/Department'));
const Color = lazy(() => import('src/pages/Color/Color'));
const Subject = lazy(() => import('src/pages/Subject/Subject'));
const Chapter = lazy(() => import('src/pages/Chapter/chapter'));
const Batch = lazy(() => import('src/pages/Batch/Batch'));
const Timeslot = lazy(() => import('src/pages/Timeslot/Timeslot'));
const User = lazy(() => import('src/pages/user/user'));
const StudentInquiry = lazy(() => import('src/pages/student/studentInquiry'));
const StudentList = lazy(() => import('src/pages/student/studentList'));
const PaymentMethod = lazy(() => import('src/pages/paymentmethod'));
const FeesAmount = lazy(() => import('src/pages/fees/fees'));
const StudentStatus = lazy(() => import('src/pages/attributes/studentStatus'));
const TeacherHiringStatus = lazy(() =>
  import('src/pages/attributes/teacherHiringStatus')
);
const Websetting = lazy(() => import('src/pages/websetting/websetting'));
const TeacherHiring = lazy(() => import('src/pages/TeacherHiring'));
const UserProfile = lazy(() => import('src/pages/profile/userProfile'));
const StudyMaterial = lazy(() =>
  import('src/pages/studymaterials/studyMaterial')
);
const TeacherAttendance = lazy(() =>
  import('src/pages/attendance/techerAttendance')
);
const DailyTeacherAttendance = lazy(() =>
  import('src/pages/attendance/dailyAttendance')
);
const FacultyTimeTable = lazy(() =>
  import('src/pages/timetable/facultyTimeTable')
);
const Meeting = lazy(() => import('src/pages/Meeting/meeting'));
const Meetingtype = lazy(() => import('src/pages/attributes/meetingType'));
const Leave = lazy(() => import('src/pages/Leave/leave'));
const GalleryCorners = lazy(() => import('src/pages/gallery/gallery'));
const ExpenseType = lazy(() => import('src/pages/transaction/ExpenseType'));
const CategoryList = lazy(() => import('src/pages/transaction/CategoryList'));
const IncomeExpense = lazy(() => import('src/pages/transaction/IncomeExpense'));
const TeacherAttendanceStatus = lazy(() =>
  import('src/pages/attendance/attendanceStatus')
);
const FeesType = lazy(() => import('src/pages/feestype/feesType'));
const Invoice = lazy(() => import('src/pages/invoice/invoice'));
const TeacherTraining = lazy(() =>
  import('src/pages/TeacherTraining/teacherTraining')
);
const StudentTest = lazy(() => import('src/pages/StudentTest/index'));
const EOW = lazy(() => import('src/pages/EOW/eow'));
const Holidays = lazy(() => import('src/pages/Holidays/Holidays'));
const TeacherAttendanceReport = lazy(() =>
  import('src/pages/attendance/teacherAttendanceReport')
);
const Activity = lazy(() => import('src/pages/Activity/activity'));
const MyAttendance = lazy(() => import('src/pages/attendance/myattendance'));
const NewsCorner = lazy(() => import('src/pages/newsCorner/index'));
const DailyVanAttendance = lazy(() =>
  import('src/pages/Van/dailyVanAttendance')
);
const AssignStudentToVan = lazy(() =>
  import('src/pages/Van/assignStudentToVan')
);
const DailyWork = lazy(() => import('src/pages/DailyWork/dailywork'));
const Salary = lazy(() => import('src/pages/salary/index'));
const StudentAttendance = lazy(() =>
  import('src/pages/studentAttendance/studentAttendance')
);
const FeesCollectionReport = lazy(() =>
  import('src/pages/Reports/feesCollectionReport')
);
const GeneratedFeesReports = lazy(() =>
  import('src/pages/Reports/generatedFeesReports')
);
const StudentAttendanceReport = lazy(() =>
  import('src/pages/Reports/studentAttendanceReport')
);
const ExperienceLetter = lazy(() =>
  import('src/pages/Reports/experienceLetter')
);
const AppointmentLetter = lazy(() =>
  import('src/pages/Reports/appointmentLetter')
);

export const dashboardRoutes = [
  {
    element: (
      <AuthGuard>
        <RouteGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </RouteGuard>
      </AuthGuard>
    ),
    children: [
      { path: 'dashboard', element: <IndexPage /> },
      // { path: 'admindashboard', element: <AdminDashboard /> },
      {
        path: 'admindashboards',
        children: [
          { element: <Navigate to='/dashboard' replace />, index: true },
          { path: 'studentdashboard', element: <StudentDashboard /> },
          { path: 'financedashboard', element: <FinanceDashboard /> },
        ],
      },
      { path: 'department', element: <Department /> },
      { path: 'chapter', element: <Chapter /> },
      { path: 'batch', element: <Batch /> },
      { path: 'user', element: <User /> },
      { path: 'fees', element: <FeesAmount /> },
      { path: 'meeting', element: <Meeting /> },
      {
        path: 'student',
        children: [
          { element: <Navigate to='/dashboard' replace />, index: true },
          { path: 'studentinquiry', element: <StudentInquiry /> },
          { path: 'studentlist', element: <StudentList /> },
        ],
      },
      {
        path: 'attributes',
        children: [
          { element: <Navigate to='/dashboard' replace />, index: true },
          { path: 'studentstatus', element: <StudentStatus /> },
          { path: 'teacherhiringstatus', element: <TeacherHiringStatus /> },
          { path: 'meetingtype', element: <Meetingtype /> },
        ],
      },
      {
        path: 'generalsetting',
        children: [
          { element: <Navigate to='/dashboard' replace />, index: true },
          { path: 'board', element: <Board /> },
          { path: 'branch', element: <Branch /> },
          { path: 'color', element: <Color /> },
          { path: 'role', element: <RoleList /> },
          { path: 'school', element: <School /> },
          { path: 'standard', element: <Standard /> },
          { path: 'subject', element: <Subject /> },
          { path: 'timeslot', element: <Timeslot /> },
          { path: 'paymentmethod', element: <PaymentMethod /> },
          { path: 'feestype', element: <FeesType /> },
          { path: 'holidays', element: <Holidays /> },
        ],
      },
      { path: 'websetting', element: <Websetting /> },
      { path: 'teacherhiring', element: <TeacherHiring /> },
      { path: 'userprofile', element: <UserProfile /> },
      { path: 'studymaterial', element: <StudyMaterial /> },
      {
        path: 'attendance',
        children: [
          { element: <Navigate to='/dashboard' replace />, index: true },
          { path: 'teacherattendance', element: <TeacherAttendance /> },
          {
            path: 'dailyteacherattendance',
            element: <DailyTeacherAttendance />,
          },
          {
            path: 'teacherattendancestatus',
            element: <TeacherAttendanceStatus />,
          },
          {
            path: 'teacherattendancereport',
            element: <TeacherAttendanceReport />,
          },
          { path: 'myattendance', element: <MyAttendance /> },
        ],
      },
      {
        path: 'van',
        children: [
          { element: <Navigate to='/dashboard' replace />, index: true },
          { path: 'assignstudenttovan', element: <AssignStudentToVan /> },
          {
            path: 'dailyvanattendance',
            element: <DailyVanAttendance />,
          },
        ],
      },
      {
        path: 'reports',
        children: [
          { element: <Navigate to='/dashboard' replace />, index: true },
          { path: 'feesduereport', element: <FeesDueReport /> },
          { path: 'feescollectionreport', element: <FeesCollectionReport /> },
          { path: 'generatedfeesreports', element: <GeneratedFeesReports /> },
          {
            path: 'studentattendancereport',
            element: <StudentAttendanceReport />,
          },
          {
            path: 'dailyincomeexpensereport',
            element: <DailyIncomeExpenseReport />,
          },
          { path: 'experienceletter', element: <ExperienceLetter /> },
          { path: 'appointmentletter', element: <AppointmentLetter /> },
        ],
      },
      { path: 'facultytimetable', element: <FacultyTimeTable /> },
      { path: 'leave', element: <Leave /> },
      { path: 'gallery', element: <GalleryCorners /> },
      {
        path: 'transaction',
        children: [
          { element: <Navigate to='/dashboard' replace />, index: true },
          { path: 'expensetype', element: <ExpenseType /> },
          { path: 'categorylist', element: <CategoryList /> },
          { path: 'IncomeExpense', element: <IncomeExpense /> },
        ],
      },
      { path: 'dailywork', element: <DailyWork /> },
      { path: 'invoice', element: <Invoice /> },
      { path: 'teachertraining', element: <TeacherTraining /> },
      { path: 'test', element: <StudentTest /> },
      { path: 'eow', element: <EOW /> },
      { path: 'activity', element: <Activity /> },
      { path: 'newscorner', element: <NewsCorner /> },
      { path: 'salary', element: <Salary /> },
      { path: 'studentattendance', element: <StudentAttendance /> },
    ],
  },
];
