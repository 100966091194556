import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { revertAll } from './index';

const initialState = { batchList: [] };

export const batchSlice = createSlice({
  name: 'batch',
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    getBatchListData: (state, action) => {
      state.batchList = action.payload;
    },
  },
});

export const { getBatchListData } = batchSlice.actions;

export default batchSlice.reducer;
